import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';

const MARKSCHEME_IMAGES_STORAGE_BUCKET = 'markscheme-images';

@Injectable({ providedIn: 'root' })
export class MarkschemeTextHttpService extends SupabaseBaseHttpService<'markscheme_texts'> {
  readonly schema = 'public';
  readonly tableName = 'markscheme_texts';
}

@Injectable({ providedIn: 'root' })
export class MarkschemeImageHttpService extends SupabaseBaseHttpService<'markscheme_images'> {
  readonly schema = 'public';
  readonly tableName = 'markscheme_images';
  override readonly storage_bucket = MARKSCHEME_IMAGES_STORAGE_BUCKET;
}
