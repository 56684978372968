import { Constants, Enum } from '@examdojo/supabase';

export type StemSubjectSubType = Enum<'subject_sub_type'>;

export const STEM_SUBJECT_SUB_TYPES = Constants.public.Enums.subject_sub_type;

// TODO: map to translation keys instead
export const STEM_SUBJECT_SUB_TYPE_TO_LABEL: Record<StemSubjectSubType, string> = {
  ALGEBRA: 'Algebra',
  GEOMETRY: 'Geometry',
};
