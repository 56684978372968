import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { TestingSectionComponent } from '@examdojo/core/debugging';
import { QuestionGradingPreset } from '@examdojo/models/question';
import { ButtonComponent } from '@examdojo/ui/button';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { map } from 'rxjs';
import { ImageLocation } from '../question-attempt-response-image.model';
import { QuestionAttemptResponseImageQuery } from '../question-attempt-response-images.query';
import { SolutionImageService } from '../solution-image.service';
import { SolutionImagesListComponent } from '../solution-images-list/solution-images-list.component';
import { SolutionService } from '../solution.service';
import { SolutionCaptureDialogService } from './solution-capture-dialog.service';

@Component({
  selector: 'dojo-solution-capture-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ion-header>
      <ion-toolbar class="toolbar-with-closing-button !px-2 md:!px-4">
        <div slot="start" class="hidden md:flex">
          <dojo-icon-button (click)="closeModal()" [icon]="'times'" />
        </div>
        <ion-title class="common-ion-title">
          {{ 'examdojo.question.upload_answer' | transloco }}
        </ion-title>
        <div slot="end" class="hidden md:flex md:w-8"></div>
      </ion-toolbar>
    </ion-header>

    <div class="safe-area-bottom">
      <div class="rounded-[1rem] p-0 py-4 md:p-2">
        <dojo-solution-images-list [userId]="userId" [attemptId]="attemptId" />

        <dojo-testing-section class="mb-4">
          <div class="aligner flex-wrap gap-4">
            <dojo-button [small]="true" (click)="gradeWithPreset(QuestionGradingPreset.NoAnswer)">
              No answer 🔴
            </dojo-button>
            <dojo-button [small]="true" (click)="gradeWithPreset(QuestionGradingPreset.GradingError)">
              Grading error (self-grading) 🔴
            </dojo-button>
            <dojo-button [small]="true" (click)="gradeWithPreset(QuestionGradingPreset.SomeMarks)">
              Some marks 🟡
            </dojo-button>
            <dojo-button [small]="true" (click)="gradeWithPreset(QuestionGradingPreset.FullMarks)">
              All marks 🟢
            </dojo-button>
          </div>
        </dojo-testing-section>

        <div class="px-4">
          <dojo-button (click)="saveImages()" [disabled]="state.gradingDisabled">
            {{ 'examdojo.continue' | transloco }}
          </dojo-button>
        </div>
      </div>
    </div>
  `,
  imports: [
    SolutionImagesListComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    ButtonComponent,
    IconButtonComponent,
    TranslocoPipe,
    TestingSectionComponent,
  ],
  providers: [SolutionImageService, SolutionService],
})
export class SolutionCaptureDialogComponent {
  constructor(
    private readonly solutionCaptureDialogService: SolutionCaptureDialogService,
    private readonly questionAttemptResponseImageQuery: QuestionAttemptResponseImageQuery,
  ) {}

  @Input() userId!: string;
  @Input() attemptId!: string;

  readonly state = connectState({
    gradingDisabled: this.questionAttemptResponseImageQuery.selectAllEntities().pipe(
      map((images) => {
        if (!images?.length) {
          return true;
        }

        return images.some((image) => image.location === ImageLocation.Local);
      }),
    ),
  });

  readonly QuestionGradingPreset = QuestionGradingPreset;

  closeModal() {
    this.solutionCaptureDialogService.dismiss(false);
  }

  saveImages() {
    this.solutionCaptureDialogService.dismiss(true);
  }

  gradeWithPreset(preset: QuestionGradingPreset) {
    this.solutionCaptureDialogService.dismiss(preset);
  }
}
