import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';
import { from } from 'rxjs';
import { QuestionAttemptStoreModel } from './question-attempt.model';

@Injectable({ providedIn: 'root' })
export class QuestionAttemptGradingHttpService extends SupabaseBaseHttpService<
  'question_attempt_gradings',
  'learning'
> {
  readonly schema = 'learning';
  readonly tableName = 'question_attempt_gradings';

  fetchAttemptGradings(attemptId: QuestionAttemptStoreModel['id']) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        .select()
        .eq('question_attempt_id', attemptId)
        .order('created_at', { ascending: false })
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
