import { Injectable } from '@angular/core';
import { QueryEntity } from '@examdojo/state';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { PracticeActivityState, PracticeActivityStoreModel, PracticeActivityUIModel } from './practice-activity.model';
import { PracticeActivityStore } from './practice-activity.store';

@Injectable({ providedIn: 'root' })
export class PracticeActivityQuery extends QueryEntity<
  PracticeActivityState,
  PracticeActivityStoreModel,
  PracticeActivityUIModel,
  'id'
> {
  constructor(protected override store: PracticeActivityStore) {
    super(store);
  }

  readonly practiceActivities$ = this.entities$.pipe(
    map((activities) => {
      return activities.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    }),
  );

  readonly notStartedPracticeActivityId$: Observable<string | undefined> = this.practiceActivities$.pipe(
    map((activity) => activity.filter((a) => a.status === 'NOT_STARTED')[0]?.id),
    distinctUntilChanged(),
  );

  readonly inProgressPracticeActivityId$: Observable<string | undefined> = this.practiceActivities$.pipe(
    map((activity) => activity.filter((a) => a.status === 'IN_PROGRESS')[0]?.id),
    distinctUntilChanged(),
  );
}
