import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import {
  QuestionGradingPreset,
  QuestionHttpModel,
  QuestionImageHttpModel,
  QuestionStoreModel,
  QuestionVoteStoreModel,
  StemHttpModel,
  StimulusHttpModel,
} from '@examdojo/models/question';
import { DbJson, ExamdojoSupabaseService } from '@examdojo/supabase';
import { from, Observable } from 'rxjs';
import { QuestionAttemptHttpModel } from './question-attempt.model';
import { Feedback } from './question-user-feedback';
import { GradedStem } from './solution';

@Injectable({
  providedIn: 'root',
})
export class QuestionHttpService {
  constructor(
    private readonly supabase: ExamdojoSupabaseService,
    private readonly http: HttpClient,
  ) {}

  private readonly basePath = `/question-attempts`;
  private readonly baseUrl = `${environment.examdojo.examdojoApiUrl}${this.basePath}`;
  private readonly testBaseUrl = `${environment.examdojo.examdojoApiUrl}/test${this.basePath}`;

  fetchQuestion(id: QuestionStoreModel['id']): Observable<QuestionHttpModel> {
    return from(
      this.supabase.client
        .from('questions')
        .select()
        .eq('id', id)
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  fetchItems(questionId: QuestionStoreModel['id']) {
    return from(
      this.supabase.client
        .rpc('get_question_items', {
          p_question_id: questionId,
        })
        .throwOnError()
        .then(
          (res) =>
            (res.data ?? []) as Array<
              (
                | ({ item_type: 'stimulus' } & Omit<StimulusHttpModel, 'id'>)
                | ({ item_type: 'stem' } & Omit<StemHttpModel, 'id'>)
                | ({ item_type: 'question_image' } & Omit<QuestionImageHttpModel, 'id'>)
              ) & { item_id: number }
            >,
        ),
    );
  }

  triggerGrading(attemptId: QuestionAttemptHttpModel['id'], preset?: QuestionGradingPreset) {
    const baseUrl = preset ? this.testBaseUrl : this.baseUrl;

    return this.http.post<void>(
      `${baseUrl}/${attemptId}/grade`,
      {},
      {
        params: preset ? { preset } : {},
      },
    );
  }

  triggerSelfGrading(attemptId: QuestionAttemptHttpModel['id'], gradingResult: GradedStem[]) {
    return this.http.post<void>(`${this.baseUrl}/${attemptId}/self-grade`, {
      grading_result: gradingResult,
    });
  }

  // TODO
  fetchMarks(attemptId: QuestionAttemptHttpModel['id']) {
    return from(
      this.supabase.client
        .from('mark_history')
        .select()
        .eq('question_engagement_id', Number(attemptId))
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle()
        .throwOnError()
        .then((d) => d.data),
    );
  }

  // TODO
  fetchUserXp(attemptId: QuestionAttemptHttpModel['id']) {
    return from(
      this.supabase.client
        .from('user_xp_records')
        .select()
        .eq('question_engagement_id', Number(attemptId))
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle()
        .throwOnError()
        .then((d) => d.data),
    );
  }

  // TODO
  fetchUserXpRecords() {
    return from(
      this.supabase.client
        .from('user_xp_records')
        .select()
        .order('created_at', { ascending: false })
        .throwOnError()
        .then((d) => d.data),
    );
  }

  // TODO
  setQuestionVote(
    questionId: QuestionStoreModel['id'],
    options: { vote: boolean; problems?: Feedback[] | string[]; textFeedback?: string },
  ): Observable<undefined | null> {
    return from(
      this.supabase.client
        .rpc('set_question_vote', {
          q_id: questionId,
          user_vote: options.vote,
          user_text_feedback: options.textFeedback || '',
          user_problems: (options.problems as DbJson[]) ?? null,
        })
        .throwOnError()
        .then((res) => res.data),
    );
  }

  // TODO
  removeQuestionVote(voteId: QuestionVoteStoreModel['id']): Observable<void> {
    return from(
      this.supabase.client
        .from('question_votes')
        .delete()
        .eq('id', voteId)
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  // TODO
  fetchQuestionVote(questionId: QuestionStoreModel['id']) {
    return from(
      this.supabase.client
        .from('question_votes')
        .select()
        .eq('question_id', questionId)
        .limit(1)
        .maybeSingle()
        .throwOnError()
        .then((d) => d.data),
    );
  }

  // TODO
  fetchQuestionGradingVote(attemptId: QuestionAttemptHttpModel['id']): Observable<{
    grading_id: number;
    feedback_score: number | null;
  } | null> {
    return from(
      this.supabase.client
        .rpc('get_latest_question_engagement_grading_feedback', {
          // TODO
          question_engagement_id_input: +attemptId,
        })
        .throwOnError()
        .then((res) => (res.data ?? [])[0]),
    );
  }

  // TODO
  setQuestionGradingVote(engagementId: QuestionAttemptHttpModel['id'], score: number, textFeedback: string) {
    return this.http.post<void>(`${environment.examdojo.questionEngagementApi}/grading/${engagementId}/user-feedback`, {
      score,
      text_feedback: textFeedback,
    });
  }

  fetchSignedUrls(imageNames: string[], bucketName: string) {
    return this.supabase.getSignedUrlsForFiles(bucketName, imageNames);
  }
}
