import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  ElementRef,
  input,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { AnimationDuration, getAnimationAppearDisappear } from '@examdojo/animation';
import { FeatureFlagService } from '@examdojo/core/feature-flag';
import { IconComponent } from '@examdojo/core/icon';
import { SwiperComponent, SwiperSlideDirective } from '@examdojo/core/swiper';
import { ExamDojoFeatureFlag, ExamdojoFeatureFlags } from '@examdojo/models/feature-flag';
import { PlatformService } from '@examdojo/platform';
import { ButtonComponent } from '@examdojo/ui/button';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { ImagePreviewDirective } from '@examdojo/ui/image-preview';
import { assertNonNullable } from '@examdojo/util/assert';
import { IonBadge, IonSpinner, IonThumbnail } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { filter, from, map, switchMap } from 'rxjs';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { QuestionAttemptStoreModel } from '../../question-attempt.model';
import { ImageLocation, QuestionAttemptResponseImageUIModel } from '../question-attempt-response-image.model';
import { QuestionAttemptResponseImageQuery } from '../question-attempt-response-images.query';
import { QuestionAttemptResponseImageService } from '../question-attempt-response-images.service';
import { SolutionLinkDialogService } from '../solution-link-dialog/solution-link-dialog.service';
import { SolutionService } from '../solution.service';

@Component({
  selector: 'dojo-solution-images-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './solution-images-list.component.html',
  styleUrls: ['./solution-images-list.component.scss'],
  animations: [getAnimationAppearDisappear({ enter: AnimationDuration.Short })],
  imports: [
    IonThumbnail,
    ButtonComponent,
    SwiperComponent,
    SwiperSlideDirective,
    IonBadge,
    IconComponent,
    IconButtonComponent,
    ImagePreviewDirective,
    IonSpinner,
    TranslocoPipe,
  ],
  providers: [SolutionLinkDialogService],
})
export class SolutionImagesListComponent {
  constructor(
    private readonly solutionService: SolutionService,
    private readonly questionAttemptResponseImageService: QuestionAttemptResponseImageService,
    private readonly destroyRef: DestroyRef,
    private readonly solutionLinkDialogService: SolutionLinkDialogService,
    private readonly questionAttemptResponseImageQuery: QuestionAttemptResponseImageQuery,
    private readonly platformService: PlatformService,
    private readonly featureFlagService: FeatureFlagService<ExamdojoFeatureFlags>,
  ) {}

  readonly swiperOptions: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 8,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
    },
  };

  readonly userId = input.required<string>();
  readonly attemptId = input.required<QuestionAttemptStoreModel['id']>();

  readonly fileInputRef = viewChild<ElementRef<HTMLInputElement>>('fileInput');

  readonly state = connectState({
    allImages: this.questionAttemptResponseImageQuery.selectAllEntities(),
    showQrCode: this.featureFlagService
      .select(ExamDojoFeatureFlag.HideSolutionDialogQrCode)
      .pipe(map((hideSolutionDialogQrCode) => !hideSolutionDialogQrCode)),
    isTestingFlagEnabled: this.featureFlagService.select(ExamDojoFeatureFlag.Testing),
  });

  readonly isDesktop = this.platformService.isDesktop;

  readonly ImageLocation = ImageLocation;

  readonly hasMediaCaptureSupport = computed(() => {
    const inputRef = this.fileInputRef();
    return inputRef && 'capture' in inputRef.nativeElement;
  });

  addNewSolutionImage(forceNativeCapture = false) {
    if (this.hasMediaCaptureSupport() || forceNativeCapture) {
      const fileInput = this.fileInputRef()?.nativeElement;
      assertNonNullable(fileInput, 'fileInputRef');
      fileInput.click();
      return;
    }

    return from(this.solutionService.addNewSolutionImage())
      .pipe(
        filter(Boolean),
        switchMap((image) => {
          return this.solutionService.saveSolutionImage(this.userId(), this.attemptId(), image);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  addNewSolutionImageWithQRCode() {
    this.solutionLinkDialogService.openDialog();
  }

  removeSolutionImage(image: QuestionAttemptResponseImageUIModel) {
    if (image.location === ImageLocation.Remote) {
      this.questionAttemptResponseImageService
        .removeQuestionAttemptResponseImage(image.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
  }

  saveInputImage($event: Event) {
    const file = ($event.target as HTMLInputElement).files?.[0];
    if (!file) {
      return;
    }
    this.solutionService
      .saveSolutionImage(this.userId(), this.attemptId(), file)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
