import { Component, computed, Input, model, signal } from '@angular/core';
import { ClassicButtonComponent } from '@examdojo/core/button';
import { IfLocalizedStringDirective, LocalizedString } from '@examdojo/core/i18n';
import { IconButtonComponent } from '@examdojo/core/icon-button';
import { ImageDropzoneComponent } from '@examdojo/ui/image-dropzone';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { IonHeader, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { MarkschemeImageHttpService } from '../../../markscheme-http.service';

@Component({
  selector: 'exam-markscheme-image-dialog',
  template: `
    <ion-header [translucent]="true" class="shadow-none">
      <ion-toolbar class="toolbar-with-closing-button !px-2 md:!px-4">
        <div slot="start" class="flex">
          <y42-icon-button (click)="closeDialog()" [icon]="'times'" />
        </div>
        <div slot="end" class="w-8"></div>
        <span class="ml-4">Upload markscheme image for the description</span>
      </ion-toolbar>
    </ion-header>
    <div class="flex h-full flex-col justify-between p-4">
      <dojo-markdown-viewer *dojoIfLocalizedString="description ?? ''; let text" [content]="text" />
      @if (selectedImageBlobUrl()) {
        <div class="group">
          <span class="relative inline-block">
            <img [src]="selectedImageBlobUrl()" alt="Markscheme image" class="h-[300px]" />
            <y42-icon-button
              class="absolute right-1 top-1 !hidden bg-gray-100 group-hover:!block"
              [icon]="'times'"
              [size]="'large'"
              (click)="deleteImage()"
            />
          </span>
        </div>
      } @else {
        <dojo-image-dropzone (imageSelected)="selectedImage.set($event)" />
      }

      <div class="small:flex-row small:justify-end mt-6 flex w-full flex-col gap-2">
        <dojo-classic-button (click)="closeDialog()">Cancel</dojo-classic-button>
        <dojo-classic-button
          [color]="'success'"
          (click)="uploadImage()"
          [pending]="imageUploading()"
          [disabled]="!selectedImage()"
        >
          Upload image
        </dojo-classic-button>
      </div>
    </div>
  `,
  imports: [
    ImageDropzoneComponent,
    IonHeader,
    ClassicButtonComponent,
    IonToolbar,
    IconButtonComponent,
    MarkdownViewerComponent,
    IfLocalizedStringDirective,
  ],
})
export class StemMarkschemeImageDialogComponent {
  constructor(
    private readonly modalCtrl: ModalController,
    private readonly markschemeImageHttpService: MarkschemeImageHttpService,
  ) {}

  @Input() readonly description: LocalizedString | undefined;

  readonly selectedImage = model<File | null>();

  readonly selectedImageBlobUrl = computed(() => {
    const selectedImage = this.selectedImage();
    return selectedImage ? URL.createObjectURL(selectedImage) : '';
  });

  readonly imageUploading = signal<boolean>(false);

  deleteImage() {
    this.selectedImage.set(null);
  }

  closeDialog() {
    this.modalCtrl.dismiss(null);
  }

  async uploadImage() {
    const selectedImage = this.selectedImage();
    if (!selectedImage) {
      return;
    }

    this.imageUploading.set(true);

    const path = await this.markschemeImageHttpService.uploadImage(selectedImage);

    this.modalCtrl.dismiss(path);
  }
}
