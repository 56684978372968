{
  "sections": [
    {
      "section_id": "question",
      "section_display_name": "About the Question",
      "checklist": [
        {
          "id": "question_correct_general",
          "display_name": "Is the question content correct and the storyline consistent?",
          "explanation": "https://examdojo.notion.site/Is-the-question-correct-1a8b7b3bb46681c5978bd1996b0f54e6?pvs=73",
          "type": "yes/no",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_IMAGE"],
              "to": "DELETED"
            },
            {
              "from": ["NEEDS_HUMAN_REVIEW"],
              "to": "NEEDS_PRESCREENING"
            }
          ]
        },
        {
          "id": "question_correct_math",
          "display_name": "Is the math correct?",
          "explanation": "https://examdojo.notion.site/Is-the-question-correct-1a8b7b3bb46681c5978bd1996b0f54e6?pvs=73",
          "type": "yes/no",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
              "to": "DELETED"
            }
          ]
        },
        {
          "id": "question_solvable_no_calculator",
          "display_name": "Is the question solvable without a calculator?",
          "type": "yes/no",
          "explanation": "https://examdojo.notion.site/Is-it-solvable-without-a-calculator-1a8b7b3bb466813993f4f0f79c615ac3?pvs=73",
          "display": "has_no_calculator",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
              "to": "DELETED"
            }
          ]
        },
        {
          "id": "question_format_formula",
          "display_name": "Are the formulas formatted correctly?",
          "type": "yes/no",
          "show_extra_text_field": "no_response",
          "explanation": "https://examdojo.notion.site/Is-the-question-formatted-correctly-1a8b7b3bb46681d5923fc428f27f7bb9?pvs=73",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_PRESCREENING"],
              "to": "DELETED"
            },
            {
              "from": ["NEEDS_HUMAN_REVIEW"],
              "to": "NEEDS_PRESCREENING"
            }
          ]
        },
        {
          "id": "question_format_other",
          "display_name": "Is everything else formatted correctly?",
          "explanation": "https://examdojo.notion.site/Is-the-question-formatted-correctly-1a8b7b3bb46681d5923fc428f27f7bb9?pvs=73",
          "type": "yes/no",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_PRESCREENING"],
              "to": "DELETED"
            },
            {
              "from": ["NEEDS_HUMAN_REVIEW"],
              "to": "NEEDS_PRESCREENING"
            }
          ]
        },
        {
          "id": "question_image_correct",
          "display_name": "Is the image correct?",
          "type": "yes/no",
          "explanation": "https://examdojo.notion.site/Is-the-image-correct-1a8b7b3bb466815e89f3d84115e65737?pvs=73",
          "display": "has_question_image",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
              "to": "NEEDS_IMAGE"
            }
          ]
        }
      ]
    },
    {
      "section_id": "markscheme",
      "section_display_name": "About the Markscheme",
      "checklist": [
        {
          "id": "markscheme_correct_general",
          "display_name": "Is the markscheme correct?",
          "type": "yes/no",
          "explanation": "https://examdojo.notion.site/Is-the-markscheme-correct-and-complete-1a8b7b3bb4668188bf5ed5df489e06e1",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
              "to": "DELETED"
            }
          ]
        },
        {
          "id": "markscheme_correct_math",
          "display_name": "Is the math of the markscheme correct?",
          "explanation": "https://examdojo.notion.site/Is-the-markscheme-correct-and-complete-1a8b7b3bb4668188bf5ed5df489e06e1?pvs=73",
          "type": "yes/no",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
              "to": "DELETED"
            }
          ]
        },
        {
          "id": "markscheme_correct_marks",
          "display_name": "Does the markscheme account for all marks?",
          "explanation": "https://examdojo.notion.site/Is-the-markscheme-correct-and-complete-1a8b7b3bb4668188bf5ed5df489e06e1?pvs=73",
          "type": "yes/no",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_IMAGE", "NEEDS_HUMAN_REVIEW"],
              "to": "DELETED"
            }
          ]
        },
        {
          "id": "markscheme_format_formula",
          "display_name": "Are the formulas formatted correctly?",
          "type": "yes/no",
          "explanation": "https://examdojo.notion.site/Is-the-markscheme-and-content-formatted-correctly-1a8b7b3bb4668145b750ea04e6cffd75?pvs=73",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_PRESCREENING"],
              "to": "DELETED"
            },
            {
              "from": ["NEEDS_HUMAN_REVIEW"],
              "to": "NEEDS_PRESCREENING"
            }
          ]
        },
        {
          "id": "markscheme_image_correct",
          "display_name": "Are the images in the markscheme correct?",
          "type": "yes/no",
          "explanation": "https://examdojo.notion.site/Is-the-image-correct-1a8b7b3bb466815e89f3d84115e65737?pvs=73",
          "display": "has_markscheme_image",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
              "to": "NEEDS_IMAGE"
            }
          ]
        },
        {
          "id": "markscheme_format_other",
          "display_name": "Is everything else formatted correctly?",
          "type": "yes/no",
          "explanation": "https://examdojo.notion.site/Is-the-markscheme-and-content-formatted-correctly-1a8b7b3bb4668145b750ea04e6cffd75?pvs=73",
          "show_extra_text_field": "no_response",
          "pass_condition": "yes_response",
          "in_statuses": ["NEEDS_PRESCREENING", "NEEDS_HUMAN_REVIEW"],
          "rejection_statuses": [
            {
              "from": ["NEEDS_PRESCREENING"],
              "to": "DELETED"
            },
            {
              "from": ["NEEDS_HUMAN_REVIEW"],
              "to": "NEEDS_PRESCREENING"
            }
          ]
        }
      ]
    },
    {
      "section_id": "other",
      "section_display_name": "Others",
      "checklist": [
        {
          "id": "other",
          "display_name": "Are there any other issues?",
          "type": "yes/no",
          "show_extra_text_field": "yes_response",
          "display": "always_visible",
          "pass_condition": "no_response",
          "in_statuses": [
            "NEEDS_IMAGE",
            "NEEDS_PRESCREENING",
            "NEEDS_HUMAN_REVIEW"
          ],
          "rejection_statuses": [
            {
              "from": [
                "NEEDS_IMAGE",
                "NEEDS_PRESCREENING",
                "NEEDS_HUMAN_REVIEW"
              ],
              "to": "DELETED"
            }
          ]
        }
      ]
    }
  ]
}
