import { Injectable } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import { SupabaseSelectQueryConfig } from '@examdojo/core/supabase';
import { ErrorHandlerService } from '@examdojo/error-handling';
import {
  QUESTION_CREATE_ALLOWED_KEYS,
  QUESTION_IMAGE_CREATE_ALLOWED_KEYS,
  QUESTION_UPDATE_ALLOWED_KEYS,
  QuestionCreateModel,
  QuestionHttpModel,
  QuestionImageHttpModel,
  QuestionImageStoreModel,
  QuestionItemQuestionImageStoreModel,
  QuestionListItemHttpModel,
  QuestionStoreModel,
  QuestionUpdateModel,
  STEM_CREATE_ALLOWED_KEYS,
  STEM_UPDATE_ALLOWED_KEYS,
  StemCreateModel,
  StemHttpModel,
  StemStoreModel,
  StemUpdateModel,
  STIMULUS_CREATE_ALLOWED_KEYS,
  STIMULUS_UPDATE_ALLOWED_KEYS,
  StimulusCreateModel,
  StimulusHttpModel,
  StimulusStoreModel,
  StimulusUpdateModel,
} from '@examdojo/models/question';
import { mapToVoid } from '@examdojo/rxjs';
import { rethrowError } from '@examdojo/rxjs/rethrow-error';
import { TableInsertModel, TableUpdateModel } from '@examdojo/supabase';
import { isNotNullish } from '@examdojo/util/nullish';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import omit from 'lodash/omit';
import { finalize, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { CmsQuestionHttpService } from './cms-question-http.service';
import { CmsQuestionStore } from './cms-question.store';

@Injectable({ providedIn: 'root' })
export class CmsQuestionService {
  constructor(
    private readonly store: CmsQuestionStore,
    private readonly questionHttpService: CmsQuestionHttpService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  fetchPaginated(
    selectQueryConfig: SupabaseSelectQueryConfig = {},
  ): Observable<{ data: QuestionStoreModel[]; totalCount: number }> {
    return this.questionHttpService.createSelectQuery(selectQueryConfig).pipe(
      map(({ data, totalCount }) => ({
        data: data.map((d) => this.mapQuestionHttpModelToStoreModel(d)),
        totalCount,
      })),
      tap(({ data }) => {
        this.store.upsertMany(data);
      }),
      this.errorHandlerService.catchError('[QuestionService]: Fetching questions failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while fetching the questions',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  fetchPaginatedListView(
    selectQueryConfig: SupabaseSelectQueryConfig = {},
  ): Observable<{ data: QuestionListItemHttpModel[]; totalCount: number }> {
    return this.questionHttpService.fetchPaginatedListView(selectQueryConfig).pipe(
      map((data) => ({
        data: data.data.map((d) => ({
          ...d, // TODO map to store model
        })),
        totalCount: data.totalCount,
      })),
    );
  }

  fetchByReference(id: string): Observable<QuestionStoreModel[]> {
    return this.questionHttpService.getRelatedQuestions(id).pipe(
      map((data) => data.map((d) => this.mapQuestionHttpModelToStoreModel(d))),
      tap((storeModels) => this.store.upsertMany(storeModels)),
      this.errorHandlerService.catchError('[QuestionService]: Fetching questions failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while fetching the questions',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  fetch(id: QuestionStoreModel['id']): Observable<QuestionStoreModel> {
    return this.questionHttpService.fetch(id).pipe(
      map((data) => this.mapQuestionHttpModelToStoreModel(data)),
      tap((storeModel) => this.store.upsert(storeModel.id, storeModel)),
      this.errorHandlerService.catchError('[QuestionService]: Fetching question failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while fetching the question',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  create(createModel: QuestionCreateModel): Observable<void> {
    const question = this.mapQuestionCreateModelToHttpInsertModel(createModel);

    return this.questionHttpService.create(question).pipe(
      tap((data) => {
        this.store.upsert(data.id, this.mapQuestionHttpModelToStoreModel(data));
      }),
      mapToVoid(),
      this.errorHandlerService.catchError('[QuestionService]: Creating question failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while creating the question',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  update(id: QuestionStoreModel['id'], updateModel: QuestionUpdateModel) {
    const question = this.mapQuestionUpdateModelToHttpUpdateModel(updateModel);

    this.store.setSavingState(id, 'savingQuestionMap', true);

    return this.questionHttpService.update(id, question).pipe(
      tap((data) => this.store.update(data.id, this.mapQuestionHttpModelToStoreModel(data))),
      mapToVoid(),
      this.errorHandlerService.catchError('[QuestionService]: Updating question failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while updating the question',
          description: (err as Error)?.message,
        }),
      }),
      finalize(() => this.store.setSavingState(id, 'savingQuestionMap', false)),
    );
  }

  delete(id: number): Observable<void> {
    return this.questionHttpService.delete(id).pipe(
      tap(() => {
        this.store.delete(id);
      }),
      this.errorHandlerService.catchError('[QuestionService]: Deleting question failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while deleting the question',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  checkQuestionWithLlmFeedback(id: QuestionHttpModel['id']): Observable<void> {
    return this.questionHttpService.checkQuestionWithLlmFeedback(id);
  }

  fetchStimulus(stimulusId: number): Observable<StimulusStoreModel> {
    return this.questionHttpService.fetchStimulus(stimulusId).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Fetching stimulus failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while fetching the stimulus',
          description: (err as Error)?.message,
        }),
      }),
      map((model) => this.mapStimulusHttpModelToStoreModel(model)),
    );
  }

  createStimulus(stimulus: TableInsertModel<'stimuli'>): Observable<StimulusStoreModel> {
    return this.questionHttpService.createStimulus(this.mapStimulusCreateModelToHttpInsertModel(stimulus)).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Creating stimulus failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while creating the stimulus',
          description: (err as Error)?.message,
        }),
      }),
      map((model) => this.mapStimulusHttpModelToStoreModel(model)),
    );
  }

  updateStimulus(stimulusId: number, stimulus: TableUpdateModel<'stimuli'>): Observable<StimulusStoreModel> {
    this.store.setSavingState(stimulusId, 'savingStimulusMap', true);
    return this.questionHttpService
      .updateStimulus(stimulusId, this.mapStimulusUpdateModelToHttpUpdateModel(stimulus))
      .pipe(
        this.errorHandlerService.catchError('[QuestionService]: Updating stimulus failed', rethrowError(), {
          toast: (err) => ({
            title: 'An error occurred while updating the stimulus',
            description: (err as Error)?.message,
          }),
        }),
        map((model) => this.mapStimulusHttpModelToStoreModel(model)),
        finalize(() => this.store.setSavingState(stimulusId, 'savingStimulusMap', false)),
      );
  }

  deleteStimulus(stimulusId: number) {
    return this.questionHttpService.deleteStimulus(stimulusId);
  }

  fetchStem(stemId: number): Observable<StemStoreModel & { topics: number[] }> {
    return this.questionHttpService.fetchStem(stemId).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Fetching stem failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while fetching the stem',
          description: (err as Error)?.message,
        }),
      }),

      map((model) => this.mapStemHttpModelToStoreModel(model)),
      switchMap((stem) =>
        this.questionHttpService.fetchStemTopics(stemId).pipe(
          map((topics) => ({
            ...stem,
            topics,
          })),
        ),
      ),
    );
  }

  createStem(stem: TableInsertModel<'stems'>): Observable<StemStoreModel> {
    return this.questionHttpService.createStem(this.mapStemCreateModelToHttpInsertModel(stem)).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Creating stem failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while creating the stem',
          description: (err as Error)?.message,
        }),
      }),
      map((model) => this.mapStemHttpModelToStoreModel(model)),
    );
  }

  updateStem(stemId: number, stem: TableUpdateModel<'stems'>): Observable<StemStoreModel> {
    this.store.setSavingState(stemId, 'savingStemMap', true);
    return this.questionHttpService.updateStem(stemId, this.mapStemUpdateModelToHttpUpdateModel(stem)).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Updating stem failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while updating the stem',
          description: (err as Error)?.message,
        }),
      }),
      map((model) => this.mapStemHttpModelToStoreModel(model)),
      tap(() => this.store.setSavingState(stemId, 'savingStemMap', false)),
      finalize(() => this.store.setSavingState(stemId, 'savingStemMap', false)),
    );
  }

  updateStemTopics(stemId: number, topics: number[]): Observable<void> {
    return this.questionHttpService.updateStemTopics(stemId, topics).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Updating stem topics failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while updating the stem topics',
          description: (err as Error)?.message,
        }),
      }),
      mapToVoid(),
    );
  }

  deleteStem(stemId: number): Observable<void> {
    return this.questionHttpService.deleteStem(stemId).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Deleting stem failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while deleting the stem',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  fetchQuestionImage(imageId: number): Observable<QuestionImageStoreModel> {
    return this.questionHttpService.fetchQuestionImage(imageId).pipe(
      map((model) => this.mapQuestionImageHttpModelToStoreModel(model)),
      this.errorHandlerService.catchError('[QuestionService]: Fetching image failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while fetching the image',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  createQuestionImage(image: TableInsertModel<'question_images'>): Observable<QuestionImageStoreModel> {
    return this.questionHttpService.createQuestionImage(image).pipe(
      map((model) => this.mapQuestionImageHttpModelToStoreModel(model)),
      this.errorHandlerService.catchError('[QuestionService]: Creating image failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while creating the image',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  upsertQuestionImage(
    imageId: number,
    image: TableUpdateModel<'question_images'>,
  ): Observable<QuestionImageStoreModel> {
    this.store.setSavingState(imageId, 'savingQuestionImageMap', true);
    return this.questionHttpService
      .upsertQuestionImage(imageId, this.mapQuestionImageInsertModelToHttpInsertModel(image))
      .pipe(
        map((model) => this.mapQuestionImageHttpModelToStoreModel(model)),
        this.errorHandlerService.catchError('[QuestionService]: Updating image failed', rethrowError(), {
          toast: (err) => ({
            title: 'An error occurred while updating the image',
            description: (err as Error)?.message,
          }),
        }),
        finalize(() => this.store.setSavingState(imageId, 'savingQuestionImageMap', false)),
      );
  }

  deleteQuestionImage(imageId: number): Observable<void> {
    return this.questionHttpService.deleteQuestionImage(imageId).pipe(
      this.errorHandlerService.catchError('[QuestionService]: Deleting image failed', rethrowError(), {
        toast: (err) => ({
          title: 'An error occurred while deleting the image',
          description: (err as Error)?.message,
        }),
      }),
    );
  }

  getImageUrl(imageId: string) {
    return this.questionHttpService.getPublicUrl(imageId);
  }

  getSignedUrlForImage(imageId: string) {
    return this.questionHttpService.getSignedUrl(imageId);
  }

  uploadImage(image: File): Promise<string | null> {
    return this.questionHttpService.uploadImage(image);
  }

  fetchQuestionItems(questionId: QuestionStoreModel['id']) {
    return this.questionHttpService.fetchItems(questionId).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_details' }),
      map((items) =>
        items.map((item) => {
          if (item.item_type === 'stimulus') {
            return this.mapStimulusHttpModelToStoreModel({ ...omit(item, ['item_id']), id: item.item_id });
          } else if (item.item_type === 'stem') {
            return this.mapStemHttpModelToStoreModel({ ...omit(item, ['item_id']), id: item.item_id });
          } else {
            return this.mapQuestionImageHttpModelToStoreModel({ ...omit(item, ['item_id']), id: item.item_id });
          }
        }),
      ),
      switchMap((items) => {
        const questionImageItems = items.filter(
          (item): item is QuestionItemQuestionImageStoreModel => item.type === 'question_image',
        );

        const questionImageItemsWithNames = questionImageItems.filter((item) => !!item.question_image_name);

        const questionImageItemBucketName = questionImageItemsWithNames[0]?.question_image_bucket; // all question image items have the same bucket

        if (!questionImageItemsWithNames.length || !questionImageItemBucketName) {
          return of(items);
        }

        const questionImageNames = questionImageItemsWithNames.map((item) => item.question_image_name);

        return from(this.fetchSignedUrlsForImage(questionImageNames, questionImageItemBucketName)).pipe(
          map((signedUrls) =>
            items.map((item) => {
              if (item.type === 'question_image') {
                (item as QuestionItemQuestionImageStoreModel).questionImageUrl = item.question_image_name
                  ? signedUrls[item.question_image_name]
                  : null;
              }

              return item;
            }),
          ),
        );
      }),
    );
  }

  private async fetchSignedUrlsForImage(imageNames: Array<string | null>, bucketName: string) {
    const data = await this.questionHttpService.fetchSignedUrls(imageNames.filter(isNotNullish), bucketName);

    return data.reduce<Record<string, string | null>>((acc, item) => {
      return {
        ...acc,
        ...(item.path ? { [item.path]: item.error ? null : item.signedUrl } : {}),
      };
    }, {});
  }

  private mapQuestionHttpModelToStoreModel(questionHttpModel: QuestionHttpModel): QuestionStoreModel {
    return {
      ...omit(questionHttpModel, ['author']),
      authorId: questionHttpModel.author,
      items: questionHttpModel.items as QuestionStoreModel['items'],
      markscheme_items: questionHttpModel.markscheme_items as QuestionStoreModel['markscheme_items'],
      llm_feedback: questionHttpModel.llm_feedback as unknown as QuestionStoreModel['llm_feedback'],
    };
  }

  private mapQuestionCreateModelToHttpInsertModel(createModel: QuestionCreateModel): TableInsertModel<'questions'> {
    return sanitizeObject(createModel, QUESTION_CREATE_ALLOWED_KEYS);
  }

  private mapQuestionUpdateModelToHttpUpdateModel(updateModel: QuestionUpdateModel): TableUpdateModel<'questions'> {
    return sanitizeObject(updateModel, QUESTION_UPDATE_ALLOWED_KEYS);
  }

  private mapStemCreateModelToHttpInsertModel(createModel: StemCreateModel): TableInsertModel<'stems'> {
    return sanitizeObject(createModel, STEM_CREATE_ALLOWED_KEYS);
  }

  private mapStemUpdateModelToHttpUpdateModel(updateModel: StemUpdateModel): TableUpdateModel<'stems'> {
    return sanitizeObject(updateModel, STEM_UPDATE_ALLOWED_KEYS);
  }

  private mapStimulusCreateModelToHttpInsertModel(createModel: StimulusCreateModel): TableInsertModel<'stimuli'> {
    return sanitizeObject(createModel, STIMULUS_CREATE_ALLOWED_KEYS);
  }

  private mapStimulusUpdateModelToHttpUpdateModel(updateModel: StimulusUpdateModel): TableUpdateModel<'stimuli'> {
    return sanitizeObject(updateModel, STIMULUS_UPDATE_ALLOWED_KEYS);
  }

  private mapQuestionImageInsertModelToHttpInsertModel(
    updateModel: TableInsertModel<'question_images'>,
  ): TableInsertModel<'question_images'> {
    return sanitizeObject(updateModel, QUESTION_IMAGE_CREATE_ALLOWED_KEYS);
  }

  private mapStimulusHttpModelToStoreModel(httpModel: StimulusHttpModel): StimulusStoreModel {
    return {
      ...omit(httpModel, ['author']),
      authorId: httpModel.author!,
      stimulus: httpModel.stimulus as LocalizedString,
      type: 'stimulus',
    };
  }

  private mapStemHttpModelToStoreModel(httpModel: StemHttpModel): StemStoreModel {
    return {
      ...omit(httpModel, ['author']),
      authorId: httpModel.author!,
      stem: httpModel.stem as LocalizedString[],
      solution: httpModel.solution as LocalizedString,
      type: 'stem',
      markscheme: httpModel.markscheme as StemStoreModel['markscheme'],
      totalMarks: 0,
    };
  }

  private mapQuestionImageHttpModelToStoreModel(
    questionImageHttpModel: QuestionImageHttpModel,
  ): QuestionImageStoreModel {
    return {
      ...omit(questionImageHttpModel, ['author']),
      authorId: questionImageHttpModel.author,
      description: questionImageHttpModel.description as LocalizedString,
      type: 'question_image',
    };
  }
}
