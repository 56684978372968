<div [mtxPopoverTriggerFor]="bannersPopover">
  @for (stat of state.infoBoxesStats; track stat.severity) {
    <div class="mr-2 inline-flex">
      <y42-info-box-severity-icon [severity]="stat.severity" />
      <span class="stat-severity-label ml-1" [class]="'severity-' + stat.severity">{{ stat.label }}</span>
    </div>
  }
</div>

<mtx-popover class="custom-mtx-popover" #bannersPopover="mtxPopover" [position]="['below', 'after']" [xOffset]="-1">
  <div class="flex flex-col gap-2">
    @for (infoBox of state.infoBoxOrdered; track infoBox.message) {
      <y42-info-box [message]="infoBox.message" [severity]="infoBox.severity" [animationDisabled]="true" />
    }
  </div>
</mtx-popover>
