import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IfLocalizedStringDirective } from '@examdojo/core/i18n';
import { MarkGroup } from '@examdojo/models/markscheme';
import { MarkschemeImagePath } from '../markscheme-image';
import { MarkschemeImageComponent } from '../markscheme-image/markscheme-image.component';
import { NodePathDirective } from '../markscheme-image/node-path.directive';
import { MarkschemeMarkComponent } from '../markscheme-mark/markscheme-mark.component';
import { MarkschemeNoteComponent } from '../markscheme-note/markscheme-note.component';

@Component({
  selector: 'dojo-markscheme-markgroup',
  templateUrl: './markscheme-markgroup.component.html',
  imports: [
    MarkschemeMarkComponent,
    NodePathDirective,
    MarkschemeImageComponent,
    MarkschemeNoteComponent,
    IfLocalizedStringDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkschemeMarkgroupComponent {
  @Input({ required: true }) markGroup!: MarkGroup;

  readonly MarkschemeImagePath = MarkschemeImagePath;
}
