import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { connectState, RxInput, RxOnInit } from '@examdojo/angular/util';
import { keys } from '@examdojo/core/util/object-utils';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';
import { InfoBoxComponent } from '../info-box';
import { InfoBoxSeverityIconComponent, Severity } from '../info-box-severity-icon';

export interface InfoBoxConfig {
  message: string;
  severity: Severity;
}

@RxOnInit()
@UntilDestroy()
@Component({
  selector: 'y42-info-box-stats',
  templateUrl: './info-box-stats.component.html',
  styleUrls: ['./info-box-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [InfoBoxSeverityIconComponent, InfoBoxComponent, MtxPopoverModule],
})
export class InfoBoxStatsComponent {
  @Input({ required: true })
  @RxInput()
  infoBoxes: InfoBoxConfig[] = [];
  private readonly infoBoxes$!: Observable<InfoBoxConfig[]>;

  private readonly severityOrderPriority: Record<Severity, number> = {
    success: 1,
    info: 2,
    warn: 3,
    error: 4,
  };

  readonly state = connectState({
    infoBoxesStats: this.infoBoxes$.pipe(
      map((infoBoxes) => {
        const statsCounters: Record<Severity, number> = infoBoxes.reduce(
          (acc, item) => {
            acc[item.severity] = (acc[item.severity] ?? 0) + 1;
            return acc;
          },
          {} as Record<Severity, number>,
        );

        const severityLabels: Record<Severity, { singular: string; plural: string }> = {
          error: { singular: 'error', plural: 'errors' },
          warn: { singular: 'warning', plural: 'warnings' },
          success: { singular: 'success message', plural: 'success messages' },
          info: { singular: 'info message', plural: 'info messages' },
        };

        const stats = keys(statsCounters)
          .map((severity) => {
            const count = statsCounters[severity];
            const label = severityLabels[severity];
            return {
              severity,
              label: `${count} ${count === 1 ? label.singular : label.plural}`,
              priority: this.severityOrderPriority[severity],
            };
          })
          .sort((a, b) => a.priority - b.priority);

        return stats;
      }),
    ),
    infoBoxOrdered: this.infoBoxes$.pipe(
      map((infoBoxes) =>
        infoBoxes.sort((a, b) => this.severityOrderPriority[a.severity] - this.severityOrderPriority[b.severity]),
      ),
    ),
  });
}
