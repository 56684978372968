import { ChangeDetectionStrategy, Component, DestroyRef, input, output } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControlsOf, markAllAs } from '@examdojo/angular/forms';
import { connectState } from '@examdojo/angular/util';
import { DateTimeModule } from '@examdojo/core/date-time';
import { IconComponent } from '@examdojo/core/icon';
import { TextareaInputComponent } from '@examdojo/core/input';
import { StimulusStoreModel } from '@examdojo/models/question';
import { shareOneReplay } from '@examdojo/rxjs';
import { ButtonComponent } from '@examdojo/ui/button';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { isNotNullish } from '@examdojo/util/nullish';
import { catchError, EMPTY, filter, of, switchMap, tap } from 'rxjs';
import { CmsQuestionQuery } from '../../cms-question.query';
import { CmsQuestionService } from '../../cms-question.service';

type StimulusEditorForm = FormControlsOf<{
  stimulus: string;
}>;

@Component({
  selector: 'dojo-stimulus-editor',
  templateUrl: './stimulus-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DateTimeModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TextareaInputComponent,
    MarkdownViewerComponent,
    IconComponent,
    ButtonComponent,
  ],
})
export class StimulusEditorComponent {
  constructor(
    private readonly questionService: CmsQuestionService,
    private readonly cmsQuestionQuery: CmsQuestionQuery,
    private readonly destroyRef: DestroyRef,
  ) {
    this.stimulus$
      .pipe(
        filter(isNotNullish),
        tap((stimulus) => {
          if (this.form.pristine) {
            this.form.patchValue({ stimulus: stimulus.stimulus.en ?? '' });
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly finishedSaving = output<void>();

  readonly id = input.required<StimulusStoreModel['id']>();
  readonly stimulus$ = toObservable(this.id).pipe(
    switchMap((id) => this.questionService.fetchStimulus(id)),
    shareOneReplay(),
  );

  readonly form = new FormGroup<StimulusEditorForm>({
    stimulus: new FormControl('', { nonNullable: true }),
  });

  readonly state = connectState({
    stimulus: this.stimulus$,
    isSaving: toObservable(this.id).pipe(
      switchMap((id) => (id ? this.cmsQuestionQuery.selectIsSaving('savingStimulusMap', id) : of(false))),
    ),
  });

  save() {
    const formValue = this.form.value;

    if (this.form.pristine) {
      return;
    }

    markAllAs('touched', this.form);

    if (!this.form.valid) {
      return;
    }

    this.questionService
      .updateStimulus(this.id(), {
        stimulus: { en: formValue.stimulus },
      })
      .pipe(
        tap(() => {
          this.form.markAsPristine();
          this.finishedSaving.emit();
        }),
        catchError(() => EMPTY),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
