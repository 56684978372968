import { TableInsertModel, TableRowModel, TableUpdateModel, ViewRowModel } from '@examdojo/supabase';
import { UserUIModel } from '../user';
import { QuestionImageStoreModel } from './question-image.model';
import { StemUIModel } from './stem.model';
import { StimulusUIModel } from './stimulus.model';

export type QuestionHttpModel = Pick<
  TableRowModel<'questions'>,
  | 'id'
  | 'question_number'
  | 'author'
  | 'items'
  | 'markscheme_items'
  | 'llm_feedback'
  | 'calculator_allowed'
  | 'created_at'
  | 'needs_graphics'
  | 'status'
  | 'updated_at'
  | 'updated_by'
>;
export type QuestionListItemHttpModel = ViewRowModel<'admin_question_list_view'>;

export type QuestionItemType = 'stimulus' | 'stem' | 'question_image';

export type MarkSchemeItemType = 'text' | 'image';

export type QuestionItemQuestionImageStoreModel = QuestionImageStoreModel & {
  questionImageUrl: string | null;
};

export type QuestionItemQuestionImageUIModel = QuestionItemQuestionImageStoreModel;

export type QuestionItemsUIModel = Array<StimulusUIModel | StemUIModel | QuestionItemQuestionImageUIModel>;

export type QuestionStoreModel = Omit<
  QuestionHttpModel,
  'sys_period' | 'author' | 'items' | 'markscheme_items' | 'llm_feedback'
> & {
  authorId: QuestionHttpModel['author'];
  items: Array<{ item_id: number; item_type: QuestionItemType }>;
  markscheme_items: Array<{ item_id: number; item_type: MarkSchemeItemType }>;
  llm_feedback: {
    thinking: string;
    stems_feedback: StemLlmFeedback[];
    markscheme_feedback: string;
    everything_is_correct: boolean;
  };
};

export interface StemLlmFeedback {
  stem_id: number;
  feedback: string;
  stem_letter: string;
}

export type QuestionUIModel = QuestionStoreModel & {
  author: UserUIModel | null;
};

export const QUESTION_CREATE_ALLOWED_KEYS = [
  'status',
  'items',
  'markscheme_items',
  'calculator_allowed',
  'question_number',
  'feedback',
] satisfies Array<keyof TableInsertModel<'questions'>>;

export type QuestionCreateModel = Pick<TableInsertModel<'questions'>, (typeof QUESTION_CREATE_ALLOWED_KEYS)[number]>;

export const QUESTION_UPDATE_ALLOWED_KEYS = [...QUESTION_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'questions'>
>;

export type QuestionUpdateModel = Pick<TableUpdateModel<'questions'>, (typeof QUESTION_UPDATE_ALLOWED_KEYS)[number]>;

export interface QuestionItemBase<T extends QuestionItemType> {
  type: T;
}

export enum QuestionGradingPreset {
  NoAnswer = 'no_answer',
  SomeMarks = 'some_marks',
  FullMarks = 'full_marks',
  GradingError = 'grading_error',
}

export function getOrderedSubstemTagValue(orderNumber: number): string {
  const romanNumerals = [
    'i',
    'ii',
    'iii',
    'iv',
    'v',
    'vi',
    'vii',
    'viii',
    'ix',
    'x',
    'xi',
    'xii',
    'xiii',
    'xiv',
    'xv',
    'xvi',
    'xvii',
    'xviii',
    'xix',
    'xx',
  ];

  if (orderNumber < 0 || orderNumber > romanNumerals.length) {
    return 'i';
  }

  return romanNumerals[orderNumber];
}

export function getOrderedItemTagValue(orderNumber: number): string {
  return String.fromCharCode(65 + orderNumber);
}
