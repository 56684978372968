import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TopicLevel2Query, TopicLevel3Query } from '@examdojo/category/v2';
import { shareOneReplay } from '@examdojo/core/rxjs';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { SubStem } from '@examdojo/models/markscheme';
import { getOrderedItemTagValue, getOrderedSubstemTagValue, StemStoreModel } from '@examdojo/models/question';
import { Query } from '@examdojo/state';
import { combineLatest, distinctUntilChanged, map, Observable } from 'rxjs';
import { QuestionItemsUIModel, QuestionState, StemUIModel } from './question-v2.model';
import { QuestionStore } from './question-v2.store';
import { GradedStemStoreModel } from './solution';
import { SelfGradableMark, SelfGradableMarkPoint, SelfGradableSubStem } from './solution/self-gradable-result-model';

@Injectable({ providedIn: 'root' })
export class QuestionQuery extends Query<QuestionState> {
  constructor(
    protected override readonly store: QuestionStore,
    private readonly topicLevel3Query: TopicLevel3Query,
    private readonly topicLevel2Query: TopicLevel2Query,
  ) {
    super(store);
  }

  readonly questionLoaded$ = this.select('questionLoaded').pipe(
    map((questionLoaded) => !!questionLoaded),
    distinctUntilChanged(),
  );

  readonly attempt$ = this.select('attempt');
  readonly attemptStatus$ = this.attempt$.pipe(
    map((attempt) => attempt?.status),
    distinctUntilChanged(),
  );
  readonly attemptStatuses$ = this.select('attemptStatuses');
  readonly hasNewGrading$ = this.select('hasNewGrading');
  readonly gradingError$ = this.select('gradingError');

  readonly selfGradingAllowed$ = this.gradingError$.pipe(
    map((gradingError) => gradingError?.error_code === 'grading_error'),
    distinctUntilChanged(),
  );

  readonly timeSpent$ = combineLatest([this.attempt$, this.attemptStatuses$]).pipe(
    map(([attempt, statuses]) => {
      if (!statuses) {
        return null;
      }

      const submittedStatuses = statuses.filter((status) => status.status === 'SUBMITTED');
      const start = attempt?.created_at;

      if (!submittedStatuses.length || !start) {
        return null;
      }

      // Get the latest submitted status
      const endTime = Math.max(...submittedStatuses.map((status) => new Date(status.created_at).getTime()));

      return endTime - new Date(start).getTime();
    }),
  );

  readonly buttonText$ = this.attempt$.pipe(
    map((attempt) => {
      return attempt?.status === 'GRADED' ? 'explanation' : 'get_hint';
    }),
  );

  readonly questionFlagged$ = this.select('userVote').pipe(map((vote) => vote?.vote === false));

  readonly openCaptureDialog$ = this.select('openCaptureDialog');

  readonly question$ = this.select('question');
  readonly questionItems$: Observable<QuestionItemsUIModel | undefined> = combineLatest([
    this.select('items'),
    this.select('gradings'),
  ]).pipe(
    map(([items, gradingResults]) => {
      if (!items) {
        return items;
      }

      const gradingResult = gradingResults?.[0]?.grading_result;

      const stemIdToIndexMap = items
        .filter((item) => item.type === 'stem')
        .reduce((stemToIndexMap, item, index) => {
          stemToIndexMap.set(item.id, index);
          return stemToIndexMap;
        }, new Map<number, number>());

      return items.map((item) => {
        if (item.type !== 'stem') {
          return item;
        }

        const stemItemIndex = stemIdToIndexMap.get(item.id)!;

        return this.stemModelToUIModel(item as StemStoreModel, stemItemIndex, gradingResult?.[stemItemIndex]);
      });
    }),
    shareOneReplay(),
  );

  readonly questionGradingAwardedPoints$ = this.questionItems$.pipe(
    map((items) => {
      if (!items) {
        return null;
      }

      return items.reduce((sum, item) => {
        if (item.type === 'stem' && item.gradingResult) {
          return sum + item.gradingResult.awardedMarks;
        }

        return sum;
      }, 0);
    }),
  );

  readonly questionGradingTotalPoints$ = this.questionItems$.pipe(
    map((items) => {
      if (!items) {
        return null;
      }

      return items.reduce((sum, item) => {
        if (item.type === 'stem') {
          return sum + item.totalMarks;
        }

        return sum;
      }, 0);
    }),
  );

  private stemModelToUIModel(
    stem: StemStoreModel,
    stemIndex: number,
    gradingResult?: GradedStemStoreModel,
  ): StemUIModel {
    const label = getOrderedItemTagValue(stemIndex);

    const labeledMarkscheme =
      stem.markscheme?.map((subStem, index) => {
        const { topicLevel3IDs, selfGradableSubStem } = this.getTopicLevel3IDsAndSelfGradable(subStem);

        const topicLevel2IDs = topicLevel3IDs
          .map((topicLevel3Id) => {
            return this.topicLevel3Query.getEntity(topicLevel3Id)?.topic_level_02_id ?? null;
          })
          .filter(isNotNullish);

        const topicLevel2Models = topicLevel2IDs
          .map((topicLevel2Id) => {
            return this.topicLevel2Query.getUIEntity(topicLevel2Id);
          })
          .filter(isNotNullish);

        return {
          ...selfGradableSubStem,
          topicLevel2Models,
          label: getOrderedSubstemTagValue(index),
        };
      }) ?? null;

    return {
      ...stem,
      label,
      markscheme: labeledMarkscheme,
      ...(gradingResult && { gradingResult }),
    };
  }

  private getTopicLevel3IDsAndSelfGradable(subStem: SubStem): {
    topicLevel3IDs: number[];
    selfGradableSubStem: SelfGradableSubStem;
  } {
    const subStemTopicLevel3IdSet = new Set<number>();

    const selfGradableSubStem = {
      ...subStem,
      methods: subStem.methods.map((method) => ({
        ...method,
        mark_groups: method.mark_groups.map((markGroup) => ({
          ...markGroup,
          mark_group_alternatives: markGroup.mark_group_alternatives.map((markGroupAlternative) => ({
            ...markGroupAlternative,
            marks: markGroupAlternative.marks.map((mark) => {
              if (mark.topic_id) {
                subStemTopicLevel3IdSet.add(Number(mark.topic_id));

                return {
                  ...mark,
                  points: mark.points.map(
                    (point) =>
                      ({
                        ...point,
                        formCtrl: new FormControl(false, { nonNullable: true }),
                      }) as SelfGradableMarkPoint,
                  ),
                };
              }
              return mark as SelfGradableMark;
            }),
          })),
        })),
      })),
    };

    return { selfGradableSubStem, topicLevel3IDs: Array.from(subStemTopicLevel3IdSet) };
  }
}
