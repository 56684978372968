import { Injectable } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import {
  MARKSCHEME_IMAGE_CREATE_ALLOWED_KEYS,
  MARKSCHEME_IMAGE_UPDATE_ALLOWED_KEYS,
  MARKSCHEME_TEXT_CREATE_ALLOWED_KEYS,
  MARKSCHEME_TEXT_UPDATE_ALLOWED_KEYS,
  MarkschemeImageHttpModel,
  MarkschemeImageStoreModel,
  MarkschemeTextHttpModel,
  MarkschemeTextStoreModel,
} from '@examdojo/models/question';
import { TableInsertModel, TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import omit from 'lodash/omit';
import { map, Observable } from 'rxjs';
import { MarkschemeImageHttpService, MarkschemeTextHttpService } from './markscheme-http.service';

@Injectable({ providedIn: 'root' })
export class MarkschemeService {
  constructor(
    private readonly markschemeTextHttpService: MarkschemeTextHttpService,
    private readonly markschemeImageHttpService: MarkschemeImageHttpService,
  ) {}

  fetchMarkschemeImage(imageId: number): Observable<MarkschemeImageStoreModel> {
    return this.markschemeImageHttpService
      .fetch(imageId)
      .pipe(map((model) => this.mapMarkschemeImageHttpModelToStoreModel(model)));
  }

  createMarkschemeImage(insertModel: TableInsertModel<'markscheme_images'>): Observable<MarkschemeImageStoreModel> {
    return this.markschemeImageHttpService
      .create(this.mapMarkschemeImageInsertModelToHttpInsertModel(insertModel))
      .pipe(map((model) => this.mapMarkschemeImageHttpModelToStoreModel(model)));
  }

  updateMarkschemeImage(
    imageId: number,
    updateModel: TableUpdateModel<'markscheme_images'>,
  ): Observable<MarkschemeImageStoreModel> {
    return this.markschemeImageHttpService
      .update(imageId, this.mapMarkschemeImageUpdateModelToHttpUpdateModel(updateModel))
      .pipe(map((model) => this.mapMarkschemeImageHttpModelToStoreModel(model)));
  }

  deleteMarkschemeImage(imageId: number): Observable<void> {
    return this.markschemeImageHttpService.delete(imageId);
  }

  fetchMarkschemeText(textId: number): Observable<MarkschemeTextStoreModel> {
    return this.markschemeTextHttpService
      .fetch(textId)
      .pipe(map((model) => this.mapMarkschemeTextHttpModelToStoreModel(model)));
  }

  createMarkschemeText(insertModel: TableInsertModel<'markscheme_texts'>): Observable<MarkschemeTextStoreModel> {
    return this.markschemeTextHttpService
      .create(this.mapMarkschemeTextInsertModelToHttpInsertModel(insertModel))
      .pipe(map((model) => this.mapMarkschemeTextHttpModelToStoreModel(model)));
  }

  updateMarkschemeText(
    textId: number,
    updateModel: TableUpdateModel<'markscheme_texts'>,
  ): Observable<MarkschemeTextStoreModel> {
    return this.markschemeTextHttpService
      .update(textId, this.mapMarkschemeTextUpdateModelToHttpUpdateModel(updateModel))
      .pipe(map((model) => this.mapMarkschemeTextHttpModelToStoreModel(model)));
  }

  deleteMarkschemeText(textId: number): Observable<void> {
    return this.markschemeTextHttpService.delete(textId);
  }

  getImageUrl(imageId: string) {
    return this.markschemeImageHttpService.getPublicUrl(imageId);
  }

  getSignedUrlForImage(imageId: string) {
    return this.markschemeImageHttpService.getSignedUrl(imageId);
  }

  uploadImage(image: File): Promise<string | null> {
    return this.markschemeImageHttpService.uploadImage(image);
  }

  private mapMarkschemeImageInsertModelToHttpInsertModel(
    model: TableInsertModel<'markscheme_images'>,
  ): TableInsertModel<'markscheme_images'> {
    return sanitizeObject(model, MARKSCHEME_IMAGE_CREATE_ALLOWED_KEYS);
  }

  private mapMarkschemeTextInsertModelToHttpInsertModel(
    model: TableInsertModel<'markscheme_texts'>,
  ): TableInsertModel<'markscheme_texts'> {
    return sanitizeObject(model, MARKSCHEME_TEXT_CREATE_ALLOWED_KEYS);
  }

  private mapMarkschemeImageUpdateModelToHttpUpdateModel(
    model: TableUpdateModel<'markscheme_images'>,
  ): TableUpdateModel<'markscheme_images'> {
    return sanitizeObject(model, MARKSCHEME_IMAGE_UPDATE_ALLOWED_KEYS);
  }

  private mapMarkschemeTextUpdateModelToHttpUpdateModel(
    model: TableUpdateModel<'markscheme_texts'>,
  ): TableUpdateModel<'markscheme_texts'> {
    return sanitizeObject(model, MARKSCHEME_TEXT_UPDATE_ALLOWED_KEYS);
  }

  private mapMarkschemeImageHttpModelToStoreModel(
    markschemeImageHttpModel: MarkschemeImageHttpModel,
  ): MarkschemeImageStoreModel {
    return {
      ...omit(markschemeImageHttpModel, ['author']),
      authorId: markschemeImageHttpModel.author,
      description: markschemeImageHttpModel.description as LocalizedString,
      type: 'markscheme_image',
    };
  }

  private mapMarkschemeTextHttpModelToStoreModel(
    markschemeTextHttpModel: MarkschemeTextHttpModel,
  ): MarkschemeTextStoreModel {
    return {
      ...omit(markschemeTextHttpModel, ['author']),
      authorId: markschemeTextHttpModel.author,
      text: markschemeTextHttpModel.text as LocalizedString,
      type: 'markscheme_text',
    };
  }
}
