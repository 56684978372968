<div class="flex flex-col p-4">
  <div class="header flex flex-col justify-between gap-2">
    <div class="flex items-center justify-between">
      <div class="text-md font-medium">
        <dojo-icon class="mr-2" [icon]="'text'" />
        Stimulus description
      </div>

      <dojo-button [disabled]="form.pristine" (click)="save()" [pending]="state.isSaving">
        <dojo-icon class="mr-1 mt-1" [icon]="'save'" />
        Save
      </dojo-button>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <y42-textarea-input
        [hasInitialFocus]="true"
        [autoResize]="true"
        class="h-full"
        [formCtrl]="form.controls.stimulus"
        [errorMessages]="{ required: 'Stimulus description is required' }"
      />

      <dojo-markdown-viewer [content]="form.controls.stimulus.getRawValue()" />
    </div>
  </div>
</div>
