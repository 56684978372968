import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { SupabaseSelectQueryConfig } from '@examdojo/core/supabase';
import {
  QUESTION_IMAGES_STORAGE_BUCKET,
  QuestionHttpModel,
  QuestionImageHttpModel,
  QuestionListItemHttpModel,
  QuestionStoreModel,
  StemHttpModel,
  StimulusHttpModel,
} from '@examdojo/models/question';
import { mapToVoid } from '@examdojo/rxjs';
import { SupabaseBaseHttpService, TableInsertModel, TableUpdateModel } from '@examdojo/supabase';
import { from, map, Observable } from 'rxjs';

const questionsBaseUrl = `${environment.examdojo.cmsApiUrl}/questions`;

@Injectable({
  providedIn: 'root',
})
export class CmsQuestionHttpService extends SupabaseBaseHttpService<'questions'> {
  readonly http = inject(HttpClient);

  readonly schema = 'public';
  readonly tableName = 'questions';
  override readonly storage_bucket = QUESTION_IMAGES_STORAGE_BUCKET;

  checkQuestionWithLlmFeedback(questionId: QuestionHttpModel['id']): Observable<void> {
    return this.http.post(`${questionsBaseUrl}/${questionId}/review`, {}).pipe(mapToVoid());
  }

  fetchPaginatedListView(
    selectQueryConfig: SupabaseSelectQueryConfig = {},
  ): Observable<{ data: QuestionListItemHttpModel[]; totalCount: number }> {
    const query = this.supabase.createSelectQuery('public', 'admin_question_list_view', selectQueryConfig);

    return from(query).pipe(
      map(({ data, count }) => ({
        data: data!,
        totalCount: count!,
      })),
    ) as Observable<{ data: QuestionListItemHttpModel[]; totalCount: number }>;
  }

  getRelatedQuestions(referenceQuestionId: string): Observable<QuestionHttpModel[]> {
    return from(
      this.supabase.client
        .from('questions')
        .select('*, generation_runs!inner(reference_question_id)')
        .eq('generation_runs.reference_question_id', Number(referenceQuestionId))
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  fetchStem(stemId: number): Observable<StemHttpModel> {
    return from(
      this.supabase.client
        .from('stems')
        .select('*')
        .eq('id', stemId)
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  createStem(stem: TableInsertModel<'stems'>): Observable<StemHttpModel> {
    return from(
      this.supabase.client
        .from('stems')
        .insert(stem)
        .select('*')
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  updateStem(stemId: number, stem: TableUpdateModel<'stems'>): Observable<StemHttpModel> {
    return from(
      this.supabase.client
        .from('stems')
        .update(stem)
        .eq('id', stemId)
        .select('*')
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  fetchStemTopics(stemId: number): Observable<number[]> {
    return from(
      this.supabase.client
        .from('stems_topics')
        .select('topic_id')
        .eq('stem_id', stemId)
        .throwOnError()
        .then((response) => response.data!.map((data) => data.topic_id)),
    );
  }

  deleteStem(stemId: number): Observable<void> {
    return from(
      this.supabase.client
        .from('stems')
        .delete()
        .eq('id', stemId)
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  fetchStimulus(stimulusId: number): Observable<StimulusHttpModel> {
    return from(
      this.supabase.client
        .from('stimuli')
        .select('*')
        .eq('id', stimulusId)
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  createStimulus(stimulus: TableInsertModel<'stimuli'>): Observable<StimulusHttpModel> {
    return from(
      this.supabase.client
        .from('stimuli')
        .insert(stimulus)
        .select()
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  updateStimulus(stimulusId: number, stimulus: TableUpdateModel<'stimuli'>): Observable<StimulusHttpModel> {
    return from(
      this.supabase.client
        .from('stimuli')
        .update(stimulus)
        .eq('id', stimulusId)
        .select()
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  updateStemTopics(stemId: number, topics: number[]): Observable<number[]> {
    // TODO: adding + removing doesn't sound like a most efficient way of doing that.
    return from(
      this.supabase.client
        .from('stems_topics')
        .delete()
        .eq('stem_id', stemId)
        .then(() =>
          this.supabase.client
            .from('stems_topics')
            .insert(
              topics.map((topicId) => ({
                stem_id: stemId,
                topic_id: topicId,
              })),
            )
            .then(() => topics),
        ),
    );
  }

  deleteStimulus(stimulusId: number): Observable<void> {
    return from(
      this.supabase.client
        .from('stimuli')
        .delete()
        .eq('id', stimulusId)
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  fetchQuestionImage(imageId: number): Observable<QuestionImageHttpModel> {
    return from(
      this.supabase.client
        .from('question_images')
        .select()
        .eq('id', imageId)
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  createQuestionImage(image: TableInsertModel<'question_images'>): Observable<QuestionImageHttpModel> {
    return from(
      this.supabase.client
        .from('question_images')
        .insert(image)
        .select()
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  upsertQuestionImage(imageId: number, image: TableUpdateModel<'question_images'>): Observable<QuestionImageHttpModel> {
    return from(
      this.supabase.client
        .from('question_images')
        .upsert(image)
        .eq('id', imageId)
        .select()
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  deleteQuestionImage(imageId: number): Observable<void> {
    return from(
      this.supabase.client
        .from('question_images')
        .delete()
        .eq('id', imageId)
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  // Fetches all items
  fetchItems(questionId: QuestionStoreModel['id']) {
    return from(
      this.supabase.client
        .rpc('get_question_items', {
          p_question_id: questionId,
        })
        .throwOnError()
        .then(
          (res) =>
            (res.data ?? []) as Array<
              (
                | ({ item_type: 'stimulus' } & Omit<StimulusHttpModel, 'id'>)
                | ({ item_type: 'stem' } & Omit<StemHttpModel, 'id'>)
                | ({ item_type: 'question_image' } & Omit<QuestionImageHttpModel, 'id'>)
              ) & { item_id: number }
            >,
        ),
    );
  }

  fetchSignedUrls(imageNames: string[], bucketName: string) {
    return this.supabase.getSignedUrlsForFiles(bucketName, imageNames);
  }
}
