import { ChangeDetectionStrategy, Component, input, Optional } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { ClassicButtonComponent } from '@examdojo/core/button';
import { IconButtonComponent } from '@examdojo/core/icon-button';
import { MarkschemeImage } from '@examdojo/models/markscheme';
import { switchMap } from 'rxjs';
import { NodePathDirective } from './node-path.directive';
import { MarkschemeImageService } from './provide-markscheme-image-service';

@Component({
  selector: 'dojo-markscheme-image',
  template: `
    @if (image().bucket_name && image().image_name && state.imageUrl) {
      <div class="group">
        <span class="relative inline-block">
          <img dojoImagePreview class="h-[150px]" [src]="state.imageUrl" />
          @if (markschemeImageService && allowEdit()) {
            <y42-icon-button
              class="absolute right-1 top-1 !hidden bg-gray-100 group-hover:!block"
              [icon]="'edit'"
              [size]="'large'"
              (click)="openImageDialog()"
            />
          }
        </span>
      </div>
    } @else if (markschemeImageService && allowEdit()) {
      <dojo-classic-button [color]="'warn'" (click)="openImageDialog()">Upload Image</dojo-classic-button>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconButtonComponent, ClassicButtonComponent],
})
export class MarkschemeImageComponent {
  constructor(
    private readonly currentNode: NodePathDirective,
    @Optional() readonly markschemeImageService: MarkschemeImageService,
  ) {}

  readonly allowEdit = input<boolean>(true);
  readonly image = input.required<MarkschemeImage>();

  readonly imageUrl$ = toObservable(this.image).pipe(
    switchMap((image) =>
      image.image_name && image.bucket_name ? this.markschemeImageService.fetchSignedUrlForImage(image.image_name) : '',
    ),
  );

  readonly state = connectState({
    imageUrl: this.imageUrl$,
  });

  async openImageDialog() {
    if (this.image().image_name && this.image().bucket_name) {
      try {
        await this.markschemeImageService.deleteImage(this.image(), this.currentNode.path);
      } catch (error) {
        // Ignore the error, an image may not exist if it's
        // been deleted but it's still a part of the markscheme.
        console.error(error);
      }
    }

    this.markschemeImageService.openImageDialog(this.image().description, this.currentNode.path);
  }

  deleteImage() {
    this.markschemeImageService.deleteImage(this.image(), this.currentNode.path);
  }
}
