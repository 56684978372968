import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeatureFlagDirective } from '@examdojo/core/feature-flag';
import { ExamDojoFeatureFlag } from '@examdojo/models/feature-flag';

@Component({
  selector: 'dojo-testing-section',
  imports: [FeatureFlagDirective],
  template: `
    <div
      *ngIfFeatureFlag="ExamDojoFeatureFlag.Testing"
      class="relative flex flex-col border border-dashed border-green-400 bg-green-100 p-6"
    >
      <div class="absolute left-1 top-1 text-xs uppercase text-green-400">Testing</div>

      <div>
        <ng-content />
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'block' },
})
export class TestingSectionComponent {
  protected readonly ExamDojoFeatureFlag = ExamDojoFeatureFlag;
}
