import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { IconComponent } from '@examdojo/core/icon';
import { TextareaInputComponent } from '@examdojo/ui/input';
import { ChecklistItem, ChecklistItemId, ChecklistItemType } from '../settings/ibdp_math';

@Component({
  selector: 'dojo-consensus-checklist-item',
  template: `
    @if (!!valueControl) {
      <div class="checklist-item p-4">
        @if (item.type === ChecklistItemType.YesNo) {
          <div class="question" [class]="item.explanation ? 'mb-0' : 'mb-2'">{{ item.display_name }}</div>
          @if (item.explanation) {
            <a
              class="mb-2 flex w-[132px] items-center text-sm text-gray-700 hover:text-blue-500"
              target="_blank"
              [href]="item.explanation"
            >
              See explanation
              <dojo-icon class="ml-1" size="12" [icon]="'arrow-right'" />
            </a>
          }

          <mat-button-toggle-group
            [formControl]="valueControl"
            class="custom-toggle-group small mb-2 w-full"
            (change)="onValueChange()"
          >
            <mat-button-toggle [value]="true" class="flex-1">Yes</mat-button-toggle>
            <mat-button-toggle [value]="false" class="flex-1">No</mat-button-toggle>
          </mat-button-toggle-group>
        }

        @if (shouldShowExtraTextField()) {
          <div class="mt-2">
            <dojo-textarea-input
              [placeholder]="'Describe why you are reporting this'"
              [resizable]="true"
              [formCtrl]="commentControl!"
              class="feedback-field !flex"
            />
          </div>
        }
      </div>
    }
  `,
  styles: [
    `
      .question {
        @apply text-md font-normal;
      }

      .nested-checklist {
        padding-left: 10px;
        border-left: 1px solid #d5d5d5;
      }

      .custom-toggle-group {
        display: flex;
        width: 60px;
      }
    `,
  ],
  imports: [
    TextareaInputComponent,
    ReactiveFormsModule,
    MatRadioModule,
    MatButtonToggleModule,
    TextareaInputComponent,
    IconComponent,
  ],
})
export class ConsensusChecklistItemComponent {
  @Input() item!: ChecklistItem;
  @Input() formGroupName!: ChecklistItemId;
  @Input() parentFormGroup!: FormGroup;
  @Input() contextData: {
    containsQuestionImage: boolean;
    containsMarkschemeImage: boolean;
    calculatorAllowed: boolean;
    parent: null;
  } = {
    containsQuestionImage: false,
    containsMarkschemeImage: false,
    calculatorAllowed: false,
    parent: null,
  };

  @Input() shouldShowExtraTextFieldFn!: (item: ChecklistItem, formPath?: string) => boolean;
  @Input() isItemVisibleFn!: (item: ChecklistItem, parentValue?: string | number | boolean | null) => boolean;

  @Output() valueChange = new EventEmitter<void>();
  @Output() showExplanation = new EventEmitter<string>();

  readonly ChecklistItemType = ChecklistItemType;

  // Add this property to reference the component type itself
  selfComponentType = ConsensusChecklistItemComponent;

  onValueChange(): void {
    this.updateFormValidators();
    this.valueChange.emit();
  }

  get valueControl(): FormControl | null {
    return this.getFormControl();
  }

  get commentControl(): FormControl | null {
    const group = this.parentFormGroup.get(this.formGroupName) as FormGroup;
    if (!group) return null;

    // If comment control doesn't exist yet, create it
    if (!group.get('comment')) {
      group.addControl('comment', new FormControl(''));
    }

    return group.get('comment') as FormControl;
  }

  // Add this method to update validators based on conditions
  updateFormValidators(): void {
    if (!this.commentControl) return;

    if (this.shouldShowExtraTextField()) {
      this.commentControl.setValidators([Validators.required]);
    } else {
      this.commentControl.clearValidators();
    }

    this.commentControl.updateValueAndValidity();
  }

  getFormControl(): FormControl | null {
    const control = this.parentFormGroup.get(this.formGroupName);
    if (!control) return null;

    // If this is a FormGroup (as expected in the ConsensusFeedbackFormComponent),
    // return the 'value' control within it
    if (control instanceof FormGroup && control.get('value')) {
      return control.get('value') as FormControl;
    }

    // Fallback - return the control itself if it's a FormControl
    return control as FormControl;
  }

  shouldShowExtraTextField(): boolean {
    return this.shouldShowExtraTextFieldFn(this.item, this.formGroupName);
  }

  isSubItemVisible(subItem: ChecklistItem): boolean {
    return this.isItemVisibleFn(subItem, this.valueControl?.getRawValue());
  }

  // Add this method to the component class
  getNestedFormGroupPath(subItemId: string): ChecklistItemId {
    return `${this.formGroupName}.additional_checklist.${subItemId}` as ChecklistItemId;
  }

  // Helper method to create inputs for the dynamic component
  getChildComponentInputs(subItem: ChecklistItem) {
    return {
      item: subItem,
      formGroupName: this.getNestedFormGroupPath(subItem.id),
      parentFormGroup: this.parentFormGroup,
      contextData: this.contextData,
      shouldShowExtraTextFieldFn: this.shouldShowExtraTextFieldFn,
      isItemVisibleFn: this.isItemVisibleFn,
    };
  }
}
