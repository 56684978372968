import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  input,
  model,
  output,
  viewChildren,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControlsOf } from '@examdojo/angular/forms';
import { connectState } from '@examdojo/angular/util';
import { TopicLevel2Query, TopicLevel3Query, TopicLevel3UIModel } from '@examdojo/category';
import { ClassicButtonComponent } from '@examdojo/core/button';
import { ConfirmDialogService } from '@examdojo/core/confirm-dialog';
import { DateTimeModule } from '@examdojo/core/date-time';
import { IconComponent } from '@examdojo/core/icon';
import { SingleSelectComponent } from '@examdojo/core/select';
import { SubStem } from '@examdojo/models/markscheme';
import {
  getOrderedItemTagValue,
  getOrderedSubstemTagValue,
  MarkschemeImageStoreModel,
  MarkSchemeItemType,
  MarkschemeTextStoreModel,
  QUESTION_STATUS_TO_LABEL,
  QUESTION_STATUSES,
  QuestionCreateModel,
  QuestionItemType,
  QuestionStatus,
  QuestionStoreModel,
  QuestionUIModel,
  QuestionUpdateModel,
  StemLlmFeedback,
  StemStoreModel,
  StemUIModel,
} from '@examdojo/models/question';
import { ToastService } from '@examdojo/toast';
import { isNotNullish } from '@examdojo/util/nullish';
import { SelectOption } from '@examdojo/util/select-option';
import isEqual from 'lodash/isEqual';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  fromEvent,
  map,
  merge,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { CmsQuestionQuery } from '../cms-question.query';
import { CmsQuestionService } from '../cms-question.service';
import { MarkschemeService } from '../markscheme.service';
import { QuestionImageEditorComponent } from './question-image-editor/question-image-editor.component';
import { QuestionPreviewDialogService } from './question-preview-dialog/question-preview-dialog.service';
import { SaveIndicatorComponent } from './save-indicator/save-indicator.component';
import { StemEditorComponent } from './stem-editor/stem-editor.component';
import { StimulusEditorComponent } from './stimulus-editor/stimulus-editor.component';

type QuestionEditorForm = FormControlsOf<Pick<QuestionStoreModel, 'status' | 'markscheme_items' | 'items'>>;

@Component({
  selector: 'dojo-question-editor',
  imports: [
    ClassicButtonComponent,
    DateTimeModule,
    ReactiveFormsModule,
    SingleSelectComponent,
    MatTooltipModule,
    StemEditorComponent,
    StimulusEditorComponent,
    QuestionImageEditorComponent,
    IconComponent,
    SaveIndicatorComponent,
    MatExpansionModule,
  ],
  templateUrl: './question-editor.component.html',
  styleUrl: './question-editor.component.scss',
  host: { class: 'flex flex-col gap-4 p-4 overflow-y-auto' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [QuestionPreviewDialogService],
})
export class QuestionEditorComponent {
  constructor(
    private readonly questionService: CmsQuestionService,
    private readonly destroyRef: DestroyRef,
    private readonly toastService: ToastService,
    private readonly confirmDialogService: ConfirmDialogService,
    private readonly cmsQuestionQuery: CmsQuestionQuery,
    private readonly markschemeService: MarkschemeService,
    private readonly questionPreviewDialogService: QuestionPreviewDialogService,
    private readonly topicLevel3Query: TopicLevel3Query,
    private readonly topicLevel2Query: TopicLevel2Query,
  ) {
    this.question$
      .pipe(
        filter(isNotNullish),
        tap((question) => {
          // prevent changing the form if the form is being still edited (and later saved).
          if (!this.questionMetadataForm.dirty) {
            this.questionMetadataForm.reset({
              status: question.status,
              markscheme_items: question.markscheme_items ?? '',
              items: question.items,
            });
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.questionMetadataForm.valueChanges
      .pipe(
        debounceTime(5000),
        switchMap(() => this.save().pipe(catchError(() => EMPTY))),
        takeUntilDestroyed(),
      )
      .subscribe();

    fromEvent(window, 'beforeunload')
      .pipe(
        filter(() => this.form.dirty),
        tap((event) => event.preventDefault()),
        takeUntilDestroyed(),
      )
      .subscribe();

    merge(
      toObservable(this.stemComponents).pipe(tap((components) => this.syncFormControls(components, 'stems'))),
      toObservable(this.stimulusComponents).pipe(tap((components) => this.syncFormControls(components, 'stimuli'))),
      toObservable(this.questionImageComponents).pipe(
        tap((components) => this.syncFormControls(components, 'questionImages')),
      ),
      toObservable(this.adminMode).pipe(
        tap((isAdmin) => {
          if (!isAdmin) {
            this.questionMetadataForm.controls.status.disable();
          } else {
            this.questionMetadataForm.controls.status.enable();
          }
        }),
      ),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  readonly question = input<QuestionUIModel>();
  readonly adminMode = input(false);
  readonly showHeader = input(true);

  readonly deleted = output();

  readonly id = computed(() => this.question()?.id);

  readonly loadingLlmFeedback = model(false);

  readonly question$ = toObservable(this.question);

  readonly questionMetadataForm = new FormGroup<QuestionEditorForm>({
    status: new FormControl('DRAFT', { nonNullable: true, validators: [Validators.required] }),
    markscheme_items: new FormControl([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
    items: new FormControl([], {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  readonly form = new FormGroup({
    _questionMetadataForm: this.questionMetadataForm,
    stems: new FormGroup({}),
    stimuli: new FormGroup({}),
    questionImages: new FormGroup({}),
    markschemeTexts: new FormGroup({}),
    markschemeImages: new FormGroup({}),
  });

  readonly questionStatusOptions = QUESTION_STATUSES.map(
    (type): SelectOption<QuestionStatus> => ({ label: QUESTION_STATUS_TO_LABEL[type], value: type }),
  );

  readonly stemComponents = viewChildren(StemEditorComponent);
  readonly stimulusComponents = viewChildren(StimulusEditorComponent);
  readonly questionImageComponents = viewChildren(QuestionImageEditorComponent);

  readonly questionItems$ = this.questionMetadataForm.controls.items.valueChanges.pipe(
    startWith(this.questionMetadataForm.controls.items.value),
    distinctUntilChanged((x, y) => isEqual(x, y)),
  );

  readonly markschemeItems$ = this.questionMetadataForm.controls.markscheme_items.valueChanges.pipe(
    startWith(this.questionMetadataForm.controls.markscheme_items.value),
    distinctUntilChanged((x, y) => isEqual(x, y)),
  );

  readonly state = connectState({
    question: this.question$,
    questionItems: this.questionItems$,
    markschemeItems: this.markschemeItems$,
    questionSubmitted: this.questionMetadataForm.controls.status.valueChanges.pipe(
      startWith(this.questionMetadataForm.controls.status.value),
      map((status) => status === 'NEEDS_MARKSCHEME_V2'),
    ),
    isSaving: toObservable(this.id).pipe(
      switchMap((id) => (id ? this.cmsQuestionQuery.selectIsSaving('savingQuestionMap', id) : of(false))),
    ),
    stemLlmFeedback: this.question$.pipe(
      map((question) => {
        if (!question?.llm_feedback) {
          return {} as Record<string, StemLlmFeedback>;
        }

        return question?.llm_feedback.stems_feedback.reduce(
          (acc, feedback) => ({
            ...acc,
            [feedback.stem_id]: feedback.feedback,
          }),
          {} as Record<string, StemLlmFeedback>,
        );
      }),
      startWith({} as Record<string, StemLlmFeedback>),
    ),
  });

  syncFormControls(
    components: ReadonlyArray<StemEditorComponent | StimulusEditorComponent | QuestionImageEditorComponent>,
    formControlName: 'stems' | 'stimuli' | 'questionImages',
  ) {
    for (const component of components) {
      if (this.form.contains(component.id().toString())) {
        continue;
      }
      this.form.controls[formControlName].addControl(`${component.id()}`, component.form);
    }
    for (const key of Object.keys(this.form.controls)) {
      if (!components.find((c) => c.id().toString() === key)) {
        this.form.controls[formControlName].removeControl(`${key}`);
      }
    }
  }

  previewQuestion() {
    // Getting all the information from local editors would require quite a lot of work as we currently
    // don't keep that in the store/query.
    // We could potentially save here to make sure that we get everything up to date.
    this.questionService
      .fetchQuestionItems(this.id()!)
      .pipe(
        switchMap((questionItems) => {
          const totalMarks = this.calculateQuestionTotalMarks(questionItems.filter((item) => item.type === 'stem'));

          let stemIndex = 0;
          return this.questionPreviewDialogService.openDialog({
            options: {
              componentProps: {
                markscheme: questionItems
                  .filter((item) => item.type === 'stem')
                  .map((item, index) => ({
                    stem_letter: getOrderedItemTagValue(index),
                    sub_stems: item.markscheme ?? [],
                  })),
                question: this.state.question,
                totalMarks: totalMarks,
                questionItems: questionItems.map((item) => {
                  if (item.type === 'stem') {
                    return this.stemModelToUIModel(item, stemIndex++);
                  }
                  return item;
                }),
              },
            },
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  readonly trackByQuestionItemFn = (item: { item_id: number; item_type: QuestionItemType }) =>
    `${item.item_type}-${item.item_id}`;

  readonly trackByMarkschemeItemFn = (item: { item_id: number; item_type: MarkSchemeItemType }) =>
    `${item.item_type}-${item.item_id}`;

  createMarkschemeText() {
    this.markschemeService
      .createMarkschemeText({
        text: { en: '' },
        question_id: this.id()!,
      })
      .pipe(
        tap((markschemeText) => {
          this.questionMetadataForm.controls.markscheme_items.markAsDirty();
          this.questionMetadataForm.patchValue({
            markscheme_items: [
              ...this.questionMetadataForm.getRawValue().markscheme_items,
              {
                item_id: markschemeText.id,
                item_type: 'text',
              },
            ],
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  createMarkschemeImage() {
    this.markschemeService
      .createMarkschemeImage({
        description: { en: '' },
        question_id: this.id()!,
      })
      .pipe(
        tap((markschemeImage) => {
          this.questionMetadataForm.controls.markscheme_items.markAsDirty();
          this.questionMetadataForm.patchValue({
            markscheme_items: [
              ...this.questionMetadataForm.getRawValue().markscheme_items,
              {
                item_id: markschemeImage.id,
                item_type: 'image',
              },
            ],
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  deleteMarkschemeImage(markschemeImageId: MarkschemeImageStoreModel['id']) {
    const items = this.questionMetadataForm
      .getRawValue()
      .markscheme_items.filter((item) => item.item_id !== markschemeImageId || item.item_type !== 'image');

    this.questionMetadataForm.controls.items.markAsDirty();
    this.questionMetadataForm.patchValue({ markscheme_items: items });

    this.questionService.deleteQuestionImage(markschemeImageId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  deleteMarkschemeText(markschemeTextId: MarkschemeTextStoreModel['id']) {
    const items = this.questionMetadataForm
      .getRawValue()
      .markscheme_items.filter((item) => item.item_id !== markschemeTextId || item.item_type !== 'text');

    this.questionMetadataForm.controls.markscheme_items.markAsDirty();
    this.questionMetadataForm.patchValue({ markscheme_items: items });

    this.questionService.deleteQuestionImage(markschemeTextId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  save() {
    const question = this.state.question;

    if (!question) {
      this.toastService.error('Question not found');
      return EMPTY;
    }

    const questionFormValue: QuestionUpdateModel | QuestionCreateModel = this.questionMetadataForm.getRawValue();

    const id = this.id();

    if (!id) {
      this.toastService.error('Creating questions manually is not supported');
      return EMPTY;
    }

    if (this.questionMetadataForm.pristine) {
      return EMPTY;
    }

    return this.questionService
      .update(id, questionFormValue)
      .pipe(tap(() => this.questionMetadataForm.markAsPristine()));
  }

  deleteQuestion() {
    const id = this.id();

    if (!id) {
      return;
    }

    this.confirmDialogService
      .confirm({
        title: 'Delete question',
        message: 'Are you sure you want to delete this question?',
        confirmLabel: 'Delete',
        severity: 'warn',
      })
      .pipe(
        switchMap((confirmed) => {
          if (!confirmed) {
            return EMPTY;
          }

          return this.questionService.update(id, { status: 'DELETED' }).pipe(catchError(() => EMPTY));
        }),
        tap(() => this.toastService.success('Question deleted')),
        tap(() => this.deleted.emit()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  async copyQuestion() {
    const stems = this.stemComponents().map((stem) => ({
      id: stem.id(),
      value: stem.form.controls.stem.getRawValue(),
    }));
    const stimuli = this.stimulusComponents().map((stimulus) => ({
      id: stimulus.id(),
      value: stimulus.form.controls.stimulus.getRawValue(),
    }));
    const questionImages = this.questionImageComponents().map((questionImage) => ({
      id: questionImage.id(),
      value: questionImage.form.controls.description.getRawValue(),
    }));

    let text = '';

    for (const item of this.state.questionItems) {
      switch (item.item_type) {
        case 'stem': {
          const stem = stems.find((s) => s.id === item.item_id);
          if (stem) {
            text += `${stem.value ?? ''}\n\n`;
          }
          break;
        }
        case 'stimulus': {
          const stimulus = stimuli.find((s) => s.id === item.item_id);
          if (stimulus) {
            text += `${stimulus.value ?? ''}\n\n`;
          }
          break;
        }
        case 'question_image': {
          const questionImage = questionImages.find((q) => q.id === item.item_id);
          if (questionImage) {
            text += `${questionImage.value}\n\n`;
          }
          break;
        }
      }
    }

    await window.navigator.clipboard.writeText(text);
    this.toastService.success('Question copied to clipboard');
  }

  checkQuestionWithLlmFeedback() {
    const id = this.id();

    if (!id) {
      return;
    }

    if (this.questionMetadataForm.controls.status.value === 'NEEDS_IMAGE') {
      if (!this.form.controls.stems.valid) {
        // The only validation in stem control - markscheme image warning
        this.toastService.error('All stem markschemes must have images uploaded');
      } else {
        this.questionMetadataForm.controls.status.markAsDirty();
        this.questionMetadataForm.patchValue({
          status: 'NEEDS_IMAGE_REVIEW',
        });
      }
      return;
    }

    this.questionMetadataForm.controls.status.markAsDirty();
    this.questionMetadataForm.patchValue({
      status: 'NEEDS_MARKSCHEME_V2',
    });
  }

  // TODO: Move it to QuestionQuery? Ideally we need a shared place for CMS and student app.
  private stemModelToUIModel(stem: StemStoreModel, stemIndex: number): StemUIModel {
    const label = getOrderedItemTagValue(stemIndex);

    const labeledMarkscheme =
      stem.markscheme?.map((subStem, index) => {
        const topicLevel3IDs = this.getTopicLevel3IDsFromSubStem(subStem);

        const topicLevel2IDs = topicLevel3IDs
          .map((topicLevel3Id) => {
            return this.topicLevel3Query.getEntity(topicLevel3Id)?.topic_level_02_id ?? null;
          })
          .filter(isNotNullish);

        const topicLevel2Models = topicLevel2IDs
          .map((topicLevel2Id) => {
            return this.topicLevel2Query.getUIEntity(topicLevel2Id);
          })
          .filter(isNotNullish);

        return {
          ...subStem,
          topicLevel2Models,
          label: getOrderedSubstemTagValue(index),
        };
      }) ?? null;

    return {
      ...stem,
      label,
      markscheme: labeledMarkscheme,
      totalMarks: stem.total_marks ?? 0,
    };
  }

  private calculateQuestionTotalMarks(stemQuestionItems: StemStoreModel[]): number {
    return stemQuestionItems.reduce((sum, item) => sum + (item.total_marks ?? 0), 0);
  }

  private getTopicLevel3IDsFromSubStem(subStem: SubStem): Array<TopicLevel3UIModel['id']> {
    const subStemTopicLevel3IdSet = new Set<number>();

    for (const method of subStem.methods) {
      for (const markGroup of method.mark_groups) {
        for (const markGroupAlternative of markGroup.mark_group_alternatives) {
          for (const mark of markGroupAlternative.marks) {
            if (mark.topic_id) {
              subStemTopicLevel3IdSet.add(Number(mark.topic_id));
            }
          }
        }
      }
    }

    return Array.from(subStemTopicLevel3IdSet);
  }
}
