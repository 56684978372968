import { Injectable } from '@angular/core';
import { QuestionAttemptStatusHttpModel } from '@examdojo/question';
import { SupabaseBaseHttpService } from '@examdojo/supabase';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuestionAttemptHttpService extends SupabaseBaseHttpService<'question_attempts', 'learning'> {
  readonly schema = 'learning';
  readonly tableName = 'question_attempts';

  fetchStatuses(id: QuestionAttemptStatusHttpModel['question_attempt_id']) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from('question_attempt_statuses')
        .select('*')
        .eq('question_attempt_id', id)
        .throwOnError()
        .then((response) => response.data ?? []),
    );
  }

  fetchChats(id: QuestionAttemptStatusHttpModel['question_attempt_id']) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from('question_attempt_chats')
        .select('*')
        .eq('question_attempt_id', id)
        .throwOnError()
        .then((response) => response.data ?? []),
    );
  }
}
