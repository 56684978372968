import { QuestionStatus } from '@examdojo/models/question';
import { ConditionReference } from '../question-user-feedback';

export interface SurveyConfig {
  sections: SurveySection[];
}

export enum SectionId {
  QUESTION = 'question',
  MARKSCHEME = 'markscheme',
  OTHER = 'other',
  PRESCREEN = 'pre_screen',
}

export enum ChecklistItemType {
  YesNo = 'yes/no',
}

export enum ChecklistItemId {
  QuestionCorrectGeneral = 'question_correct_general',
  QuestionCorrectMath = 'question_correct_math',
  QuestionSolvableNoCalculator = 'question_solvable_no_calculator',

  QuestionImageCorrect = 'question_image_correct',

  QuestionFormatFormula = 'question_format_formula',
  QuestionFormatOther = 'question_format_other',

  MarkschemeCorrectGeneral = 'markscheme_correct_general',
  MarkschemeCorrectMath = 'markscheme_correct_math',
  MarkschemeCorrectMarks = 'markscheme_correct_marks',

  MarkschemeImageCorrect = 'markscheme_image_correct',

  MarkschemeFormatFormula = 'markscheme_format_formula',
  MarkschemeFormatOther = 'markscheme_format_other',

  Other = 'other',
}

export interface SurveySection {
  section_id: SectionId;
  section_display_name: string;
  checklist: ChecklistItem[];
}

export interface ChecklistItem {
  id: ChecklistItemId;
  display_name: string;
  type: ChecklistItemType;
  pass_condition: ConditionReference;
  in_statuses: QuestionStatus[];
  rejection_statuses: Array<{
    from: QuestionStatus[];
    to: QuestionStatus;
  }>;

  show_extra_text_field?: ConditionReference;
  explanation?: string;
  display?: ConditionReference;
  value?: boolean | string | number;
}
