import { Injectable } from '@angular/core';
import { Camera, CameraResultType, Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { randUuid } from '@ngneat/falso';
import { SOLUTION_IMAGE_STORAGE_BUCKET_NAME } from './question-attempt-response-image.model';

@Injectable()
export class SolutionImageService {
  constructor(private readonly supabaseService: ExamdojoSupabaseService) {}

  async takePicture(): Promise<null | Photo> {
    try {
      return await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.DataUrl,
      });
    } catch (error) {
      return null;
    }
  }

  async uploadImage(image: Photo, fileName: string): Promise<string> {
    if (!image.dataUrl) {
      throw new Error('DataUrl is required. Image might not be taken with correct resultType');
    }

    const fileType = `image/${image.format}`;

    const file = Capacitor.isNativePlatform()
      ? this.dataURLtoArrayBuffer(image.dataUrl)
      : await this.dataURLtoFileBlob(image.dataUrl, fileType);

    return this.supabaseService.uploadFile(SOLUTION_IMAGE_STORAGE_BUCKET_NAME, file, fileType, fileName);
  }

  getFileNameFromPath(userId: string, format: string): string {
    return `${userId}/${randUuid()}.${format}`;
  }

  private async dataURLtoFileBlob(path: string, format: string): Promise<File> {
    try {
      const response = await fetch(path);
      const blob = await response.blob();
      return new File([blob], 'file', { type: format });
    } catch (error) {
      throw new Error('[SolutionImageService]: Failed to get file blob from path');
    }
  }

  private dataURLtoArrayBuffer(dataURL: string) {
    // Split the dataURL into the metadata and the base64-encoded data
    const [, base64Data] = dataURL.split(',');

    // Decode base64 to binary string using modern API
    const binaryString = window.atob ? window.atob(base64Data) : Buffer.from(base64Data, 'base64').toString('binary');

    // Create an ArrayBuffer with the same length as the binary string
    const arrayBuffer = new ArrayBuffer(binaryString.length);

    // Create a view to work with the buffer
    const uint8Array = new Uint8Array(arrayBuffer);

    // Fill the view with the binary data
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    return arrayBuffer;
  }
}
