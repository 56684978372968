import { Injectable } from '@angular/core';
import { mapToVoid } from '@examdojo/core/rxjs';
import { QuestionStoreModel, QuestionUIModel } from '@examdojo/models/question/question.model';
import { QueryEntity } from '@examdojo/state';
import { combineLatest, filter, map } from 'rxjs';
import { CmsQuestionState, CmsQuestionStore, QuestionStateSavingProp } from './cms-question.store';

@Injectable({ providedIn: 'root' })
export class CmsQuestionQuery extends QueryEntity<CmsQuestionState, QuestionStoreModel, QuestionUIModel, 'id'> {
  constructor(protected override store: CmsQuestionStore) {
    super(store);
  }

  readonly questionUpdated$ = combineLatest([
    this.selectProp('savingQuestionMap'),
    this.selectProp('savingStemMap'),
    this.selectProp('savingStimulusMap'),
    this.selectProp('savingQuestionImageMap'),
  ]).pipe(
    filter((maps) => maps.every((m) => Object.values(m).every((x) => !x))),
    mapToVoid(),
  );

  selectIsSaving(propName: QuestionStateSavingProp, id: number) {
    return this.selectProp(propName).pipe(map((savingMap) => savingMap[id] ?? false));
  }

  override readonly toUIModelFn = (entity: QuestionStoreModel) => {
    return {
      ...entity,
      author: null, // entity.authorId ? this.userQuery.getUIEntity(entity.authorId) : null,
      created_at: `${entity.created_at}Z`,
      updated_at: `${entity.updated_at}Z`,
    };
  };
}
