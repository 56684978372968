import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IfLocalizedStringDirective } from '@examdojo/core/i18n';
import { Mark } from '@examdojo/models/markscheme';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { MarkschemeImageComponent } from '../markscheme-image/markscheme-image.component';
import { MarkschemeNoteComponent } from '../markscheme-note/markscheme-note.component';
import { MarkschemePointComponent } from '../markscheme-point/markscheme-point.component';

@Component({
  selector: 'dojo-markscheme-mark',
  templateUrl: './markscheme-mark.component.html',
  styleUrls: ['./markscheme-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MarkdownViewerComponent,
    MarkschemeImageComponent,
    MarkschemePointComponent,
    MarkschemeNoteComponent,
    IfLocalizedStringDirective,
  ],
})
export class MarkschemeMarkComponent {
  @Input({ required: true }) mark!: Mark;
}
