import { Directive, Input, Optional, SkipSelf } from '@angular/core';

@Directive({
  selector: '[dojoNodePath]',
  standalone: true,
})
export class NodePathDirective {
  constructor(@Optional() @SkipSelf() private readonly parentContainer: NodePathDirective) {}

  @Input({ required: true }) nodeKey: string | undefined;
  @Input({ required: true }) nodeIndex: number | undefined;

  path: { [key: string]: number } = {};

  ngOnInit() {
    const inheritedPath = this.parentContainer ? { ...this.parentContainer.path } : {};

    if (this.nodeKey !== undefined && this.nodeIndex !== undefined) {
      inheritedPath[this.nodeKey] = this.nodeIndex;
    }

    this.path = inheritedPath;
  }
}
