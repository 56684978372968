export interface QuestionFeedback {
  problems: string[];
  textFeedback: string;
}

export const PROBLEMS = [
  { id: 'question_incorrect', description: 'question_incorrect_description' },
  { id: 'question_math_incorrect', description: 'question_math_incorrect_description' },
  { id: 'question_formula_formatting', description: 'question_formula_formatting_description' },
  { id: 'question_other_formatting', description: 'question_other_formatting_description' },
  { id: 'question_unsolveable', description: 'question_unsolveable_description' },
  { id: 'question_unsolveable_without_calculator', description: 'question_unsolveable_without_calculator_description' },
  { id: 'question_bad_image', description: 'question_bad_image_description' },
  { id: 'question_other_specify', description: 'question_other_specify_description' },
  { id: 'markscheme_incorrect', description: 'markscheme_incorrect_description' },
  { id: 'markscheme_math_incorrect', description: 'markscheme_math_incorrect_description' },
  { id: 'markscheme_formula_formatting', description: 'markscheme_formula_formatting_description' },
  { id: 'markscheme_other_formatting', description: 'markscheme_other_formatting_description' },
  { id: 'markscheme_missing_marks', description: 'markscheme_missing_marks_description' },
  { id: 'markscheme_bad_image', description: 'markscheme_bad_image_description' },
  { id: 'markscheme_other_specify', description: 'markscheme_other_specify_description' },
] as const;

export type Problem = (typeof PROBLEMS)[number]['id'];

export const PROBLEMS_FOR_STUDENTS = [
  { id: 'problems_question', description: 'problems_question_description' },
  { id: 'problems_markscheme', description: 'problems_markscheme_description' },
  { id: 'problems_grading', description: 'problems_grading_description' },
  { id: 'other_specify', description: 'other_specify_description' },
] as const;

export type ProblemForStudents = (typeof PROBLEMS_FOR_STUDENTS)[number]['id'];
