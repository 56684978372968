import { Constants, Enum } from '@examdojo/supabase';

export type QuestionStatus = Enum<'question_status'>;

export const QUESTION_STATUSES = Constants.public.Enums.question_status;

// TODO: map to translation keys instead
export const QUESTION_STATUS_TO_LABEL: Record<QuestionStatus, string> = {
  NEEDS_REVIEW: 'Needs Review',
  PUBLISHED: 'Published',
  PUBLISHED_V2: 'Published V2',
  DRAFT: 'Draft',
  DELETED: 'Deleted',
  GENERALLY_AVAILABLE: 'Generally Available',
  NEEDS_IMAGE: 'Needs Image',
  IMPOSSIBLE_TO_FIX_BY_AI: 'Impossible to Fix by AI',
  NEEDS_MARKSCHEME: 'Needs Markscheme',
  NEEDS_MARKSCHEME_V2: 'Needs Markscheme V2',
  INCORRECT_STEMS_AMOUNT: 'Incorrect Stems Amount',
  NEEDS_IMAGE_REVIEW: 'Needs Image Review',
  INCONSISTENT_POINTS: 'Inconsistent Points',
  HUMAN_REVIEWED: 'Human Reviewed',
  NEEDS_HUMAN_REVIEW: 'Needs Human Review',
  NEEDS_FIX: 'Needs Fix',
  NEEDS_AI_REVIEW: 'Needs AI Review',
  NEEDS_PRESCREENING: 'Needs Prescreening',
  SNAPSHOT: 'Snapshot',
};
