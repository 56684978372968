import { Injectable, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { mapToVoid } from '@examdojo/rxjs';
import { BehaviorSubject, isObservable, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DebuggingContextService {
  private readonly context$$ = new BehaviorSubject<Record<string, unknown>>({});

  readonly context$ = this.context$$.asObservable();

  setContext(key: string, value: unknown) {
    this.context$$.next({ ...this.context$$.value, [key]: value });
  }

  resetContextKey(key: string) {
    const { [key]: _, ...rest } = this.context$$.value;
    this.context$$.next(rest);
  }

  syncDebuggingContext(key: string, source: Observable<unknown> | Signal<unknown>) {
    const source$ = isObservable(source) ? source : toObservable(source);

    return source$.pipe(
      tap({
        next: (activity) => {
          this.setContext(key, activity);
        },
        complete: () => {
          this.resetContextKey(key);
        },
      }),
      mapToVoid(),
    );
  }
}
