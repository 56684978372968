import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, TemplateRef } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { IfLocalizedStringDirective } from '@examdojo/core/i18n';
import { calculateTotalMarksPerStem, Markscheme, Stem } from '@examdojo/models/markscheme';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { MarkschemeImagePath } from '../markscheme-image';
import { MarkschemeImageComponent } from '../markscheme-image/markscheme-image.component';
import { NodePathDirective } from '../markscheme-image/node-path.directive';
import { MarkschemeMarkgroupComponent } from '../markscheme-markgroup/markscheme-markgroup.component';
import { MarkschemeNoteComponent } from '../markscheme-note/markscheme-note.component';

@Component({
  selector: 'dojo-markscheme-viewer',
  templateUrl: './markscheme-viewer.component.html',
  host: {
    class: 'flex flex-col gap-6',
  },
  styleUrls: ['./markscheme-viewer.component.scss'],
  imports: [
    MarkschemeMarkgroupComponent,
    MarkschemeImageComponent,
    NodePathDirective,
    MarkschemeNoteComponent,
    IfLocalizedStringDirective,
    MarkdownViewerComponent,
    MatMenuModule,
    NgTemplateOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkschemeViewerComponent {
  readonly markScheme = input.required<Markscheme>();
  readonly hideTotalMarks = input<boolean>(false);
  readonly stemHeaderTemplate = input<TemplateRef<{ $implicit: Stem; index: number }>>();
  readonly allowImageDelete = input<boolean>(false);

  readonly stemMarksList = computed(() => {
    const stemsList = this.markScheme();

    return calculateTotalMarksPerStem(stemsList);
  });

  readonly totalMarks = computed(() => {
    return this.stemMarksList().reduce((sum, mark) => sum + mark, 0);
  });

  readonly MarkschemeImagePath = MarkschemeImagePath;
}
