@if (markGroup.image) {
  <dojo-markscheme-image [image]="markGroup.image" />
}
<div class="flex flex-col">
  @for (markGroupAlternative of markGroup.mark_group_alternatives; track $index) {
    <ng-container dojoNodePath [nodeKey]="MarkschemeImagePath.MarkGroupAlternative" [nodeIndex]="$index">
      @if (markGroupAlternative.image) {
        <dojo-markscheme-image [image]="markGroupAlternative.image" />
      }
      <div class="mark-group-alternative mb-4 flex flex-col">
        <h3 *dojoIfLocalizedString="markGroupAlternative.name; let name" class="font-bold">{{ name }}</h3>

        @for (mark of markGroupAlternative.marks; track $index) {
          <dojo-markscheme-mark
            [class]="$index === 0 ? 'mt-0' : 'mt-4'"
            [mark]="mark"
            dojoNodePath
            [nodeKey]="MarkschemeImagePath.Mark"
            [nodeIndex]="$index"
          />
        }

        <dojo-markscheme-note *dojoIfLocalizedString="markGroupAlternative.note; let note" [note]="note" />
      </div>
    </ng-container>
  }
  <dojo-markscheme-note *dojoIfLocalizedString="markGroup.note; let note" [note]="note" class="block" />
</div>
