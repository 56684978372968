import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';
import { from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PracticeActivityHttpService extends SupabaseBaseHttpService<'practice_activities', 'learning'> {
  readonly schema = 'learning';
  readonly tableName = 'practice_activities';

  getPracticeActivityQuestionCandidates(practice_activity_id: string) {
    return from(
      this.supabase.client
        .schema('learning')
        .from('practice_activity_candidates')
        .select()
        .eq('practice_activity_id', practice_activity_id)
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
