import { LocalizedString } from '@examdojo/core/i18n';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { QuestionItemBase } from './question.model';

export type QuestionImageHttpModel = Pick<
  TableRowModel<'question_images'>,
  | 'author'
  | 'created_at'
  | 'description'
  | 'id'
  | 'question_id'
  | 'question_image_bucket'
  | 'question_image_name'
  | 'updated_at'
>;
export type QuestionImageStoreModel = Omit<QuestionImageHttpModel, 'author' | 'description'> &
  QuestionItemBase<'question_image'> & {
    authorId: QuestionImageHttpModel['author'];
    description: LocalizedString;
  };
export type QuestionImageUIModel = QuestionImageStoreModel;

export const QUESTION_IMAGE_CREATE_ALLOWED_KEYS = [
  'question_id',
  'description',
  'question_image_name',
  'id',
] as const satisfies Array<keyof TableInsertModel<'question_images'>>;

export type QuestionImageCreateModel = Pick<
  TableInsertModel<'question_images'>,
  (typeof QUESTION_IMAGE_CREATE_ALLOWED_KEYS)[number]
>;

export const QUESTION_IMAGE_UPDATE_ALLOWED_KEYS = [...QUESTION_IMAGE_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'question_images'>
>;

export type QuestionImageUpdateModel = Pick<
  TableUpdateModel<'question_images'>,
  (typeof QUESTION_IMAGE_UPDATE_ALLOWED_KEYS)[number]
>;

export const QUESTION_IMAGES_STORAGE_BUCKET = 'question-images';
