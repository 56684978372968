import { EntityState } from '@examdojo/state';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { QuestionMark } from './question-mark.model';
import { QuestionStoreModel } from './question.model';

export type QuestionEngagementHttpModel = Pick<
  TableRowModel<'question_engagements'>,
  | 'category_id'
  | 'category_level'
  | 'created_at'
  | 'id'
  | 'language'
  | 'marks'
  | 'post_grading_llm_chat_id'
  | 'pre_grading_llm_chat_id'
  | 'question_id'
  | 'updated_at'
  | 'user_id'
>;

export type QuestionEngagementStoreModel = Omit<QuestionEngagementHttpModel, 'marks'> & {
  marks: QuestionMark[];
};

export type QuestionEngagementUIModel = QuestionEngagementStoreModel;

export interface QuestionEngagementState extends EntityState<QuestionStoreModel> {}

export const QUESTION_ENGAGEMENT_CREATE_ALLOWED_KEYS = [
  'user_id',
  'question_id',
  'category_id',
  'language',
  'pre_grading_llm_chat_id',
  'post_grading_llm_chat_id',
] satisfies Array<keyof TableInsertModel<'question_engagements'>>;

export type QuestionEngagementCreateModel = Pick<
  TableInsertModel<'question_engagements'>,
  (typeof QUESTION_ENGAGEMENT_CREATE_ALLOWED_KEYS)[number]
>;

export const QUESTION_ENGAGEMENT_UPDATE_ALLOWED_KEYS = [...QUESTION_ENGAGEMENT_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'question_engagements'>
>;

export type QuestionEngagementUpdateModel = Pick<
  TableUpdateModel<'question_engagements'>,
  (typeof QUESTION_ENGAGEMENT_UPDATE_ALLOWED_KEYS)[number]
>;
