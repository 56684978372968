@if (!hideCalculatorHeader()) {
  <div class="highlight-container">
    <div class="font-primary text-black-pure text-md flex items-center font-semibold">
      @if (question()?.calculator_allowed) {
        <div
          class="mr-2 flex h-[28px] w-[28px] items-center justify-center"
          [matTooltip]="'examdojo.question.calculator_allowed' | transloco"
        >
          <img src="assets/images/calculator/calculator-allowed.svg" />
        </div>
        You can solve this question with a calculator
      } @else {
        <div
          class="mr-2 flex h-[28px] w-[28px] items-center justify-center"
          [matTooltip]="'calculator_not_allowed' | transloco"
        >
          <img src="assets/images/calculator/calculator-not-allowed.svg" />
        </div>
        You cannot solve the question with a calculator. Please report the question is it is impossible to solve with a
        calculator
        <dojo-icon
          class="ml-2"
          size="16"
          [matTooltip]="
            'Some questions in IBDP are required to be solved without a calculator. Please check if this question can be calculatod without a calculator'
          "
          [icon]="'info-circle'"
        />
      }
    </div>
  </div>
}

<div class="small:items-center small:flex-row flex flex-col flex-wrap justify-between gap-4 px-3 pb-6 pt-6">
  <div class="font-primary text-black-pure text-lg">[Maximum mark: {{ totalMarks() }}]</div>
</div>

<div class="stem-wrap font-primary text-black-pure flex flex-col text-lg">
  @for (item of questionItems(); track $index) {
    @if (item.type === 'stimulus') {
      <div class="stimulus px-3 py-4">
        @if (item.stimulus | localize | async; as content) {
          @if (editable()) {
            <div #matMenuTriggerForMenu="matMenuTrigger" [matMenuTriggerFor]="menu">
              <dojo-markdown-viewer [content]="content" />
            </div>
            <mat-menu class="wide-panel" #menu="matMenu">
              <ng-template matMenuContent>
                <div
                  class="w-[800px]"
                  (click)="$event.stopPropagation()"
                  cdkTrapFocus
                  (keydown.tab)="$event.stopPropagation()"
                  (keydown.shift.tab)="$event.stopPropagation()"
                >
                  <dojo-stimulus-editor (finishedSaving)="matMenuTriggerForMenu.closeMenu()" [id]="item.id" />
                </div>
              </ng-template>
            </mat-menu>
          } @else {
            <dojo-markdown-viewer [content]="content" />
          }
        }
      </div>
    }

    @if (item.type === 'question_image' && item.questionImageUrl) {
      <div class="question-image-container">
        @if (editable()) {
          <div #matMenuTriggerForMenu="matMenuTrigger" [matMenuTriggerFor]="stemMenu">
            <ion-img [alt]="item.description" [src]="item.questionImageUrl" class="question-image" />
          </div>
          <mat-menu class="wide-panel" #stemMenu="matMenu">
            <ng-template matMenuContent>
              <div
                class="w-[800px]"
                (click)="$event.stopPropagation()"
                cdkTrapFocus
                (keydown.tab)="$event.stopPropagation()"
                (keydown.shift.tab)="$event.stopPropagation()"
              >
                <dojo-question-image-editor (finishedSaving)="matMenuTriggerForMenu.closeMenu()" [id]="item.id" />
              </div>
            </ng-template>
          </mat-menu>
        } @else {
          <div class="group relative" dojoImagePreview [src]="item.questionImageUrl">
            <div
              class="absolute right-2 top-2 hidden rounded-[0.5rem] bg-neutral-100 p-2 text-neutral-700 group-hover:flex"
            >
              <dojo-icon
                [icon]="'arrow-up-right-and-arrow-down-left-from-center'"
                [matTooltip]="'enlarge' | transloco"
              />
            </div>
            <ion-img [alt]="item.description" [src]="item.questionImageUrl" class="question-image" />
          </div>
        }
      </div>
    }

    @if (item.type === 'stem') {
      <div class="stem group relative flex flex-col rounded-[1rem] px-3 py-4" role="button" tabindex="0">
        <div class="stem-inner small:flex-row small:gap-6 relative flex flex-col justify-between gap-3 pl-10">
          <div class="stem-order-tag absolute left-0 top-0">{{ item.label | lowercase }})</div>
          <ol class="substem-wrap small:self-center flex min-h-[30px] flex-col gap-2">
            @for (subStem of item.stem; track $index) {
              <li class="substeam">
                <div class="substem-inner">
                  @if (subStem | localize | async; as content) {
                    @if (editable()) {
                      <div #matMenuTriggerForMenu="matMenuTrigger" [matMenuTriggerFor]="stemMenu">
                        <dojo-markdown-viewer [content]="content" />
                      </div>
                      <mat-menu class="wide-panel" #stemMenu="matMenu">
                        <ng-template matMenuContent>
                          <div
                            class="w-[800px]"
                            (click)="$event.stopPropagation()"
                            cdkTrapFocus
                            (keydown.tab)="$event.stopPropagation()"
                            (keydown.shift.tab)="$event.stopPropagation()"
                          >
                            <dojo-stem-editor
                              (finishedSaving)="matMenuTriggerForMenu.closeMenu()"
                              [showMarkscheme]="false"
                              [id]="item.id"
                            />
                          </div>
                        </ng-template>
                      </mat-menu>
                    } @else {
                      <dojo-markdown-viewer [content]="content" />
                    }
                  }
                </div>
              </li>
            }
          </ol>
          <div class="flex min-h-[30px] items-center justify-between gap-4 self-start">
            <div class="small:group-focus-within:flex text-md flex min-w-[80px] justify-end gap-2 font-normal">
              <span>[{{ item.totalMarks }} mark{{ item.totalMarks === 1 ? '' : 's' }}]</span>
            </div>
          </div>
        </div>
      </div>
    }
  }
</div>
