import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mapToVoid } from '@examdojo/core/rxjs';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { QuestionAttemptHttpModel, QuestionQuery } from '@examdojo/question';
import { RealtimeChangesListenEvent, RealtimeService } from '@examdojo/supabase';
import { concatMap, distinctUntilChanged, from, map, merge, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { QuestionAttemptResponseImageHttpService } from './question-attempt-response-image-http.service';
import {
  ImageLocation,
  LocalAttemptResponseImageStoreModel,
  QuestionAttemptResponseImageCreateModel,
  QuestionAttemptResponseImageHttpModel,
  QuestionAttemptResponseImageStoreModel,
  RemoteAttemptResponseImageStoreModel,
} from './question-attempt-response-image.model';
import { QuestionAttemptResponseImageQuery } from './question-attempt-response-images.query';
import { QuestionAttemptResponseImageStore } from './question-attempt-response-images.store';

@Injectable({ providedIn: 'root' })
export class QuestionAttemptResponseImageService {
  constructor(
    private readonly realtimeService: RealtimeService,
    private readonly store: QuestionAttemptResponseImageStore,
    private readonly query: QuestionAttemptResponseImageQuery,
    private readonly httpService: QuestionAttemptResponseImageHttpService,
    private readonly questionQuery: QuestionQuery,
  ) {
    this.listenForResponseImageUpdates().pipe(takeUntilDestroyed()).subscribe();
  }

  private readonly resetListener$ = new Subject<null>();

  resetListener() {
    this.resetListener$.next(null);
    this.store.reset();
  }

  // TODO: listen for question store instead of passing questionAttemptId
  fetchQuestionAttemptResponseImages(attemptId: QuestionAttemptHttpModel['id']) {
    return this.httpService.fetchQuestionAttemptResponseImages(attemptId).pipe(
      switchMap((questionAttemptResponseImages) =>
        this.mapQuestionAttemptResponseImageHttpModelToStoreModel(questionAttemptResponseImages),
      ),
      tap((questionAttemptResponseImages) => this.store.set(questionAttemptResponseImages)),
    );
  }

  listenForResponseImageUpdates() {
    return merge(
      this.questionQuery.attempt$.pipe(
        map((attempt) => attempt?.id),
        distinctUntilChanged(),
      ),
      this.resetListener$,
    ).pipe(
      distinctUntilChanged(),
      switchMap((questionAttemptId) => {
        if (!questionAttemptId) {
          return of([]);
        }

        return merge(
          this.realtimeService
            .listenBroadcastRealtime<QuestionAttemptResponseImageHttpModel>(
              [{ event: RealtimeChangesListenEvent.DELETE }],
              'question_attempt_response_images_delete',
            )
            .pipe(
              tap((payload) => {
                if (
                  payload.event === RealtimeChangesListenEvent.DELETE &&
                  payload?.payload?.old_record?.id &&
                  this.store.hasEntity(payload.payload.old_record.id)
                ) {
                  this.store.delete(payload.payload.old_record.id);
                }
              }),
            ),
          this.realtimeService
            .listenBroadcastRealtime<QuestionAttemptResponseImageHttpModel>(
              [{ event: RealtimeChangesListenEvent.INSERT }],
              `question_attempt_response_images:${questionAttemptId}`,
            )
            .pipe(
              concatMap((payload) => {
                if (
                  payload.event === RealtimeChangesListenEvent.INSERT &&
                  payload?.payload?.record?.id &&
                  !this.store.hasEntity(payload.payload.record.id)
                ) {
                  return this.mapQuestionAttemptResponseImageHttpModelToStoreModel([payload.payload.record]).pipe(
                    tap((questionAttemptResponseImages) => this.store.upsertMany(questionAttemptResponseImages)),
                  );
                }
                return of();
              }),
            ),
        );
      }),
    );
  }

  addNewLocalQuestionAttemptResponseImages(questionAttemptResponseStoreModels: LocalAttemptResponseImageStoreModel[]) {
    this.store.upsertMany(questionAttemptResponseStoreModels);
  }

  replaceLocalQuestionAttemptResponseImage(questionAttemptResponseStoreModel: QuestionAttemptResponseImageHttpModel) {
    const oldEntity = this.query.getEntityByPredicate(
      (item) => questionAttemptResponseStoreModel.image_name === item.image_name,
    );

    if (!oldEntity) {
      return;
    }

    this.store.update(oldEntity.id, (item) => ({
      ...questionAttemptResponseStoreModel,
      url: item.url, // do not replate url, to avoid flicking (optimistic UI)
      location: ImageLocation.Remote,
    }));

    this.store.updateEntitiesIds(oldEntity.id, questionAttemptResponseStoreModel.id);
  }

  createQuestionAttemptResponseImage(
    data: QuestionAttemptResponseImageCreateModel,
  ): Observable<QuestionAttemptResponseImageHttpModel> {
    return this.httpService.create(data);
  }

  removeQuestionAttemptResponseImage(entityId: QuestionAttemptResponseImageStoreModel['id']) {
    const entity = this.query.getEntity(entityId);

    if (!entity) {
      return of();
    }

    return this.httpService.delete(entity.id).pipe(
      tap(() => this.store.delete(entity.id)),
      switchMap(() => {
        assertNonNullable(entity.bucket_name, 'bucket_name');
        assertNonNullable(entity.image_name, 'image_name');
        return this.httpService.deleteQuestionAttemptResponseImageFile(entity.bucket_name, entity.image_name);
      }),
      mapToVoid(),
    );
  }

  private mapQuestionAttemptResponseImageHttpModelToStoreModel(
    questionAttemptResponseImages: QuestionAttemptResponseImageHttpModel[],
  ): Observable<RemoteAttemptResponseImageStoreModel[]> {
    const questionAttemptResponseImagesWithNames = questionAttemptResponseImages.filter((item) => !!item.image_name);

    const questionAttemptResponseImageBucketName = questionAttemptResponseImagesWithNames[0]?.bucket_name; // all questionAttempt response image items have the same bucket

    if (!questionAttemptResponseImagesWithNames.length || !questionAttemptResponseImageBucketName) {
      return of(
        questionAttemptResponseImages.map(
          (item): RemoteAttemptResponseImageStoreModel => ({
            ...item,
            url: null,
            location: ImageLocation.Remote,
            updated_at: item.updated_at,
          }),
        ),
      );
    }

    const questionAttemptResponseImageNames = questionAttemptResponseImagesWithNames.map((item) => item.image_name);

    return from(
      this.fetchSignedUrlsForImage(questionAttemptResponseImageNames, questionAttemptResponseImageBucketName),
    ).pipe(
      map((signedUrls) =>
        questionAttemptResponseImagesWithNames.map(
          (item: QuestionAttemptResponseImageHttpModel): RemoteAttemptResponseImageStoreModel => ({
            ...item,
            url: item.image_name ? signedUrls[item.image_name] : null,
            location: ImageLocation.Remote,
            updated_at: item.updated_at,
          }),
        ),
      ),
    );
  }

  private async fetchSignedUrlsForImage(imageNames: Array<string | null>, bucketName: string) {
    const data = await this.httpService.fetchSignedUrls(imageNames.filter(isNotNullish), bucketName);

    return data.reduce<Record<string, string | null>>((acc, item) => {
      return {
        ...acc,
        ...(item.path ? { [item.path]: item.error ? null : item.signedUrl } : {}),
      };
    }, {});
  }
}
