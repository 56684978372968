import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { mapToVoid } from '@examdojo/core/rxjs';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { QuestionHttpModel, QuestionStoreModel } from '@examdojo/models/question';
import { PracticeActivityStrategy, QuestionAttemptHttpService } from '@examdojo/question';
import { UserQuery } from '@examdojo/user';
import { BehaviorSubject, catchError, EMPTY, map, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { PracticeActivityHttpService } from './practice-activity-http.service';
import { PracticeActivityStatusHttpService } from './practice-activity-status-http.service';
import {
  PracticeActivityCreationResult,
  PracticeActivityHttpModel,
  PracticeActivityQuestionCandidatesHttpModel,
  PracticeActivityQuestionCandidatesStoreModel,
} from './practice-activity.model';
import { PracticeActivityStore } from './practice-activity.store';

@Injectable({ providedIn: 'root' })
export class PracticeActivityService {
  constructor(
    private readonly http: HttpClient,
    private readonly practiceActivityHttpService: PracticeActivityHttpService,
    private readonly practiceActivityStatusHttpService: PracticeActivityStatusHttpService,
    private readonly errorHandler: ErrorHandlerService,
    private readonly store: PracticeActivityStore,
    private readonly userService: UserQuery,
    private readonly questionAttemptHttpService: QuestionAttemptHttpService,
  ) {}

  private readonly basePath = `/practice-activities`;
  private readonly baseUrl = `${environment.examdojo.examdojoApiUrl}${this.basePath}`;
  private readonly testBaseUrl = `${environment.examdojo.examdojoApiUrl}/test${this.basePath}`;

  // TODO -> it should be handled only by this service.
  readonly creatingPracticeActivity$$ = new BehaviorSubject<boolean>(false);
  readonly creatingPracticeActivity$ = this.creatingPracticeActivity$$.asObservable();

  resetStore() {
    this.store.reset();
  }

  fetchAll() {
    return this.practiceActivityHttpService.fetchAll().pipe(
      tap((activities) => {
        this.store.set(activities);
      }),
      this.errorHandler.setHttpErrorMetadata({
        entity: 'examdojo.entity.practice_activity',
      }),
    );
  }

  fetch(id: string): Observable<void> {
    return this.practiceActivityHttpService.fetch(id).pipe(
      tap((activity) => {
        this.store.upsert(activity.id, activity);
      }),
      this.errorHandler.setHttpErrorMetadata({
        entity: 'examdojo.entity.practice_activity',
      }),
      mapToVoid(),
    );
  }

  create(options: { topics_level_02: number[]; strategy: PracticeActivityStrategy }): Observable<void> {
    return this.http
      .post<{
        result: PracticeActivityCreationResult;
      }>(this.baseUrl, options)
      .pipe(
        switchMap(({ result }) => {
          if (result.status !== 'success') {
            this.errorHandler.error('Failed to create practice activity');
            return EMPTY;
          }

          assertNonNullable(result.id, 'id');
          return this.fetch(result.id);
        }),
        this.errorHandler.setHttpErrorMetadata({
          entity: 'examdojo.entity.practice_activity',
        }),
      );
  }

  testCreate(questionCandidateIds: Array<QuestionStoreModel['id']>): Observable<void> {
    return this.http
      .post<{
        result: PracticeActivityCreationResult;
      }>(this.testBaseUrl, {}, { params: { candidates: questionCandidateIds } })
      .pipe(
        switchMap(({ result }) => {
          if (result.status !== 'success') {
            this.errorHandler.error('Failed to create practice activity');
            return EMPTY;
          }

          assertNonNullable(result.id, 'id');
          return this.fetch(result.id);
        }),
        this.errorHandler.setHttpErrorMetadata({
          entity: 'examdojo.entity.practice_activity',
        }),
      );
  }

  updateStatus(practiceActivityId: string, status: PracticeActivityHttpModel['status']): Observable<void> {
    return this.practiceActivityStatusHttpService
      .create({
        practice_activity_id: practiceActivityId,
        status,
      })
      .pipe(
        // Refetch the practice activity to update the status (and potentially other fields like question_attempt_id).
        switchMap(() => {
          if (['COMPLETED', 'DISCARDED', 'FAILED'].includes(status)) {
            this.store.update(practiceActivityId, { status });
            return of(undefined);
          }

          return this.fetch(practiceActivityId);
        }),
        this.errorHandler.setHttpErrorMetadata({
          entity: 'examdojo.entity.practice_activity',
          action: 'update',
        }),
        mapToVoid(),
      );
  }

  setPracticeActivityQuestion(questionId: QuestionHttpModel['id'], practiceActivityId: string) {
    const userId = this.userService.getActiveId();
    assertNonNullable(userId, 'userId');

    return this.questionAttemptHttpService
      .create({
        question_id: questionId,
        user_id: userId,
      })
      .pipe(
        switchMap((_questionAttempt) => {
          return this.updateStatus(practiceActivityId, 'IN_PROGRESS');
        }),
        // TODO: a custom error title would be nice.
        this.errorHandler.setHttpErrorMetadata({
          entity: 'examdojo.entity.question',
        }),
        catchError((error: unknown) => {
          this.resetStore();
          return throwError(() => error);
        }),
      );
  }

  getPracticeActivityQuestionCandidates(practiceActivityId: string) {
    return this.practiceActivityHttpService.getPracticeActivityQuestionCandidates(practiceActivityId).pipe(
      map((response) =>
        response.map((candidate) => this.mapQuestionCandidateToPracticeActivityQuestionCandidatesStoreModel(candidate)),
      ),
      this.errorHandler.setHttpErrorMetadata({
        entity: 'examdojo.entity.practice_activity_candidates',
      }),
    );
  }

  getQuestionAttempt(questionAttemptId: string) {
    return this.questionAttemptHttpService.fetch(questionAttemptId).pipe(
      this.errorHandler.setHttpErrorMetadata({
        entity: 'examdojo.entity.question_attempt',
      }),
    );
  }

  // TODO: provide proper status after discussion with BE
  completePracticeActivityWithStatus(
    practiceActivityId: string,
    status: PracticeActivityHttpModel['status'] = 'COMPLETED',
  ) {
    return this.updateStatus(practiceActivityId, status).pipe(
      this.errorHandler.setHttpErrorMetadata({
        entity: 'examdojo.entity.practice_activity',
        action: 'update',
      }),
      catchError((error: unknown) => {
        this.resetStore();
        return throwError(() => error);
      }),
    );
  }

  private mapQuestionCandidateToPracticeActivityQuestionCandidatesStoreModel(
    questionCandidate: PracticeActivityQuestionCandidatesHttpModel,
  ): PracticeActivityQuestionCandidatesStoreModel {
    return {
      ...questionCandidate,
      metadata: questionCandidate.metadata as unknown as PracticeActivityQuestionCandidatesStoreModel['metadata'],
    };
  }
}
