import { EntityState } from '@examdojo/state';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';

export enum ImageLocation {
  Local = 'local', // Adding image will temporary add image with Local location to the store, it will be changed to Remote after uploading
  Remote = 'remote',
}

interface ImageBase<T extends ImageLocation> {
  location: T;
  url: string | null;
}

export const SOLUTION_IMAGE_STORAGE_BUCKET_NAME = 'student_solutions';

export type LocalAttemptResponseImageStoreModel = ImageBase<ImageLocation.Local> &
  Pick<QuestionAttemptResponseImageCreateModel, 'image_name' | 'bucket_name' | 'order'> & { id: string };

export type RemoteAttemptResponseImageStoreModel = ImageBase<ImageLocation.Remote> &
  QuestionAttemptResponseImageHttpModel;

export type QuestionAttemptResponseImageStoreModel =
  | LocalAttemptResponseImageStoreModel
  | RemoteAttemptResponseImageStoreModel;
export type QuestionAttemptResponseImageUIModel = QuestionAttemptResponseImageStoreModel;

export interface QuestionAttemptResponseImageState extends EntityState<QuestionAttemptResponseImageStoreModel, {}> {}

export function mapPhotoToLocalQuestionAttemptResponseImageStoreModel({
  url,
  imageName,
  bucketName,
  id,
  order,
}: {
  url: string;
  imageName: string;
  bucketName: string;
  id: string;
  order: number;
}): LocalAttemptResponseImageStoreModel {
  return {
    location: ImageLocation.Local,
    url,
    image_name: imageName,
    bucket_name: bucketName,
    id,
    order,
  };
}

export type QuestionAttemptResponseImageHttpModel = Pick<
  TableRowModel<'question_attempt_response_images', 'learning'>,
  | 'bucket_name'
  | 'created_at'
  | 'updated_at'
  | 'id'
  | 'image_name'
  | 'order'
  | 'question_attempt_grading_id'
  | 'question_attempt_id'
>;

export const QUESTION_ATTEMPT_RESPONSE_IMAGE_CREATE_ALLOWED_KEYS = [
  'question_attempt_id',
  'image_name',
  'bucket_name',
  'order',
] as const satisfies Array<keyof TableInsertModel<'question_attempt_response_images', 'learning'>>;

export type QuestionAttemptResponseImageCreateModel = Pick<
  TableInsertModel<'question_attempt_response_images', 'learning'>,
  (typeof QUESTION_ATTEMPT_RESPONSE_IMAGE_CREATE_ALLOWED_KEYS)[number]
>;

export const QUESTION_ATTEMPT_RESPONSE_IMAGE_UPDATE_ALLOWED_KEYS = [
  ...QUESTION_ATTEMPT_RESPONSE_IMAGE_CREATE_ALLOWED_KEYS,
] satisfies Array<keyof TableUpdateModel<'question_attempt_response_images', 'learning'>>;

export type QuestionAttemptResponseImageUpdateModel = Pick<
  TableUpdateModel<'question_attempt_response_images', 'learning'>,
  (typeof QUESTION_ATTEMPT_RESPONSE_IMAGE_UPDATE_ALLOWED_KEYS)[number]
>;
