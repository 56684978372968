import { Injectable } from '@angular/core';
import { QueryEntity } from '@examdojo/state';
import {
  QuestionAttemptResponseImageState,
  QuestionAttemptResponseImageStoreModel,
  QuestionAttemptResponseImageUIModel,
} from './question-attempt-response-image.model';
import { QuestionAttemptResponseImageStore } from './question-attempt-response-images.store';

@Injectable({ providedIn: 'root' })
export class QuestionAttemptResponseImageQuery extends QueryEntity<
  QuestionAttemptResponseImageState,
  QuestionAttemptResponseImageStoreModel,
  QuestionAttemptResponseImageUIModel,
  'id'
> {
  constructor(questionAttemptResponseImageStore: QuestionAttemptResponseImageStore) {
    super(questionAttemptResponseImageStore);
  }
}
