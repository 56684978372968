import { Component, Input, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { keys } from '@examdojo/core/util/object-utils';
import { TextareaInputComponent } from '@examdojo/ui/input';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { tap } from 'rxjs';
import { PROBLEMS, PROBLEMS_FOR_STUDENTS, QuestionFeedback } from './question-feedback.model';

export function atLeastOneOptionSelectedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormGroup) {
      const controls = Object.values(control.controls);
      const isAtLeastOneSelected = controls.some((ctrl) => ctrl.value === true);
      return isAtLeastOneSelected ? null : { atLeastOneOptionRequired: true };
    }
    return null;
  };
}

@Component({
  selector: 'dojo-question-feedback-content',
  template: `
    <form [formGroup]="form.controls.feedbackType" class="w-full">
      <div class="custom-mat-form-wrapper mb-6">
        <div class="flex flex-col">
          @if (forStudents) {
            <div class="mat-form-label">{{ 'reason' | transloco }}</div>
            @for (option of studentOptions; track option.value) {
              <mat-checkbox color="primary" class="mb-1 mt-1" [formControlName]="option.value">
                <span>{{ option.label }}</span>
              </mat-checkbox>
            }
          } @else {
            <div class="mat-form-label">Question issues</div>
            @for (option of reviewerQuestionOptions; track option.value) {
              <mat-checkbox color="primary" class="mb-1 mt-1" [formControlName]="option.value">
                <div class="font-normal">{{ option.label }}</div>
                <!-- <div>{{ option.description }}</div> -->
              </mat-checkbox>
            }
            <div class="mat-form-label mt-6">Markscheme issues</div>
            @for (option of reviewerMarkschemeOptions; track option.value) {
              <mat-checkbox color="primary" class="mb-1 mt-1" [formControlName]="option.value">
                <div class="font-normal">{{ option.label }}</div>
                <!-- <div>{{ option.description }}</div> -->
              </mat-checkbox>
            }
          }
        </div>
      </div>

      <dojo-textarea-input
        [label]="'additional_details' | transloco"
        [placeholder]="'optional_text_feedback' | transloco"
        [resizable]="true"
        [formCtrl]="form.controls.textFeedback"
        class="feedback-field !flex h-[116px]"
      />
    </form>
  `,
  styles: [
    `
      ::ng-deep .feedback-field {
        .mat-mdc-form-field,
        .mat-mdc-form-field-flex,
        .mat-mdc-form-field-infix,
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
          height: 100% !important;
        }

        .custom-mat-form-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }

      ::ng-deep ion-modal#user-feedback-text-dialog {
        @media only screen and (min-width: 768px) {
          --width: 480px;
        }
      }
    `,
  ],
  imports: [TranslocoPipe, TextareaInputComponent, ReactiveFormsModule, MatCheckbox],
})
export class QuestionFeedbackContentComponent {
  constructor(private readonly translocoService: TranslocoService) {
    this.form.valueChanges
      .pipe(
        tap(() => this.handleValueChange()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }
  @Input() forStudents = true;
  @Input() calculatorAllowed = false;

  readonly studentOptions: Array<{ label: string; description: string; value: string }> = PROBLEMS_FOR_STUDENTS.map(
    (option) => ({
      label: this.translocoService.translate(`problems.${option.id}`),
      description: this.translocoService.translate(`problems.${option.description}`),
      value: option.id,
    }),
  );

  readonly reviewerQuestionOptions: Array<{ label: string; description: string; value: string }> = PROBLEMS.filter(
    (option) =>
      option.id.startsWith('question_') &&
      (this.calculatorAllowed || option.id !== 'question_unsolveable_without_calculator'),
  ).map((option) => ({
    label: this.translocoService.translate(`problems.${option.id}`),
    description: this.translocoService.translate(`problems.${option.description}`),
    value: option.id,
  }));

  readonly reviewerMarkschemeOptions: Array<{ label: string; description: string; value: string }> = PROBLEMS.filter(
    (option) => option.id.startsWith('markscheme_'),
  ).map((option) => ({
    label: this.translocoService.translate(`problems.${option.id}`),
    description: this.translocoService.translate(`problems.${option.description}`),
    value: option.id,
  }));

  readonly form = new FormGroup({
    textFeedback: new FormControl<string>('', {
      nonNullable: true,
    }),
    feedbackType: new FormGroup(
      {
        ...PROBLEMS_FOR_STUDENTS.reduce(
          (acc, option) => ({
            ...acc,
            [option.id]: new FormControl<boolean>(false),
          }),
          {},
        ),
        ...PROBLEMS.reduce(
          (acc, option) => ({
            ...acc,
            [option.id]: new FormControl<boolean>(false),
          }),
          {},
        ),
      },
      {
        validators: [atLeastOneOptionSelectedValidator()],
      },
    ),
  });

  readonly changed = output<QuestionFeedback | null>();

  clearForm() {
    this.form.reset();
  }

  handleValueChange() {
    if (this.form.invalid) {
      return;
    }

    const value = this.getValue();

    this.changed.emit(value);
  }

  getValue() {
    if (this.form.invalid) {
      return null;
    }

    const value = this.form.getRawValue();

    const textFeedback = value.textFeedback?.trim() ?? '';
    const feedbackType = (value.feedbackType ?? {}) as Record<string, boolean>;
    const problems = keys(feedbackType).filter((key) => feedbackType[key]);

    return { textFeedback, problems };
  }
}
