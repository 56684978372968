import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnimationDuration, getAnimationAppearDisappear } from '@examdojo/animation';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { LoaderComponent } from '@examdojo/ui/loader';
import { QrCodeComponent } from '@examdojo/ui/qr-code';
import { IonHeader, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { SolutionLinkService } from './solution-link.service';

@Component({
  selector: 'dojo-solution-link-dialog',
  template: `
    <ion-header [translucent]="true">
      <ion-toolbar class="toolbar-with-closing-button !px-2 md:!px-4">
        <div slot="start" class="hidden md:flex">
          <dojo-icon-button (click)="closeDialog()" [icon]="'times'" />
        </div>
        <ion-title class="common-ion-title"> {{ 'examdojo.question.phone_capture_title' | transloco }} </ion-title>
        <div slot="end" class="hidden md:flex md:w-8"></div>
      </ion-toolbar>
    </ion-header>

    <div class="flex h-full flex-col items-center gap-4 p-4 text-center">
      @if (isLinkLoading()) {
        <dojo-loader [centered]="true" class="h-100" />
      }

      @if (solutionLink(); as link) {
        <div class="font-normal-exam px-4 text-xs text-stone-800">
          Scan this QR Code to directly take pictures on your phone for grading
        </div>

        <dojo-qr-code @appearDisappear [content]="link" />
      }
    </div>
  `,
  animations: [getAnimationAppearDisappear({ enter: AnimationDuration.Short })],
  styleUrls: ['./solution-link-dialog.component.scss'],
  imports: [QrCodeComponent, IonHeader, IonToolbar, IconButtonComponent, LoaderComponent, IonTitle, TranslocoPipe],
  providers: [SolutionLinkService],
})
export class SolutionLinkDialogComponent {
  constructor(
    private readonly solutionLinkService: SolutionLinkService,
    private readonly modalController: ModalController,
  ) {
    this.solutionLinkService.fetchSolutionLink().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly solutionLink = this.solutionLinkService.solutionLink;
  readonly isLinkLoading = this.solutionLinkService.isLoading;

  closeDialog() {
    this.modalController.dismiss();
  }
}
