import { DestroyRef, Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { BehaviorSubject, EMPTY, filter, finalize, from, switchMap } from 'rxjs';
import { QuestionQuery } from '../question-v2.query';
import { QuestionService } from '../question-v2.service';
import { UserFeedbackTextDialogService } from './user-feedback-text-dialog.component';

@Directive({
  selector: '[dojoQuestionFlagFeedback]',
  providers: [UserFeedbackTextDialogService],
  standalone: true,
  host: {
    '(click)': 'state.questionFlagged ? removeQuestionFeedbackStatus() : setQuestionFlagFeedback(false)',
    'class.flagged': 'state.questionFlagged',
  },
})
export class QuestionFlagFeedbackDirective {
  constructor(
    private readonly questionQuery: QuestionQuery,
    private readonly questionService: QuestionService,
    private readonly destroyRef: DestroyRef,
    private readonly userFeedbackTextDialogService: UserFeedbackTextDialogService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  private readonly isLoading$$ = new BehaviorSubject<boolean>(false);

  readonly state = connectState({
    questionFlagged: this.questionQuery.questionFlagged$,
    isLoading: this.isLoading$$,
  });

  removeQuestionFeedbackStatus() {
    const userVoteId = this.questionQuery.getValue().userVote?.id;

    if (!userVoteId) {
      return;
    }

    this.isLoading$$.next(true);

    this.questionService
      .removeQuestionVote(userVoteId)
      .pipe(
        this.errorHandlerService.catchHttpErrors(() => EMPTY),
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isLoading$$.next(false);
        }),
      )
      .subscribe();
  }

  setQuestionFlagFeedback(vote: false) {
    const questionId = this.questionQuery.getValue().question?.id;

    if (!questionId) {
      return;
    }

    this.isLoading$$.next(true);

    from(this.userFeedbackTextDialogService.openDialog())
      .pipe(
        filter((feedback) => isNotNullish(feedback)),
        switchMap((feedback) => this.questionService.setQuestionVote(questionId, { vote, ...feedback })),
        this.errorHandlerService.catchHttpErrors(() => EMPTY),
        takeUntilDestroyed(this.destroyRef),
        finalize(() => {
          this.isLoading$$.next(false);
        }),
      )
      .subscribe();
  }
}
