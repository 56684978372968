<div class="info-box-wrapper h-full" [ngClass]="{ classes, 'items-start': loading, 'items-center': !loading }">
  <div class="flex gap-2">
    @if (loading) {
      <mat-spinner [diameter]="16" class="secondary-spinner"></mat-spinner>
    } @else {
      <y42-info-box-severity-icon [severity]="severity" class="inline-flex" />
    }

    @if (state.messageTemplate) {
      <div class="content">
        <ng-container *ngTemplateOutlet="state.messageTemplate"></ng-container>
      </div>
    } @else if (message) {
      <div class="content">
        <span [innerHTML]="message"></span>
      </div>
    }
  </div>
  <ng-content></ng-content>
</div>
