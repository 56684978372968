@if (showHeader()) {
  <div class="rounded-medium flex flex-col gap-2 pb-4">
    <div class="header px-3 pt-3">
      <div class="flex justify-between">
        <div class="flex items-center gap-2">
          <h2 class="text-title-m font-medium">Question</h2>

          @if (id()) {
            <div>#{{ id() ?? '' }}</div>
          }

          @if (state.question?.updated_at; as updatedAt) {
            <div class="text-typography-secondary whitespace-nowrap text-xs" [matTooltip]="updatedAt | formatDate">
              (updated {{ updatedAt | formatDateRelativeToNow }}),
            </div>
          }

          <div class="text-typography-secondary whitespace-nowrap text-xs">
            created by: {{ state.question?.author?.fullName ? state.question?.author?.fullName : '/' }}
          </div>
        </div>

        <div class="align-center flex items-center gap-2">
          <exam-save-indicator [isSaving]="state.isSaving" />

          @if (id()) {
            <div class="flex items-center gap-2">
              <dojo-classic-button (click)="copyQuestion()">
                <div class="flex items-center gap-1">
                  <span>Copy</span>
                  <dojo-icon icon="clipboard" size="large" />
                </div>
              </dojo-classic-button>
              <dojo-classic-button (click)="previewQuestion()">
                <div class="flex items-center gap-1">
                  <span>Preview Question + Markscheme</span>
                </div>
              </dojo-classic-button>
            </div>

            <div class="flex items-center gap-2">
              <dojo-classic-button [color]="'warn'" (click)="deleteQuestion()"> Delete </dojo-classic-button>
            </div>
          }
        </div>
      </div>
    </div>

    <div class="max-w-[620px]">
      <div class="medium:grid-cols-3 grid items-center gap-4 px-3">
        <dojo-single-select
          [label]="'Status'"
          [formCtrl]="questionMetadataForm.controls.status"
          [options]="questionStatusOptions"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [fullWidthField]="true"
        />
        <dojo-classic-button
          class="mt-2 w-fit"
          [pending]="state.isSaving"
          [disabled]="state.questionSubmitted"
          (click)="checkQuestionWithLlmFeedback()"
        >
          Submit question
        </dojo-classic-button>
      </div>
    </div>
  </div>
}

<div class="flex flex-col gap-2 text-black">
  @for (item of state.questionItems; track trackByQuestionItemFn(item)) {
    <div class="flex gap-1">
      @switch (item.item_type) {
        @case ('stem') {
          <dojo-stem-editor
            class="border-t border-gray-300"
            [id]="item.item_id"
            class="flex-1"
            [llmFeedback]="state.stemLlmFeedback[item.item_id.toString()]"
          />
        }
        @case ('stimulus') {
          <dojo-stimulus-editor class="flex-1 border-t border-gray-300" [id]="item.item_id" />
        }
        @case ('question_image') {
          <dojo-question-image-editor [id]="item.item_id" class="flex-1 border-t border-gray-300" />
        }
      }
    </div>
  }
</div>
