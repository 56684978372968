import { ChecklistItemId, SectionId } from '../settings/ibdp_math';

export interface Condition {
  id: ConditionId;
  expression: string;
}

export type ConditionReference = ConditionId;

export interface FeedbackResponse {
  vote: boolean;
  problems: Feedback[];
}

export interface Feedback {
  section_id: SectionId;
  checklist: FeedbackItem[];
}

export interface FeedbackItem {
  id: ChecklistItemId;
  value: boolean | string;
  extra_text_field?: string;
  additional_checklist?: FeedbackItem[];
}

export enum ChecklistItemType {
  YesNo = 'yes/no',
}

export enum ConditionId {
  AlwaysVisible = 'always_visible',
  AnyResponse = 'any_response',
  NoResponse = 'no_response',
  ParentAnsweredNo = 'parent_answered_no',
  HasQuestionImage = 'has_question_image',
  HasMarkschemeImage = 'has_markscheme_image',
  YesResponse = 'yes_response',
  HasCalculator = 'has_calculator',
  HasNoCalculator = 'has_no_calculator',
}

export const CONDITIONS = [
  {
    id: ConditionId.AlwaysVisible,
    expression: 'true',
  },
  {
    id: ConditionId.AnyResponse,
    expression: '!!value',
  },
  {
    id: ConditionId.NoResponse,
    expression: 'value === false',
  },
  {
    id: ConditionId.YesResponse,
    expression: 'value === true',
  },
  {
    id: ConditionId.ParentAnsweredNo,
    expression: 'parentValue === false',
  },
  {
    id: ConditionId.HasQuestionImage,
    expression: 'containsQuestionImage === true',
  },
  {
    id: ConditionId.HasMarkschemeImage,
    expression: 'containsMarkschemeImage === true',
  },
  {
    id: ConditionId.HasCalculator,
    expression: 'calculatorAllowed === true',
  },
  {
    id: ConditionId.HasNoCalculator,
    expression: 'calculatorAllowed === false',
  },
];
