import { Component, DestroyRef, Directive, output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { combineLatest, Observable, tap } from 'rxjs';

@Directive()
export class _ImageDropzoneComponent<T = File | File[]> {
  constructor(private readonly destroyRef: DestroyRef) {}

  readonly multiple: boolean = false;
  readonly imageSelected = output<T | null>();

  dropped(event: NgxFileDropEntry[]): void {
    if (!this.multiple) {
      const droppedFileEntry = event[0];

      if (droppedFileEntry && droppedFileEntry.fileEntry.isFile) {
        const fileEntry = droppedFileEntry.fileEntry as FileSystemFileEntry;

        this.fileToObservable(fileEntry)
          .pipe(
            tap((file) => {
              this.imageSelected.emit(file as T);
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe();
      }
    } else {
      combineLatest(
        event
          .filter((entry) => entry && entry.fileEntry.isFile)
          .map((entry) => this.fileToObservable(entry.fileEntry as FileSystemFileEntry)),
      )
        .pipe(
          tap((files) => {
            this.imageSelected.emit(files as T);
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    }
  }

  private fileToObservable(fileEntry: FileSystemFileEntry): Observable<File> {
    return new Observable<File>((observer) => {
      fileEntry.file(
        (file) => {
          observer.next(file);
          observer.complete();
        },
        (error) => {
          observer.error(error);
        },
      );
    });
  }
}

@Component({
  selector: 'dojo-image-dropzone',
  templateUrl: './image-dropzone.component.html',
  styleUrl: 'image-dropzone.component.scss',
  imports: [NgxFileDropModule],
})
export class ImageDropzoneComponent extends _ImageDropzoneComponent<File> {}

@Component({
  selector: 'dojo-multiple-image-dropzone',
  templateUrl: './image-dropzone.component.html',
  styleUrl: 'image-dropzone.component.scss',
  imports: [NgxFileDropModule],
})
export class MultipleImageDropzoneComponent extends _ImageDropzoneComponent<File[]> {
  override readonly multiple = true;
}
