import { Component, input } from '@angular/core';
import { NgxQrcodeStylingComponent } from 'ngx-qrcode-styling';

@Component({
  selector: 'dojo-qr-code',
  template: `
    <ngx-qrcode-styling
      [data]="content()"
      [image]="centerImg"
      [width]="200"
      [height]="200"
      [imageOptions]="{
        margin: 1,
        imageSize: 0.8
      }"
      [qrOptions]="{
        errorCorrectionLevel: 'M'
      }"
      [dotsOptions]="{
        color: '#0D95EA'
      }"
      [backgroundOptions]="{
        color: '#ffffff'
      }"
      [cornersDotOptions]="{
        color: '#0D95EA',
        type: 'dot'
      }"
      [cornersSquareOptions]="{
        type: 'extra-rounded'
      }"
    />
  `,
  imports: [NgxQrcodeStylingComponent],
})
export class QrCodeComponent {
  readonly content = input.required<string>();

  readonly centerImg = 'assets/images/logo/logo-compact.svg';
}
