// ref: libs/markscheme/src/lib/models/markscheme.model.ts
export enum MarkschemeImagePath {
  Stem = 'stem',
  SubStem = 'sub_stems',
  Method = 'methods',
  MarkGroup = 'mark_groups',
  MarkGroupAlternative = 'mark_group_alternatives',
  Mark = 'marks',
}
export type MarkschemeNodePath = Partial<Record<MarkschemeImagePath, number>>;
