import { Injectable } from '@angular/core';
import { AbstractDialogService, DialogOptions } from '@examdojo/core/dialog';
import { LocalizedString } from '@examdojo/core/i18n';
import { MarkschemeImage } from '@examdojo/models/markscheme';
import { StemMarkschemeImageDialogComponent } from './stem-markscheme-image-dialog.component';

@Injectable()
export class StemMarkschemeImageDialogService extends AbstractDialogService<
  LocalizedString,
  MarkschemeImage['image_name']
> {
  readonly id = 'stem-markscheme-image-dialog';
  protected readonly component = StemMarkschemeImageDialogComponent;

  override readonly options = {
    cssClass: 'modified-modal font-primary-exam action-sheet-on-mobile',
  };

  override openDialog({ input: description }: { input: LocalizedString; options?: DialogOptions }) {
    return super.openDialog({ options: { ...this.options, componentProps: { description } } });
  }
}
