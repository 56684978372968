import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';

@Component({
  selector: 'dojo-markscheme-note',
  imports: [MarkdownViewerComponent],
  template: `
    <div class="align-center flex justify-start gap-4 border p-2" style="border-color: #d2d2d2">
      <div class="text-md flex items-center font-semibold">Note:</div>
      <dojo-markdown-viewer [content]="note" />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkschemeNoteComponent {
  @Input({ required: true }) note!: string;
}
