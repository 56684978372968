import { TopicLevel2UIModel } from '@examdojo/category/v2';
import { isNotNullish } from '@examdojo/core/util/nullish';
import {
  QuestionImageStoreModel,
  QuestionStoreModel,
  QuestionVoteStoreModel,
  StemDifficultyCategory,
  StemStoreModel,
  StimulusStoreModel,
  StimulusUIModel,
} from '@examdojo/models/question';
import { UserXpStoreModel } from '@examdojo/models/question/user-xp.model';
import { TableRowModel } from '@examdojo/supabase';
import { QuestionAttemptStatusStoreModel, QuestionAttemptStoreModel } from './question-attempt.model';
import { GradedStem, GradedStemStoreModel, QuestionGradingResultStoreModel } from './solution';
import { SelfGradableSubStem } from './solution/self-gradable-result-model';

export type QuestionItemQuestionImageStoreModel = QuestionImageStoreModel & {
  questionImageUrl: string | null;
};

export type QuestionItemQuestionImageUIModel = QuestionItemQuestionImageStoreModel;

export type QuestionStemMarkschemeUIModel = Array<
  SelfGradableSubStem & { label?: string; topicLevel2Models: TopicLevel2UIModel[] }
>;

export type StemUIModel = Omit<StemStoreModel, 'markscheme'> & {
  label: string;
  gradingResult?: GradedStemStoreModel;
  markscheme: QuestionStemMarkschemeUIModel | null;
};

export type QuestionItem = StimulusStoreModel | StemStoreModel | QuestionItemQuestionImageStoreModel;

export interface QuestionState {
  question?: QuestionStoreModel;
  items?: QuestionItem[];
  attempt?: QuestionAttemptStoreModel;
  attemptStatuses?: QuestionAttemptStatusStoreModel[];
  gradings?: QuestionGradingResultStoreModel[];
  chat?: QuestionAttemptChatStoreModel;
  // TODO: remove.
  userXp?: UserXpStoreModel;
  userVote?: QuestionVoteStoreModel;
  grading?: {
    gradingId: number;
    userGradingVote?: boolean;
  };
  gradingError?: GradingError;
  openCaptureDialog?: boolean;
  hasNewGrading?: boolean;
  questionLoaded?: boolean;
}

export interface GradingError {
  error_code: 'rejected_images' | 'grading_error' | 'internal_server_error' | 'grading_limit_exceeded' | 'unknown';
  error_message: string;
}

export type QuestionContext = Required<Pick<QuestionState, 'question' | 'items'>>;

export enum GradeStatus {
  Positive = 'positive',
  Warning = 'warning',
  Fail = 'fail',
}

export type QuestionItemsUIModel = Array<StimulusUIModel | StemUIModel | QuestionItemQuestionImageUIModel>;

export type QuestionAttemptChatHttpModel = Pick<
  TableRowModel<'question_attempt_chats', 'learning'>,
  'question_attempt_id' | 'post_grading_llm_chat_id' | 'pre_grading_llm_chat_id'
>;

export type QuestionAttemptChatStoreModel = QuestionAttemptChatHttpModel;

export function calculateQuestionDifficultyFromQuestionItems(
  items: Array<StimulusStoreModel | StemStoreModel | QuestionItemQuestionImageStoreModel> | undefined,
): StemDifficultyCategory | null {
  if (!items) {
    return null;
  }

  const stems: StemStoreModel[] = items.filter(
    (item): item is StemStoreModel & { type: 'stem' } => item.type === 'stem',
  );

  if (!stems.length) {
    return null;
  }

  // TODO: temporary logic for calculating difficulty
  const totalMarks = stems.reduce((sum, item) => sum + item.totalMarks, 0);

  const difficulty = totalMarks <= 8 ? 'EASY' : totalMarks <= 16 ? 'MEDIUM' : 'HARD';

  return difficulty;
}

export function convertStemMarkschemeUIModelToGradedMarkscheme(stemUIModels: StemUIModel[]): GradedStem[] {
  return stemUIModels
    .map((stemUIModel) => {
      return stemUIModel.markscheme
        ? {
            stem_id: stemUIModel.id,
            stem_level_feedback: '',
            stem_letter: stemUIModel.label.toLowerCase(),
            sub_stems: stemUIModel.markscheme.map((subStem) => ({
              sub_stem_letter: subStem.label ?? '',
              sub_stem_level_feedback: '',
              methods: subStem.methods.map((method) => ({
                name: '',
                mark_groups: method.mark_groups.map((markGroup) => ({
                  mark_group_alternatives: markGroup.mark_group_alternatives.map((markGroupAlternative) => ({
                    name: '',
                    marks: markGroupAlternative.marks.map((mark) => ({
                      mark_level_feedback: '',
                      note: '',
                      points: mark.points.map((point) => ({
                        amount: point.amount,
                        type: point.type,
                        awarded_amount: point.formCtrl.value ? point.amount : 0,
                      })),
                    })),
                  })),
                })),
              })),
            })),
          }
        : null;
    })
    .filter(isNotNullish);
}
