import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SafeHtml } from '@angular/platform-browser';
import { connectState, RxInput, RxOnInit } from '@examdojo/angular/util';
import { AnimationDuration, getAnimationAppearDisappearWithHeight } from '@examdojo/animation';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';
import { InfoBoxSeverityIconComponent, Severity } from '../info-box-severity-icon';

type AnimationDurationValue = (typeof AnimationDuration)[keyof typeof AnimationDuration];

@RxOnInit()
@UntilDestroy()
@Component({
  selector: 'y42-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'rounded-md inline-flex w-full border border-solid' },
  imports: [NgTemplateOutlet, NgClass, MatProgressSpinnerModule, InfoBoxSeverityIconComponent],
  animations: [getAnimationAppearDisappearWithHeight()],
})
export class InfoBoxComponent {
  @Input()
  @RxInput()
  message: string | TemplateRef<unknown> | SafeHtml = '';
  private readonly message$!: Observable<string | TemplateRef<unknown>>;

  @Input() severity: Severity = 'error';
  @Input() loading = false;
  @Input() leaveAnimationDuration: AnimationDurationValue = AnimationDuration.Short;
  @Input() classes?: string;

  @HostBinding('@appearDisappearWithHeight') get animationParams() {
    return {
      value: ':leave',
      params: {
        leaveTimeParam: this.leaveAnimationDuration,
      },
    };
  }

  @HostBinding('@.disabled') @Input() animationDisabled?: boolean = false;

  @HostBinding('class') get typeClass() {
    return `severity-${this.severity}`;
  }

  readonly state = connectState({
    messageTemplate: this.message$.pipe(map((message) => (message instanceof TemplateRef ? message : null))),
  });
}
