import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';

export type QuestionEngagementResponseImageHttpModel = Pick<
  TableRowModel<'question_engagement_response_images'>,
  | 'bucket_name'
  | 'created_at'
  | 'id'
  | 'image_name'
  | 'order'
  | 'question_engagement_grading_id'
  | 'question_engagement_id'
>;

export const QUESTION_ENGAGEMENT_RESPONSE_IMAGE_CREATE_ALLOWED_KEYS = [
  'question_engagement_id',
  'image_name',
  'bucket_name',
  'order',
] as const satisfies Array<keyof TableInsertModel<'question_engagement_response_images'>>;

export type QuestionEngagementResponseImageCreateModel = Pick<
  TableInsertModel<'question_engagement_response_images'>,
  (typeof QUESTION_ENGAGEMENT_RESPONSE_IMAGE_CREATE_ALLOWED_KEYS)[number]
>;

export const QUESTION_ENGAGEMENT_RESPONSE_IMAGE_UPDATE_ALLOWED_KEYS = [
  ...QUESTION_ENGAGEMENT_RESPONSE_IMAGE_CREATE_ALLOWED_KEYS,
] satisfies Array<keyof TableUpdateModel<'question_engagement_response_images'>>;

export type QuestionEngagementResponseImageUpdateModel = Pick<
  TableUpdateModel<'question_engagement_response_images'>,
  (typeof QUESTION_ENGAGEMENT_RESPONSE_IMAGE_UPDATE_ALLOWED_KEYS)[number]
>;
