@if (mark.image) {
  <dojo-markscheme-image [image]="mark.image" />
}
<div class="flex items-end justify-between gap-2">
  <dojo-markdown-viewer *dojoIfLocalizedString="mark.text; let text" [content]="text" />

  <div class="text-md flex">
    @for (point of mark.points; track $index) {
      <dojo-markscheme-point class="ml-1" [point]="point" />
    }
  </div>
</div>

<dojo-markscheme-note *dojoIfLocalizedString="mark.note; let note" [note]="note" class="mt-4 block" />
