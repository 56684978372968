import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { mapToVoid } from '@examdojo/rxjs';
import { catchError, first, of } from 'rxjs';
import { PracticeActivityService } from './practice-activity.service';

export const practiceActivitiesResolver: ResolveFn<void> = () => {
  const activityService = inject(PracticeActivityService);
  const errorHandler = inject(ErrorHandlerService);

  return activityService.fetchAll().pipe(
    errorHandler.catchHttpErrors(() => of(null)),
    catchError((err: unknown) => {
      console.error(err);
      return of(null);
    }),
    first(),
    mapToVoid(),
  );
};
