import { ChangeDetectionStrategy, Component, computed, input, model } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControlsOf, markAllAs } from '@examdojo/angular/forms';
import { connectState } from '@examdojo/angular/util';
import { DateTimeModule } from '@examdojo/core/date-time';
import { IconComponent } from '@examdojo/core/icon';
import { IconButtonComponent } from '@examdojo/core/icon-button';
import { TextareaInputComponent } from '@examdojo/core/input';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { QuestionImageStoreModel } from '@examdojo/models/question';
import { shareOneReplay } from '@examdojo/rxjs';
import { ButtonComponent } from '@examdojo/ui/button';
import { ImageDropzoneComponent } from '@examdojo/ui/image-dropzone';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { isNotNullish } from '@examdojo/util/nullish';
import { catchError, combineLatest, filter, map, of, startWith, switchMap, tap } from 'rxjs';
import { CmsQuestionQuery } from '../../cms-question.query';
import { CmsQuestionService } from '../../cms-question.service';

type QuestionImageEditorForm = FormControlsOf<{
  description: string;
  question_image_name: QuestionImageStoreModel['question_image_name'];
}>;

@Component({
  selector: 'dojo-question-image-editor',
  templateUrl: './question-image-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DateTimeModule,
    ReactiveFormsModule,
    IconButtonComponent,
    MatTooltipModule,
    TextareaInputComponent,
    MarkdownViewerComponent,
    ImageDropzoneComponent,
    IconComponent,
    ButtonComponent,
  ],
})
export class QuestionImageEditorComponent {
  constructor(
    private readonly questionService: CmsQuestionService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly cmsQuestionQuery: CmsQuestionQuery,
  ) {
    this.questionImage$
      .pipe(
        filter(isNotNullish),
        tap((questionImage) => {
          if (this.form.pristine) {
            this.form.patchValue({
              description: questionImage.description?.['en'] ?? '',
              question_image_name: questionImage.question_image_name,
            });
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe();

    toObservable(this.selectedQuestionImage)
      .pipe(
        switchMap((questionImage) => {
          if (questionImage) {
            return this.uploadImage(questionImage);
          }

          return of(null);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly id = input.required<QuestionImageStoreModel['id']>();

  readonly questionImage$ = toObservable(this.id).pipe(
    switchMap((id) => this.questionService.fetchQuestionImage(id)),
    catchError(() => of(null)),
    shareOneReplay(),
  );

  readonly selectedQuestionImage = model<File | null>();

  readonly selectedQuestionImageBlobUrl = computed(() => {
    const selectedImage = this.selectedQuestionImage();
    return selectedImage ? URL.createObjectURL(selectedImage) : '';
  });

  readonly form = new FormGroup<QuestionImageEditorForm>({
    description: new FormControl('', { nonNullable: true }),
    question_image_name: new FormControl<QuestionImageStoreModel['question_image_name']>('', {}),
  });

  readonly questionImageName$ = this.form.controls.question_image_name.valueChanges.pipe(
    startWith(this.form.controls.question_image_name.value),
  );

  readonly state = connectState({
    questionImage: this.questionImage$,
    questionImageUrl: combineLatest([
      this.questionImageName$.pipe(
        switchMap((imageName) => (imageName ? this.questionService.getSignedUrlForImage(imageName) : of(null))),
      ),
      toObservable(this.selectedQuestionImageBlobUrl),
    ]).pipe(map(([imageUrl, selectedImageUrl]) => selectedImageUrl || imageUrl)),

    isSaving: toObservable(this.id).pipe(
      switchMap((id) => (id ? this.cmsQuestionQuery.selectIsSaving('savingQuestionImageMap', id) : of(false))),
    ),
  });

  save() {
    if (this.form.pristine) {
      return;
    }

    markAllAs('touched', this.form);

    if (!this.form.valid) {
      return;
    }

    const formValue = this.form.getRawValue();

    // Upsert instead of update to make suer that the question is updated
    // if it does not exist yet (missing / incorrectly uploaded before, etc)
    this.questionService
      .upsertQuestionImage(this.id(), {
        ...formValue,
        id: this.id(),
        description: { en: formValue.description },
      })
      .pipe(map(() => this.form.markAsPristine()))
      .subscribe();
  }

  deleteQuestionImage() {
    this.form.controls.question_image_name.markAsDirty();
    this.form.controls.question_image_name.setValue(null);
    this.selectedQuestionImage.set(null);
  }

  async uploadImage(image: File): Promise<string | null> {
    try {
      const path = await this.questionService.uploadImage(image);

      this.form.controls.question_image_name.markAsDirty();
      this.form.patchValue({ question_image_name: path });

      return path;
    } catch (err) {
      this.errorHandlerService.error('[ReferenceQuestionEditorComponent]: Failed to upload the image', {
        toast: 'Failed to upload the image',
        err,
      });
      return null;
    }
  }
}
