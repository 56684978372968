import { Component, DestroyRef, effect, input, Input, OnInit, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { ButtonComponent } from '@examdojo/ui/button';
import { tap } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import baseConfig from '../../../../../settings/ibdp-survey-config.json';
import { PracticeActivityStrategy, STATUS_STRATEGY_MAPPING } from '../question-validation.model';
import { ChecklistItem, ChecklistItemType, SurveyConfig } from '../settings/ibdp_math';
import { ConsensusChecklistItemComponent } from './consensus-checklist-item.component';
import { CONDITIONS, Feedback, FeedbackItem, FeedbackResponse } from './consensus-checklist.model';

// For a fully typesafe version we need to wait for https://github.com/microsoft/TypeScript/issues/32063
const surveyConfig = baseConfig as SurveyConfig;

function evaluateCondition(expression: string, context: Record<string, unknown>): boolean {
  try {
    return new Function(...Object.keys(context), `return ${expression};`)(...Object.values(context));
  } catch (error) {
    return false;
  }
}

@Component({
  selector: 'dojo-consensus-feedback-form',
  template: `
    @if (activeReviewMode() === 'prescreening') {
      <div class="text-md mb-4">
        <b>Instruction</b>: Your job as a pre-screener is to fix formatting and report any image issues found. If you
        see any formatting issues in the question or markscheme, fix them first and then you can mark that everything is
        formatted correctly. Only mark as "not formatted correctly" if you cannot fix the formatting on your own.
      </div>
    }
    <form [formGroup]="form" class="w-full">
      @for (section of surveyConfig.sections; track section.section_id) {
        <div class="section-container mb-6">
          <h3 class="section-title text-lg font-medium">{{ section.section_display_name }}</h3>

          @for (item of section.checklist; track item.id) {
            @if (isItemVisible(item)) {
              <div class="checklist-item">
                <dojo-consensus-checklist-item
                  [item]="item"
                  [formGroupName]="item.id"
                  [parentFormGroup]="form"
                  [contextData]="{
                    containsQuestionImage: this.containsQuestionImage,
                    containsMarkschemeImage: this.containsMarkschemeImage,
                    calculatorAllowed: this.calculatorAllowed,
                    parent: null
                  }"
                  [shouldShowExtraTextFieldFn]="shouldShowExtraTextField.bind(this)"
                  [isItemVisibleFn]="isItemVisible.bind(this)"
                  (showExplanation)="showExplanation.emit($event)"
                />
              </div>
            }
          }
        </div>
      }
    </form>
    <div class="sticky mt-4 bg-white" style="bottom: 1rem; z-index: 10">
      <dojo-button
        [customColor]="submitTextDisplay() === 'Approve question & markscheme' ? 'green' : 'red'"
        class="w-full"
        (click)="submitForm()"
        [disabled]="!form.valid"
      >
        {{ submitTextDisplay() }}
      </dojo-button>
    </div>
  `,
  styles: [
    `
      ::ng-deep .feedback-field {
        .mat-mdc-form-field,
        .mat-mdc-form-field-flex,
        .mat-mdc-form-field-infix,
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
          height: 100% !important;
        }

        .custom-mat-form-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }

      .section-title {
        margin-top: 60px;
        color: var(--primary-color);
        margin-bottom: 5px;

        &:first-of-type {
          margin-top: 20px;
        }
      }

      .checklist-item {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.02);
      }

      .nested-checklist {
        padding-left: 10px;
        border-left: 1px solid #d5d5d5;
      }

      .custom-toggle-group {
        display: flex;
        width: 100%;
      }
    `,
  ],
  imports: [
    ReactiveFormsModule,
    MatRadioModule,
    MatButtonToggleModule,
    ConsensusChecklistItemComponent,
    ButtonComponent,
  ],
})
export class ConsensusFeedbackFormComponent implements OnInit {
  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
  ) {
    effect(
      () => {
        // TODO: Find a better way to pass the value around and build the form and other pieces
        // based on it.
        this.buildForm();
        this.updateFormValidators();
        this.submitTextDisplay.set('Submit');
      },
      { allowSignalWrites: true },
    );
  }

  @Input() calculatorAllowed = false;
  @Input() containsQuestionImage = false;
  @Input() containsMarkschemeImage = false;

  readonly activeReviewMode = input(PracticeActivityStrategy.InternalReview);
  practiceActivityStrategy = PracticeActivityStrategy;

  statusStrategyMapping = STATUS_STRATEGY_MAPPING;

  readonly surveyConfig = surveyConfig;
  form!: FormGroup;

  readonly formSubmit = output<FeedbackResponse>();
  readonly ChecklistItemType = ChecklistItemType;
  readonly showExplanation = output<string>();

  submitTextDisplay = signal('Submit');

  ngOnInit() {
    this.buildForm();

    // Initial setup of validators
    this.updateFormValidators();
  }

  buildForm() {
    if (this.form) {
      this.form.reset();
    }

    const formControls: Record<string, FormGroup> = {};

    // Process each section and its checklist items
    for (const section of this.surveyConfig.sections) {
      for (const item of section.checklist) {
        const itemGroup = this.createItemFormGroup(item);
        formControls[item.id] = itemGroup;
      }
    }

    this.form = this.fb.group(formControls);
    this.form.valueChanges
      .pipe(
        tap(() => {
          this.updateFormValidators();
          this.updateTextDisplay();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  submitForm() {
    const value = this.getValue();
    if (value) {
      this.formSubmit.emit(value);
    }
  }

  createItemFormGroup(item: ChecklistItem): FormGroup {
    const controls: Record<string, FormControl> = {
      value: this.fb.control(item.type === ChecklistItemType.YesNo ? null : false, { nonNullable: true }),
    };

    return this.fb.group(controls);
  }

  updateFormValidators() {
    // Use a more targeted approach to only update necessary validators
    for (const section of this.surveyConfig.sections) {
      for (const item of section.checklist) {
        this.updateItemValidators(item);
      }
    }
  }

  updateTextDisplay() {
    if (this.form.invalid) {
      this.submitTextDisplay.set('Submit');
    } else {
      const value = this.getValue();
      if (value) {
        this.submitTextDisplay.set(value.vote ? 'Approve question & markscheme' : 'Submit as rejected');
      }
    }
  }

  updateItemValidators(item: ChecklistItem, parentValue?: unknown, parentGroup?: FormGroup) {
    const isVisible = this.isItemVisible(item, parentValue);
    let formGroup: FormGroup | null = null;

    if (parentGroup) {
      // Access nested form control through parent group
      formGroup = parentGroup.get(item.id) as FormGroup;
    } else {
      // Top level form control
      formGroup = this.form.get(item.id) as FormGroup;
    }

    if (!formGroup) {
      return;
    }

    const valueControl = formGroup.get('value');
    if (valueControl) {
      // Skip updating if validators haven't changed
      const hasRequiredValidator = valueControl.hasValidator(Validators.required);

      if (isVisible && item.type === ChecklistItemType.YesNo && !hasRequiredValidator) {
        valueControl.setValidators([Validators.required]);
        valueControl.updateValueAndValidity({ emitEvent: false }); // Prevent emitting events
      } else if ((!isVisible || item.type !== ChecklistItemType.YesNo) && hasRequiredValidator) {
        valueControl.clearValidators();
        valueControl.updateValueAndValidity({ emitEvent: false }); // Prevent emitting events
      }
    }

    // Handle comment field if it exists
    const commentControl = formGroup.get('comment');
    if (commentControl) {
      const shouldShowTextField =
        isVisible && this.shouldShowExtraTextField(item, parentGroup ? `${item.id}` : undefined);
      if (!shouldShowTextField) {
        commentControl.clearValidators();
        commentControl.updateValueAndValidity({ emitEvent: false }); // Prevent emitting events
      }
    }
  }

  isItemVisible(item: ChecklistItem, parentValue?: unknown): boolean {
    // First check if we're in pre-screening mode and this item shouldn't be shown in pre-screening

    const mode = this.activeReviewMode();
    if (
      mode !== PracticeActivityStrategy.InternalReview &&
      mode !== PracticeActivityStrategy.PreScreening &&
      mode !== PracticeActivityStrategy.ImageValidation
    ) {
      return false;
    }
    if (!item.in_statuses.includes(this.statusStrategyMapping[mode])) {
      return false;
    }

    if (!item.display) {
      return true;
    }

    const conditionId = item.display;
    const condition = CONDITIONS.find((c) => c.id === conditionId);

    if (!condition) {
      return true;
    }

    let context: Record<string, unknown> = {};

    if (parentValue !== undefined && parentValue !== null) {
      context = {
        containsQuestionImage: this.containsQuestionImage,
        containsMarkschemeImage: this.containsMarkschemeImage,
        calculatorAllowed: this.calculatorAllowed,
        parentValue: parentValue,
        value: this.getFormControl(item.id, 'value')?.value,
      };
    } else {
      context = {
        containsQuestionImage: this.containsQuestionImage,
        containsMarkschemeImage: this.containsMarkschemeImage,
        calculatorAllowed: this.calculatorAllowed,
        parentValue: null,
        value: this.getFormControl(item.id, 'value')?.value,
      };
    }

    return evaluateCondition(condition.expression, context);
  }

  shouldShowExtraTextField(item: ChecklistItem, formPath?: string): boolean {
    if (!item.show_extra_text_field) {
      return false;
    }

    const conditionId = item.show_extra_text_field;
    const condition = CONDITIONS.find((c) => c.id === conditionId);

    if (!condition) {
      return false;
    }

    let value;
    if (formPath) {
      // For nested items, use the provided path
      const control = this.form.get(`${formPath}.value`);
      value = control?.value;
    } else {
      // For top-level items
      value = this.getFormControl(item.id, 'value')?.value;
    }

    const context = { value };

    return evaluateCondition(condition.expression, context);
  }

  getFormControl(itemId: string, controlName = 'value'): FormControl {
    const group = this.form.get(itemId) as FormGroup;
    return group?.get(controlName) as FormControl;
  }

  clearForm() {
    this.form.reset();
    this.updateFormValidators();
  }

  getValue(): FeedbackResponse | null {
    if (this.form.invalid) {
      return null;
    }

    const formValue = this.form.getRawValue();

    // Create sections one by one with explicit typing
    const feedbackData: Feedback[] = [];
    let allItemsPassed = true; // Initialize as true, will be set to false if any item fails

    for (const section of this.surveyConfig.sections) {
      const feedbackItems: FeedbackItem[] = [];

      for (const item of section.checklist) {
        // Skip items that aren't visible
        if (!this.isItemVisible(item)) {
          continue;
        }

        const itemGroup = formValue[item.id];
        if (!itemGroup) {
          continue;
        }

        const itemValue = itemGroup.value;
        const itemPassed = this.checkPassCondition(item, itemValue);
        if (!itemPassed) {
          allItemsPassed = false;
        }

        const feedbackItem: FeedbackItem = {
          id: item.id,
          value: itemValue,
        };

        if (itemGroup.comment?.trim()) {
          feedbackItem.extra_text_field = itemGroup.comment.trim();
        }

        feedbackItems.push(feedbackItem);
      }

      feedbackData.push({
        section_id: section.section_id,
        checklist: feedbackItems,
      });
    }

    return {
      vote: allItemsPassed,
      problems: allItemsPassed ? [] : feedbackData,
    };
  }

  // Helper method to check if an item passes its condition
  private checkPassCondition(item: ChecklistItem, value: unknown): boolean {
    if (!item.pass_condition) {
      return true;
    }

    const conditionId = item.pass_condition;
    const condition = CONDITIONS.find((c) => c.id === conditionId);

    if (!condition) {
      return true;
    }

    const context = { value };
    return evaluateCondition(condition.expression, context);
  }
}
