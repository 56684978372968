import { LocalizedString } from '@examdojo/core/i18n';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { QuestionItemBase } from './question.model';

export type StimulusHttpModel = Pick<
  TableRowModel<'stimuli'>,
  'author' | 'created_at' | 'id' | 'question_id' | 'stimulus' | 'updated_at'
>;

export type StimulusStoreModel = Omit<StimulusHttpModel, 'author' | 'stimulus'> &
  QuestionItemBase<'stimulus'> & {
    authorId: StimulusHttpModel['author'];
    stimulus: LocalizedString;
  };

export type StimulusUIModel = StimulusStoreModel;

export const STIMULUS_CREATE_ALLOWED_KEYS = ['question_id', 'stimulus'] satisfies Array<
  keyof TableInsertModel<'stimuli'>
>;

export type StimulusCreateModel = Pick<TableInsertModel<'stimuli'>, (typeof STIMULUS_CREATE_ALLOWED_KEYS)[number]>;

export const STIMULUS_UPDATE_ALLOWED_KEYS = [...STIMULUS_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'stimuli'>
>;

export type StimulusUpdateModel = Pick<TableUpdateModel<'stimuli'>, (typeof STIMULUS_UPDATE_ALLOWED_KEYS)[number]>;
