import { Component, input } from '@angular/core';
import { MarkPoint } from '@examdojo/models/markscheme';

@Component({
  selector: 'dojo-markscheme-point',
  template: `
    @if (point().amount > 0) {
      <span>
        {{ point().implied ? '(' : '' }}{{ point().type[0].toUpperCase() }}{{ point().amount
        }}{{ point().implied ? ')' : '' }}
      </span>
    } @else {
      <!-- AG Answer given in the question and so no marks are awarded. -->
      <span> AG </span>
    }
  `,
  standalone: true,
})
export class MarkschemePointComponent {
  readonly point = input.required<MarkPoint>();
}
