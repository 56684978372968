import { Mark, MarkGroup, Markscheme, Stem, SubStem } from './markscheme.model';

export function calculateStemTotalMarks(stemSubStems: Stem['sub_stems']): number {
  let totalMarks = 0;

  stemSubStems?.forEach((subStem: SubStem) => {
    const method = subStem.methods[0];
    if (!method) {
      return;
    }

    method.mark_groups.forEach((markGroup: MarkGroup) => {
      const markGroupAlternative = markGroup.mark_group_alternatives[0];
      if (!markGroupAlternative) {
        return;
      }

      markGroupAlternative.marks.forEach((mark: Mark) => {
        totalMarks += mark.points.reduce((sum, point) => sum + point.amount, 0);
      });
    });
  });

  return totalMarks;
}

export function calculateTotalMarksPerStem(markScheme: Markscheme): number[] {
  return markScheme.map((stem) => calculateStemTotalMarks(stem.sub_stems));
}

export function calculateTotalMarks(markScheme: Markscheme): number {
  const totalPerStem = calculateTotalMarksPerStem(markScheme);

  return totalPerStem.reduce((sum, mark) => sum + mark, 0);
}
