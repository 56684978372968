import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BillingErrorHandlingService } from '@examdojo/billing';
import { LocalizedString } from '@examdojo/core/i18n';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { calculateStemTotalMarks, SubStem } from '@examdojo/models/markscheme';
import {
  QuestionGradingPreset,
  QuestionHttpModel,
  QuestionImageHttpModel,
  QuestionStoreModel,
  QuestionVoteStoreModel,
  StemHttpModel,
  StemStoreModel,
  StimulusHttpModel,
  StimulusStoreModel,
} from '@examdojo/models/question';
import { mapToVoid } from '@examdojo/rxjs';
import { isNotNullish } from '@examdojo/util/nullish';
import omit from 'lodash/omit';
import { forkJoin, from, map, Observable, of, Subject, switchMap, tap, throwError } from 'rxjs';
import { QuestionAttemptGradingHttpService } from './question-attempt-grading-http.service';
import { QuestionAttemptHttpService } from './question-attempt-http.service';
import { QuestionAttemptStoreModel } from './question-attempt.model';
import { Feedback } from './question-user-feedback';
import { QuestionHttpService } from './question-v2-http.service';
import { GradingError, QuestionContext, QuestionItemQuestionImageStoreModel, QuestionState } from './question-v2.model';
import { QuestionStore } from './question-v2.store';
import {
  GradedStem,
  mapGradingResultHttpModelToStoreModel,
  QuestionAttemptResponseImageService,
  QuestionGradingResultHttpModel,
  QuestionGradingResultStoreModel,
} from './solution';

@Injectable({ providedIn: 'root' })
export class QuestionService {
  constructor(
    private readonly questionHttpService: QuestionHttpService,
    private readonly questionAttemptHttpService: QuestionAttemptHttpService,
    private readonly store: QuestionStore,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly questionAttemptResponseImageService: QuestionAttemptResponseImageService,
    private readonly questionAttemptGradingHttpService: QuestionAttemptGradingHttpService,
    private readonly billingErrorHandlingService: BillingErrorHandlingService,
  ) {}

  private readonly attemptGraded$$ = new Subject<void>();
  readonly attemptGraded$ = this.attemptGraded$$.asObservable();

  fetchQuestionContext(questionAttemptId: QuestionAttemptStoreModel['id']): Observable<QuestionContext> {
    // Reset the store.
    this.resetQuestionContext();

    return this.fetchQuestionAttempt(questionAttemptId).pipe(
      switchMap((attempt) => {
        return forkJoin([
          this.fetchQuestion(attempt.question_id),
          this.fetchQuestionItems(attempt.question_id),
          this.fetchQuestionAttemptStatuses(attempt.id),
          this.questionAttemptResponseImageService.fetchQuestionAttemptResponseImages(attempt.id),
          this.fetchAttemptGradings(attempt.id),
          this.fetchAttemptChat(attempt.id),
          // this.fetchQuestionVote(attempt.question_id),
          // this.fetchQuestionGradingVote(engagement.id),
        ]).pipe(
          map(([question, items]) => ({ question, items })),
          tap(({ question }) => this.store.updateStore((state) => ({ ...state, question, questionLoaded: true }))),
        );
      }),
    );
  }

  resetQuestionContext() {
    this.store.reset();
  }

  fetchQuestionAttempt(id: QuestionAttemptStoreModel['id']) {
    return this.questionAttemptHttpService.fetch(id).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_attempt' }),
      tap((attempt) => this.setLocalAttempt(attempt)),
    );
  }

  fetchQuestionAttemptStatuses(id: QuestionAttemptStoreModel['id']) {
    return this.questionAttemptHttpService.fetchStatuses(id).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_attempt' }),
      tap((statuses) => this.store.updateStore((state) => ({ ...state, attemptStatuses: statuses }))),
    );
  }

  fetchAttemptGradings(attemptId: QuestionAttemptStoreModel['id']) {
    return this.questionAttemptGradingHttpService.fetchAttemptGradings(attemptId).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_details' }),
      map((gradings) => gradings.map((grading) => this.mapAttemptGradingHttpModelToStoreModel(grading))),
      tap((gradings) => this.store.updateStore((state) => ({ ...state, gradings, hasNewGrading: true }))),
    );
  }

  fetchAttemptChat(attemptId: QuestionAttemptStoreModel['id']) {
    return this.questionAttemptHttpService.fetchChats(attemptId).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_attempt' }),
      map((chats) => chats[0]),
      tap((chat) => this.store.updateStore((state) => ({ ...state, chat }))),
    );
  }

  fetchQuestion(id: QuestionStoreModel['id']) {
    return this.questionHttpService.fetchQuestion(id).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question' }),
      map((question) => this.mapQuestionHttpModelToStoreModel(question)),
    );
  }

  fetchQuestionItems(questionId: QuestionStoreModel['id'], updateStore = true) {
    return this.questionHttpService.fetchItems(questionId).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_details' }),
      map(
        (items): NonNullable<QuestionState['items']> =>
          items.map((item) => {
            if (item.item_type === 'stimulus') {
              return this.mapStimulusHttpModelToStoreModel({ ...omit(item, ['item_id']), id: item.item_id });
            } else if (item.item_type === 'stem') {
              return this.mapStemHttpModelToStoreModel({ ...omit(item, ['item_id']), id: item.item_id });
            } else {
              return this.mapQuestionImageHttpModelToStoreModel({ ...omit(item, ['item_id']), id: item.item_id });
            }
          }),
      ),
      switchMap((items) => {
        const questionImageItems = items.filter(
          (item): item is QuestionItemQuestionImageStoreModel => item.type === 'question_image',
        );

        const questionImageItemsWithNames = questionImageItems.filter((item) => !!item.question_image_name);

        const questionImageItemBucketName = questionImageItemsWithNames[0]?.question_image_bucket; // all question image items have the same bucket

        if (!questionImageItemsWithNames.length || !questionImageItemBucketName) {
          return of(items);
        }

        const questionImageNames = questionImageItemsWithNames.map((item) => item.question_image_name);

        return from(this.fetchSignedUrlsForImage(questionImageNames, questionImageItemBucketName)).pipe(
          map((signedUrls) =>
            items.map((item) => {
              if (item.type === 'question_image') {
                item.questionImageUrl = item.question_image_name ? signedUrls[item.question_image_name] : null;
              }

              return item;
            }),
          ),
        );
      }),
      tap((items) => {
        if (updateStore) {
          this.store.updateStore((state) => ({ ...state, items }));
        }
      }),
    );
  }

  fetchQuestionGradingVote(attemptId: QuestionAttemptStoreModel['id']) {
    return this.questionHttpService.fetchQuestionGradingVote(attemptId).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_grading' }),

      tap((gradingVoteResult) => {
        this.store.updateStore((state) => ({
          ...state,
          grading: gradingVoteResult
            ? {
                userGradingVote:
                  gradingVoteResult.feedback_score === null || gradingVoteResult.feedback_score === undefined
                    ? undefined
                    : gradingVoteResult.feedback_score === 1,
                gradingId: gradingVoteResult.grading_id,
              }
            : undefined,
        }));
      }),
    );
  }

  fetchQuestionVote(questionId: QuestionStoreModel['id']) {
    return this.questionHttpService.fetchQuestionVote(questionId).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_vote' }),
      tap((questionVote) => this.store.updateStore((state) => ({ ...state, userVote: questionVote ?? undefined }))),
    );
  }

  setQuestionVote(
    questionId: QuestionStoreModel['id'],
    options: { vote: boolean; problems?: Feedback[] | string[]; textFeedback?: string },
  ) {
    return this.questionHttpService.setQuestionVote(questionId, options).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_vote', action: 'update' }),
      switchMap(() => this.fetchQuestionVote(questionId)),
      tap((questionVote) => this.store.updateStore((state) => ({ ...state, userVote: questionVote ?? undefined }))),
    );
  }

  removeQuestionVote(voteId: QuestionVoteStoreModel['id']) {
    return this.questionHttpService.removeQuestionVote(voteId).pipe(
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.question_vote', action: 'delete' }),
      tap(() => this.store.updateStore((state) => ({ ...state, userVote: undefined }))),
    );
  }

  setLocalAttemptStatus(
    attemptAttemptId: QuestionAttemptStoreModel['id'],
    status: QuestionAttemptStoreModel['status'],
  ) {
    this.store.updateStore((state) => ({
      ...state,
      attempt: state.attempt ? { ...state.attempt, status } : undefined,
    }));
  }

  setLocalAttempt(attempt: QuestionAttemptStoreModel) {
    this.store.updateStore((state) => ({
      ...state,
      attempt,
      gradingError:
        attempt.status === 'FAILED' ? (attempt.grading_result as unknown as GradingError) : state.gradingError,
    }));
  }

  submitForGrading(attemptId: QuestionAttemptStoreModel['id'], preset?: QuestionGradingPreset) {
    // TODO: Don't change the status when grading or change it only in the store to SUBMITTED before calling the endpoint.
    this.setLocalAttemptStatus(attemptId, 'SUBMITTED');

    return this.questionHttpService.triggerGrading(attemptId, preset).pipe(
      // timeout(60 * 1000), // Wait for 1 minute for the grading to finish.
      mapToVoid(),
      this.errorHandlerService.catchError(
        `[QuestionService.submitForGrading]: Failed to grade the question`,
        (err) => {
          this.billingErrorHandlingService.handleBillingLimitsExceededError(err);

          return this.fetchQuestionAttempt(attemptId).pipe(switchMap(() => throwError(() => err)));
        },
        {
          context: { attemptId },
        },
      ),
    );
  }

  submitForSelfGrading(attemptId: QuestionAttemptStoreModel['id'], gradingResult: GradedStem[]) {
    this.setLocalAttemptStatus(attemptId, 'SUBMITTED');

    return this.questionHttpService.triggerSelfGrading(attemptId, gradingResult).pipe(
      this.errorHandlerService.catchError(
        `[QuestionService.submitForSelfGrading]: Failed to self grade the question`,
        (err) => {
          this.billingErrorHandlingService.handleBillingLimitsExceededError(err);

          return this.fetchQuestionAttempt(attemptId).pipe(switchMap(() => throwError(() => err)));
        },
        {
          context: { attemptId },
        },
      ),
    );
  }

  synchronizePostGradingState(attempt: QuestionAttemptStoreModel) {
    this.store.updateStore((state) => ({
      ...state,
      hasNewGrading: false,
      gradingError: undefined,
    }));

    return forkJoin([
      this.fetchAttemptGradings(attempt.id),
      this.fetchQuestionAttemptStatuses(attempt.id),
      this.fetchAttemptChat(attempt.id),
      this.questionAttemptResponseImageService.fetchQuestionAttemptResponseImages(attempt.id),
    ]).pipe(
      tap(() => {
        this.attemptGraded$$.next();
        // Update the attempt status in the store after all the data has been fetched.
        this.setLocalAttempt(attempt);
      }),
    );
  }

  setOpenCaptureDialogFlag(open: boolean) {
    this.store.updateStore((state) => ({ ...state, openCaptureDialog: open }));
  }

  private getGradingError(error: unknown): GradingError {
    if (!(error instanceof HttpErrorResponse) || !error.error || typeof error.error !== 'object') {
      return { error_code: 'unknown', error_message: '' };
    }

    if (error.status === 500) {
      return { error_code: 'internal_server_error', error_message: '' };
    }

    return error.error as { error_code: 'rejected_images' | 'grading_error'; error_message: string };
  }

  private async fetchSignedUrlsForImage(imageNames: Array<string | null>, bucketName: string) {
    const data = await this.questionHttpService.fetchSignedUrls(imageNames.filter(isNotNullish), bucketName);

    return data.reduce<Record<string, string | null>>((acc, item) => {
      return {
        ...acc,
        ...(item.path ? { [item.path]: item.error ? null : item.signedUrl } : {}),
      };
    }, {});
  }

  private mapQuestionHttpModelToStoreModel(questionHttpModel: QuestionHttpModel): QuestionStoreModel {
    return {
      ...omit(questionHttpModel, ['author']),
      authorId: questionHttpModel.author,
      items: questionHttpModel.items as QuestionStoreModel['items'],
      markscheme_items: questionHttpModel.markscheme_items as QuestionStoreModel['markscheme_items'],
      llm_feedback: questionHttpModel.llm_feedback as unknown as QuestionStoreModel['llm_feedback'],
    };
  }

  private mapStimulusHttpModelToStoreModel(httpModel: StimulusHttpModel): StimulusStoreModel {
    return {
      ...omit(httpModel, ['author']),
      authorId: httpModel.author!,
      stimulus: httpModel.stimulus as LocalizedString,
      type: 'stimulus',
    };
  }

  private mapStemHttpModelToStoreModel(httpModel: StemHttpModel): StemStoreModel {
    return {
      ...omit(httpModel, ['author', 'markscheme']),
      authorId: httpModel.author!,
      stem: httpModel.stem as LocalizedString[],
      solution: httpModel.solution as LocalizedString,
      type: 'stem',
      markscheme: httpModel.markscheme as SubStem[] | null,
      totalMarks: httpModel.markscheme ? calculateStemTotalMarks(httpModel.markscheme as unknown as SubStem[]) : 0,
    };
  }

  private mapQuestionImageHttpModelToStoreModel(
    httpModel: QuestionImageHttpModel,
  ): QuestionItemQuestionImageStoreModel {
    return {
      ...omit(httpModel, ['author']),
      authorId: httpModel.author!,
      description: httpModel.description as LocalizedString,
      type: 'question_image',
      questionImageUrl: null,
    };
  }

  private mapAttemptGradingHttpModelToStoreModel(
    gradingResult: QuestionGradingResultHttpModel,
  ): QuestionGradingResultStoreModel {
    return {
      ...omit(gradingResult, ['grading_result']),
      grading_result: mapGradingResultHttpModelToStoreModel(gradingResult.grading_result),
    };
  }
}
