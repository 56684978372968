import { QuestionStatus } from '@examdojo/models/question';

export enum PracticeActivityStrategy {
  ScoreImpact = 'score_impact_with_difficulty_variance',
  WeakestLink = 'weakest_link',
  InternalReview = 'internal_review',
  PreScreening = 'prescreening',
  ImageValidation = 'needs_image',
}

type ReviewStrategies = Extract<
  PracticeActivityStrategy,
  | PracticeActivityStrategy.InternalReview
  | PracticeActivityStrategy.PreScreening
  | PracticeActivityStrategy.ImageValidation
>;

type StatusStrategyMappingType = Record<ReviewStrategies, QuestionStatus>;

export const STATUS_STRATEGY_MAPPING: StatusStrategyMappingType = {
  [PracticeActivityStrategy.InternalReview]: 'NEEDS_HUMAN_REVIEW',
  [PracticeActivityStrategy.PreScreening]: 'NEEDS_PRESCREENING',
  [PracticeActivityStrategy.ImageValidation]: 'NEEDS_IMAGE',
};
