import { Injectable } from '@angular/core';
import { AbstractDialogService } from '@examdojo/core/dialog';
import { SolutionLinkDialogComponent } from './solution-link-dialog.component';

@Injectable()
export class SolutionLinkDialogService extends AbstractDialogService<void, void> {
  readonly id = 'solution-link';
  protected readonly component = SolutionLinkDialogComponent;

  override readonly options = {
    cssClass: 'auto-height solution-link-dialog',
  };
}
