import { A11yModule } from '@angular/cdk/a11y';
import { AsyncPipe, LowerCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LocalizePipe } from '@examdojo/core/i18n';
import { IconComponent } from '@examdojo/core/icon';
import { QuestionItemsUIModel, QuestionStoreModel } from '@examdojo/models/question';
import { ImagePreviewDirective } from '@examdojo/ui/image-preview';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { IonImg } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { AngularSplitModule } from 'angular-split';
import { QuestionImageEditorComponent } from '../cms/editor/question-image-editor/question-image-editor.component';
import { StemEditorComponent } from '../cms/editor/stem-editor/stem-editor.component';
import { StimulusEditorComponent } from '../cms/editor/stimulus-editor/stimulus-editor.component';

@Component({
  selector: 'dojo-master-question-content',
  templateUrl: './master-question-content.component.html',
  styleUrl: './master-question-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AngularSplitModule,
    MatTooltipModule,
    MarkdownViewerComponent,
    IconComponent,
    IonImg,
    LocalizePipe,
    AsyncPipe,
    LowerCasePipe,
    TranslocoPipe,
    ImagePreviewDirective,
    MatMenuModule,
    StimulusEditorComponent,
    StemEditorComponent,
    QuestionImageEditorComponent,
    A11yModule,
  ],
})
export class MasterQuestionContentComponent {
  readonly question = input<QuestionStoreModel>();
  readonly questionItems = input<QuestionItemsUIModel>();
  readonly hideCalculatorHeader = input(false);
  readonly editable = input(false);
  readonly editPictureOnly = input(false);

  // TODO: calculate based on questionItems.
  readonly totalMarks = input<number>();
}
