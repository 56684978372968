import { Injectable } from '@angular/core';
import { AbstractDialogService, DialogOptions } from '@examdojo/core/dialog';
import { QuestionGradingPreset } from '@examdojo/models/question';
import { QuestionAttemptStoreModel } from '../../question-attempt.model';
import { SolutionCaptureDialogComponent } from './solution-capture-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SolutionCaptureDialogService extends AbstractDialogService<
  { userId: string },
  boolean | QuestionGradingPreset
> {
  readonly id = 'solution-capture';
  protected readonly component = SolutionCaptureDialogComponent;

  override openDialog({
    input,
  }: {
    input: { userId: string; attemptId: QuestionAttemptStoreModel['id'] };
    options?: DialogOptions;
  }) {
    return super.openDialog({
      options: {
        ...this.options,
        componentProps: {
          userId: input.userId,
          attemptId: input.attemptId,
        },
      },
    });
  }
}
