import { Component, Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AbstractDialogService } from '@examdojo/core/dialog';
import { ButtonComponent } from '@examdojo/ui/button';
import { IconButtonComponent } from '@examdojo/ui/icon-button';
import { IonHeader, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { QuestionFeedbackContentComponent } from './question-feedback-content.component';
import { QuestionFeedback } from './question-feedback.model';

const USER_FEEDBACK_DIALOG_ID = 'user-feedback-text-dialog';

export function atLeastOneOptionSelectedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormGroup) {
      const controls = Object.values(control.controls);
      const isAtLeastOneSelected = controls.some((ctrl) => ctrl.value === true);
      return isAtLeastOneSelected ? null : { atLeastOneOptionRequired: true };
    }
    return null;
  };
}

@Component({
  selector: 'dojo-user-feedback-text-dialog',
  template: `
    <ion-header [translucent]="true">
      <ion-toolbar class="toolbar-with-closing-button !px-2 md:!px-4">
        <div slot="start" class="hidden md:flex">
          <dojo-icon-button (click)="closeDialog()" [icon]="'times'" />
        </div>
        <div slot="end" class="hidden md:flex md:w-8"></div>
        <ion-title class="common-ion-title">
          {{ 'report_question' | transloco }}
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <div class="flex flex-col px-4 pb-4 pt-4 md:px-6 md:pb-6">
      <dojo-question-feedback-content #feedbackContent />

      <div class="small:flex-row small:justify-end mt-6 flex w-full flex-col gap-2">
        <dojo-button
          [disabled]="feedbackContent.form.invalid"
          class="block w-full"
          (click)="confirm(feedbackContent.getValue())"
        >
          {{ 'submit_feedback' | transloco }}
        </dojo-button>
      </div>
    </div>
  `,
  styles: [
    `
      ::ng-deep .feedback-field {
        .mat-mdc-form-field,
        .mat-mdc-form-field-flex,
        .mat-mdc-form-field-infix,
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
          height: 100% !important;
        }

        .custom-mat-form-wrapper {
          display: flex;
          flex-direction: column;
          flex: 1;
        }
      }

      ::ng-deep ion-modal#user-feedback-text-dialog {
        @media only screen and (min-width: 768px) {
          --width: 480px;
        }
      }
    `,
  ],
  imports: [
    TranslocoPipe,
    IonTitle,
    IonHeader,
    IonToolbar,
    IconButtonComponent,
    QuestionFeedbackContentComponent,
    ReactiveFormsModule,
    ButtonComponent,
  ],
})
export class UserFeedbackTextDialogComponent {
  constructor(private readonly modalCtrl: ModalController) {}

  closeDialog() {
    this.modalCtrl.dismiss(null, undefined, USER_FEEDBACK_DIALOG_ID);
  }

  confirm(feedback: QuestionFeedback | null) {
    this.modalCtrl.dismiss(feedback, undefined, USER_FEEDBACK_DIALOG_ID);
  }
}

@Injectable()
export class UserFeedbackTextDialogService extends AbstractDialogService<void, QuestionFeedback | null> {
  readonly id = USER_FEEDBACK_DIALOG_ID;
  protected override readonly component = UserFeedbackTextDialogComponent;
}
