import { Icon } from '@examdojo/core/icon';
import { Constants, Enum } from '@examdojo/supabase';

export type StemDifficultyCategory = Enum<'stem_difficulty_category'>;

export const STEM_DIFFICULTIES = Constants.public.Enums.stem_difficulty_category;

export const STEM_DIFFICULTY_CATEGORY_TO_LABEL_KEY: Record<StemDifficultyCategory, string> = {
  EASY: 'examdojo.difficulty.easy',
  MEDIUM: 'examdojo.difficulty.medium',
  HARD: 'examdojo.difficulty.hard',
};

export const STEM_DIFFICULTY_CATEGORY_TO_ICON: Record<StemDifficultyCategory | 'auto', Icon> = {
  EASY: 'assets/images/topic-practice/difficulty/easy.svg',
  MEDIUM: 'assets/images/topic-practice/difficulty/medium.svg',
  HARD: 'assets/images/topic-practice/difficulty/hard.svg',
  auto: 'sparkles',
};

export const STEM_DIFFICULTY_CATEGORY_TO_VALUE = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
} satisfies Record<StemDifficultyCategory, number>;

export const STEM_DIFFICULTY_CATEGORY_TO_CARD_ICON: Record<StemDifficultyCategory, Icon> = {
  EASY: 'assets/images/topic-practice/difficulty/easy-card.svg',
  MEDIUM: 'assets/images/topic-practice/difficulty/medium-card.svg',
  HARD: 'assets/images/topic-practice/difficulty/hard-card.svg',
};
