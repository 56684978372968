import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ElementRef, viewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { connectState } from '@examdojo/angular/util';
import { LocalStorageManager } from '@examdojo/browser/local-storage';
import { CopyToClipboardToastModule } from '@examdojo/core/copy-to-clipboard';
import { IconComponent } from '@examdojo/core/icon';
import { fromResize } from '@examdojo/rxjs';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { filter, map, skip, switchMap, tap } from 'rxjs';
import { DebuggingContextService } from '../debugging-context.service';

@Component({
  selector: 'dojo-debugging-window',
  imports: [NgxJsonViewerModule, CdkDrag, CdkDragHandle, CopyToClipboardToastModule, IconComponent, MatTooltip],
  templateUrl: './debugging-window.component.html',
  styleUrls: ['./debugging-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebuggingWindowComponent {
  constructor(private readonly debuggingContextService: DebuggingContextService) {
    toObservable(this.container)
      .pipe(
        filter(Boolean),
        switchMap((container) => fromResize(container.nativeElement, { debounceTime: 500 })),
        skip(1),
        tap(() => this.savePosition()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly container = viewChild<ElementRef<HTMLElement>>('container');

  readonly storageManager = new LocalStorageManager<{
    position: { top: number; left: number; width: number; height: number };
  }>('debugging-window');

  readonly initialPosition = this.storageManager.getValue()?.position;

  private readonly context$ = this.debuggingContextService.context$;

  readonly state = connectState({
    show: this.context$.pipe(map((context) => Object.keys(context).length > 0)),
    contextObject: this.debuggingContextService.context$,
    stringifiedContextObject: this.debuggingContextService.context$.pipe(
      map((context) => JSON.stringify(context, null, 2)),
    ),
  });

  savePosition() {
    const windowElement = this.container()?.nativeElement;
    if (!windowElement) {
      return;
    }

    const rect = windowElement.getBoundingClientRect();
    this.storageManager.setValue({
      position: {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      },
    });
  }
}
