import { Injectable } from '@angular/core';
import { QuestionImageStoreModel, StemStoreModel, StimulusStoreModel } from '@examdojo/models/question';
import { QuestionStoreModel } from '@examdojo/models/question/question.model';
import { EntityState, EntityStore } from '@examdojo/state';

export interface CmsQuestionState
  extends EntityState<
    QuestionStoreModel,
    {
      savingQuestionMap: Record<QuestionStoreModel['id'], boolean>;
      savingStemMap: Record<StemStoreModel['id'], boolean>;
      savingStimulusMap: Record<StimulusStoreModel['id'], boolean>;
      savingQuestionImageMap: Record<QuestionImageStoreModel['id'], boolean>;
    }
  > {}

export type QuestionStateSavingProp = keyof CmsQuestionState['props'];

@Injectable({ providedIn: 'root' })
export class CmsQuestionStore extends EntityStore<CmsQuestionState, QuestionStoreModel, 'id'> {
  constructor() {
    super({
      name: 'questions',
      idKey: 'id',
      initialPropsState: {
        savingQuestionMap: {},
        savingStemMap: {},
        savingStimulusMap: {},
        savingQuestionImageMap: {},
      },
    });
  }

  setSavingState(id: QuestionStoreModel['id'], propName: QuestionStateSavingProp, saving: boolean) {
    this.updateStore((state) => ({
      ...state,
      props: {
        ...state.props,
        [propName]: {
          ...state.props[propName],
          [id]: saving,
        },
      },
    }));
  }
}
