import { Injectable } from '@angular/core';
import { EntityStore } from '@examdojo/state';
import {
  QuestionAttemptResponseImageState,
  QuestionAttemptResponseImageStoreModel,
} from './question-attempt-response-image.model';

@Injectable({ providedIn: 'root' })
export class QuestionAttemptResponseImageStore extends EntityStore<
  QuestionAttemptResponseImageState,
  QuestionAttemptResponseImageStoreModel,
  'id'
> {
  constructor() {
    super({
      idKey: 'id',
      name: 'question-attempt-response-image',
      initialPropsState: {},
    });
  }
}
