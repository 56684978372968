@for (stem of markScheme(); track $index) {
  <div class="markscheme-stem flex gap-2" dojoNodePath [nodeKey]="MarkschemeImagePath.Stem" [nodeIndex]="$index">
    @if (stem.stem_letter) {
      <span class="stem-letter">({{ stem.stem_letter }})</span>
    }
    <div class="markscheme-stem__content flex grow flex-col gap-4">
      @for (subStem of stem.sub_stems; track $index) {
        <div
          class="markscheme-substem flex gap-2"
          dojoNodePath
          [nodeKey]="MarkschemeImagePath.SubStem"
          [nodeIndex]="$index"
        >
          @if (subStem.sub_stem_letter) {
            <span>({{ subStem.sub_stem_letter }})</span>
          }

          <div class="markscheme-substem__content flex grow flex-col gap-2">
            @for (method of subStem.methods; track $index) {
              <ng-container dojoNodePath [nodeKey]="MarkschemeImagePath.Method" [nodeIndex]="$index">
                @if (method.image) {
                  <dojo-markscheme-image [image]="method.image" [allowEdit]="allowImageDelete()" />
                }
                <div class="markscheme-method flex flex-col gap-1">
                  <h3 *dojoIfLocalizedString="method.name; let name" class="font-bold">
                    <dojo-markdown-viewer [content]="name"></dojo-markdown-viewer>
                  </h3>
                  @for (markGroup of method.mark_groups; track $index) {
                    <dojo-markscheme-markgroup
                      [markGroup]="markGroup"
                      dojoNodePath
                      [nodeKey]="MarkschemeImagePath.MarkGroup"
                      [nodeIndex]="$index"
                    />
                  }

                  <dojo-markscheme-note *dojoIfLocalizedString="method.note; let note" [note]="note" />
                </div>
              </ng-container>
            }

            <dojo-markscheme-note *dojoIfLocalizedString="subStem.note; let note" [note]="note" class="block" />
          </div>
        </div>
      }

      @if (stemMarksList().length > 1) {
        <div class="text-md flex justify-end gap-2 font-semibold">
          <span>[{{ stemMarksList()[$index] }} mark{{ stemMarksList()[$index] === 1 ? '' : 's' }}]</span>
        </div>
      }
      @if (stemHeaderTemplate(); as template) {
        <div class="text-md flex justify-end gap-2 font-semibold">
          <ng-container *ngTemplateOutlet="template; context: { $implicit: stem, index: $index }" />
        </div>
      }
    </div>
  </div>
}
@if (!hideTotalMarks()) {
  <div class="text-md flex justify-end font-semibold">
    <span>[Total: {{ totalMarks() }} marks]</span>
  </div>
}
