import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { LocalizedString } from '@examdojo/core/i18n';
import { MarkschemeImagePath, MarkschemeImageService, MarkschemeNodePath } from '@examdojo/markscheme';
import { Mark, MarkGroup, MarkGroupAlternative, MarkschemeImage, Method, SubStem } from '@examdojo/models/markscheme';
import { StemStoreModel } from '@examdojo/models/question';
import { MarkschemeImageHttpService } from '../../../markscheme-http.service';
import { StemMarkschemeImageDialogService } from './stem-markscheme-image-dialog.service';

function stemMarkschemeHasInvalidImageConfiguration(jsonString: string): boolean {
  try {
    const imageNameRegex = /"image_name"\s*:\s*null/;
    const bucketNameRegex = /"bucket_name"\s*:\s*null/;

    const imageNameNull = imageNameRegex.test(jsonString);
    const bucketNameNull = bucketNameRegex.test(jsonString);

    return imageNameNull || bucketNameNull;
  } catch (error) {
    return false;
  }
}

export function warningValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (stemMarkschemeHasInvalidImageConfiguration(value)) {
      return { markschemeWarning: 'Image should be provided' };
    }

    return null;
  };
}

@Injectable()
export class StemMarkschemeImageService extends MarkschemeImageService {
  constructor(
    private readonly markschemeImageHttpService: MarkschemeImageHttpService,
    private readonly stemMarkschemeImageDialogService: StemMarkschemeImageDialogService,
  ) {
    super();
  }

  readonly markschemeFormControl = new FormControl<string | null>(null, {
    nonNullable: true,
    validators: [warningValidator()],
  });

  setMarkscheme(markscheme: StemStoreModel['markscheme'] | null): void {
    this.markschemeFormControl.setValue(markscheme ? JSON.stringify(markscheme, null, 2) : null);
  }

  async openImageDialog(imageDescription: LocalizedString, path: MarkschemeNodePath) {
    const imagePath = await this.stemMarkschemeImageDialogService.openDialog({ input: imageDescription });

    if (imagePath) {
      const markscheme = this.markschemeFormControl.value ? JSON.parse(this.markschemeFormControl.value) : [];

      this.addImageToSpecificNode(markscheme, imagePath, this.markschemeImageHttpService.storage_bucket, path);

      this.setMarkscheme(markscheme);
      this.markschemeFormControl.markAsDirty();
    }
  }

  fetchSignedUrlForImage(imageId: string) {
    return this.markschemeImageHttpService.getSignedUrl(imageId);
  }

  uploadImage(image: File): Promise<string | null> {
    return this.markschemeImageHttpService.uploadImage(image);
  }

  async deleteImage(image: MarkschemeImage, path: MarkschemeNodePath) {
    if (!image.image_name) {
      return;
    }

    await this.markschemeImageHttpService.removeImage(image.image_name);

    const markscheme = this.markschemeFormControl.value ? JSON.parse(this.markschemeFormControl.value) : [];

    this.addImageToSpecificNode(markscheme, null, null, path);

    this.setMarkscheme(markscheme);
    this.markschemeFormControl.markAsDirty();
  }

  private addImageToSpecificNode(
    currentMarkscheme: StemStoreModel['markscheme'],
    image_name: string | null,
    bucket_name: string | null,
    path: MarkschemeNodePath,
  ): void {
    if (!currentMarkscheme) {
      return;
    }

    const subStemIndex = path[MarkschemeImagePath.SubStem] ?? 0;

    if (!currentMarkscheme[subStemIndex]) {
      return;
    }

    const method = this.findNodeByIndex<Method, SubStem>(
      currentMarkscheme[subStemIndex],
      MarkschemeImagePath.Method,
      path[MarkschemeImagePath.Method],
    );

    const markGroup = this.findNodeByIndex<MarkGroup, Method>(
      method,
      MarkschemeImagePath.MarkGroup,
      path[MarkschemeImagePath.MarkGroup],
    );

    const markGroupAlternative = this.findNodeByIndex<MarkGroupAlternative, MarkGroup>(
      markGroup,
      MarkschemeImagePath.MarkGroupAlternative,
      path[MarkschemeImagePath.MarkGroupAlternative],
    );
    const mark = this.findNodeByIndex<Mark, MarkGroupAlternative>(
      markGroupAlternative,
      MarkschemeImagePath.Mark,
      path[MarkschemeImagePath.Mark],
    );

    const lastFoundNodeWithImage = mark ?? markGroupAlternative ?? markGroup ?? method;

    if (!lastFoundNodeWithImage) {
      return;
    }

    if (!lastFoundNodeWithImage.image) {
      return;
    }

    lastFoundNodeWithImage.image = {
      ...(lastFoundNodeWithImage.image ?? {}),
      image_name,
      bucket_name,
    };
  }

  private findNodeByIndex<
    T extends Method | MarkGroupAlternative | MarkGroup | Mark,
    K extends SubStem | Method | MarkGroupAlternative | MarkGroup,
  >(node: K | null, arrayProp: keyof K, index?: number): T | null {
    if (index === undefined) {
      return node as T | null;
    }
    if (!node || !node[arrayProp] || !(node[arrayProp] as [])[index]) {
      return null;
    }

    return (node[arrayProp] as [])[index];
  }
}
