<div class="flex flex-col justify-between">
  <div class="font-medium-exam text-md mb-1 px-4 text-stone-800">
    {{ 'examdojo.question.let_ai_grade' | transloco }}
  </div>
  <div class="font-normal-exam px-4 text-xs text-stone-600">
    {{ 'examdojo.question.capture_answer_review' | transloco }}
  </div>
  <div class="thumbnail-row flex items-center gap-2 py-4">
    <dojo-swiper [options]="swiperOptions" class="w-full">
      <swiper-slide class="relative ml-4 h-[6rem] w-[6rem]">
        <div class="add-new">
          <dojo-button
            class="add-new-button block"
            [fill]="'outline'"
            (click)="addNewSolutionImage()"
            (contextmenu)="$event.preventDefault(); state.isTestingFlagEnabled ? addNewSolutionImage(true) : null"
            [customWidth]="96"
            [customHeight]="96"
          >
            <div class="flex flex-col text-stone-600">
              <img src="assets/images/camera-plus.svg" alt="Add" />
              <span class="font-medium-exam tent-xs">{{ 'examdojo.add' | transloco }}</span>
            </div>
          </dojo-button>
        </div>

        <input
          #fileInput
          type="file"
          capture="environment"
          accept="image/*"
          class="hidden"
          (change)="saveInputImage($event)"
        />
      </swiper-slide>

      @if (isDesktop && state.showQrCode) {
        <swiper-slide class="relative h-[6rem] w-[6rem]">
          <div class="add-new">
            <dojo-button
              class="add-new-button block"
              [fill]="'outline'"
              [customWidth]="96"
              [customHeight]="96"
              (click)="addNewSolutionImageWithQRCode()"
            >
              <div class="flex flex-col items-center text-stone-600">
                <dojo-icon [icon]="'qrcode'" [size]="30" />
                <span class="font-medium-exam">{{ 'examdojo.question.phone_capture_scan' | transloco }}</span>
              </div>
            </dojo-button>
          </div>
        </swiper-slide>
      }

      @for (image of state.allImages; track image.id) {
        <swiper-slide class="relative h-[6rem] w-[6rem] last:mr-4">
          <ion-thumbnail class="thumbnail-list__item relative">
            @if (image.url) {
              <img dojoImagePreview class="h-full" [src]="image.url" />
            }

            @if (image.location === ImageLocation.Remote) {
              <dojo-icon-button
                @appearDisappear
                (click)="removeSolutionImage(image)"
                [small]="true"
                [icon]="'close'"
                [iconSize]="12"
                class="loaded-images-remove-button absolute right-1 top-1"
              />
            }

            @if (image.location === ImageLocation.Local) {
              <ion-badge
                [color]="'light'"
                class="pointer-events-none absolute bottom-1 right-1 flex h-[22px] w-[22px] items-center justify-center opacity-90"
              >
                <ion-spinner color="dark" class="h-3 w-3" />
              </ion-badge>
            }

            @if (image.location === ImageLocation.Remote) {
              <ion-badge
                class="pointer-events-none absolute bottom-1 right-1 flex h-[22px] w-[22px] items-center justify-center bg-green-500 opacity-90"
              >
                <dojo-icon icon="cloud" />
              </ion-badge>
            }
          </ion-thumbnail>
        </swiper-slide>
      }
    </dojo-swiper>
  </div>
</div>
