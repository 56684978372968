@if (state.show) {
  <div
    class="debugging-container"
    #container
    cdkDrag
    (cdkDragReleased)="savePosition()"
    [style.top.px]="initialPosition?.top"
    [style.left.px]="initialPosition?.left"
    [style.width.px]="initialPosition?.width"
    [style.height.px]="initialPosition?.height"
    [class.has-initial-position]="initialPosition"
  >
    <div
      class="w-full cursor-move border border-b border-dashed border-b-black text-center text-xs font-medium uppercase transition-colors hover:bg-gray-200"
      cdkDragHandle
    >
      Debugging
    </div>
    <div class="p-1">
      <ngx-json-viewer [json]="state.contextObject" [expanded]="false" />

      <div class="mt-1 flex items-center justify-center">
        <button
          class="border border-solid border-gray-300 px-2 text-gray-800 transition-colors hover:bg-gray-200"
          [cdkCopyToClipboard]="state.stringifiedContextObject"
        >
          <dojo-icon icon="copy" matTooltip="Copy to clipboard" [matTooltipShowDelay]="1500"></dojo-icon>
        </button>
      </div>
    </div>
  </div>
}
