<div class="flex flex-col gap-4 p-4">
  <div class="align-center flex justify-end">
    <dojo-button [customWidth]="80" (click)="save()" [disabled]="form.pristine" [pending]="state.isSaving">
      <dojo-icon class="mr-1" [icon]="'save'" />
      Save
    </dojo-button>
  </div>
  @if (showStem()) {
    <div class="flex items-center justify-between">
      <div class="text-md font-medium">
        <dojo-icon class="mr-2" [icon]="'list-tree'" />
        Stem description
      </div>
    </div>
  }
  @if (showStem()) {
    <div class="grid grid-cols-2 gap-4">
      <y42-textarea-input
        [hasInitialFocus]="true"
        [autoResize]="true"
        [formCtrl]="form.controls.stem"
        [errorMessages]="{ required: 'Stem description is required' }"
      />

      <dojo-markdown-viewer [content]="form.controls.stem.getRawValue() ?? ''" />
    </div>
  }

  @if (form.controls.markscheme.value && showMarkscheme()) {
    <div class="markscheme w-full">
      <h3 class="text-typography-primary text-md mb-2 font-medium">Markscheme</h3>
      <div class="grid grid-cols-2 gap-4">
        <div class="max-h-[500px] overflow-auto">
          <mat-form-field class="w-full">
            <mat-label>Editor</mat-label>
            <textarea cdkTextareaAutosize matInput [formControl]="form.controls.markscheme"></textarea>
          </mat-form-field>
        </div>
        <dojo-markscheme-viewer [markScheme]="state.markscheme" [allowImageDelete]="true" />
      </div>
    </div>
  }

  @if (llmFeedback()) {
    <div class="border-t border-gray-300 pt-2">
      <div class="mb-2">Stem feedback</div>
      <div class="border-radius rounded-sm border border-gray-300 bg-blue-200 p-2">{{ llmFeedback() }}</div>
    </div>
  }
</div>
