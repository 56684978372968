import { LocalizedString } from '@examdojo/core/i18n';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { Stem, SubStem } from '../markscheme';
import { QuestionItemBase } from './question.model';

export type StemHttpModel = Pick<
  TableRowModel<'stems'>,
  | 'author'
  | 'created_at'
  | 'difficulty_category'
  | 'id'
  | 'question_id'
  | 'solution'
  | 'stem'
  | 'sys_period'
  | 'total_marks'
  | 'updated_at'
  | 'markscheme'
>;

export type StemStoreModel = Omit<StemHttpModel, 'author' | 'sys_period' | 'stem' | 'solution' | 'markscheme'> &
  QuestionItemBase<'stem'> & {
    authorId: StemHttpModel['author'];
    stem: LocalizedString[];
    solution: LocalizedString;
    markscheme: Stem['sub_stems'] | null;
    totalMarks: number;
  };

export type StemUIModel = Omit<StemStoreModel, 'markscheme'> & {
  label: string;
  markscheme: Array<
    SubStem & {
      label?: string;
      // topicLevel2Models: TopicLevel2UIModel[] // TODO: Creates a circular dependency
    }
  > | null;
};

export const STEM_CREATE_ALLOWED_KEYS = [
  'difficulty_category',
  'question_id',
  'solution',
  'stem',
  'total_marks',
  'markscheme',
] satisfies Array<keyof TableInsertModel<'stems'>>;

export type StemCreateModel = Pick<TableInsertModel<'stems'>, (typeof STEM_CREATE_ALLOWED_KEYS)[number]>;

export const STEM_UPDATE_ALLOWED_KEYS = [...STEM_CREATE_ALLOWED_KEYS] satisfies Array<keyof TableUpdateModel<'stems'>>;

export type StemUpdateModel = Pick<TableUpdateModel<'stems'>, (typeof STEM_UPDATE_ALLOWED_KEYS)[number]>;
