import { Injectable } from '@angular/core';
import { Photo } from '@capacitor/camera';
import { from, map, of, switchMap, tap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { QuestionAttemptStoreModel } from '../question-attempt.model';
import {
  mapPhotoToLocalQuestionAttemptResponseImageStoreModel,
  QuestionAttemptResponseImageCreateModel,
  SOLUTION_IMAGE_STORAGE_BUCKET_NAME,
} from './question-attempt-response-image.model';
import { QuestionAttemptResponseImageQuery } from './question-attempt-response-images.query';
import { QuestionAttemptResponseImageService } from './question-attempt-response-images.service';
import { SolutionImageService } from './solution-image.service';

@Injectable()
export class SolutionService {
  constructor(
    private readonly solutionImageService: SolutionImageService,
    private readonly questionAttemptResponseImageService: QuestionAttemptResponseImageService,
    private readonly questionAttemptResponseImageQuery: QuestionAttemptResponseImageQuery,
  ) {}

  addNewSolutionImage(): Promise<Photo | null> {
    return this.solutionImageService.takePicture();
  }

  saveSolutionImage(userId: string, attemptId: QuestionAttemptStoreModel['id'], fileOrPhoto: Photo | File) {
    const currentResponseImagesList = this.questionAttemptResponseImageQuery.getAllEntities();

    const order = currentResponseImagesList.length
      ? currentResponseImagesList.reduce(
          (max, responseImage) => ((responseImage.order ?? 0) > max ? responseImage.order ?? 0 : max),
          0,
        ) + 1
      : 0;

    const image$ = 'name' in fileOrPhoto ? from(this.fileToPhoto(fileOrPhoto)) : of(fileOrPhoto);

    return image$.pipe(
      map((image) => ({ image, fileName: this.solutionImageService.getFileNameFromPath(userId, image.format) })),
      tap(({ image, fileName }) => {
        const localAttemptImageStoreModel = mapPhotoToLocalQuestionAttemptResponseImageStoreModel({
          url: image.dataUrl ?? '',
          imageName: fileName,
          bucketName: SOLUTION_IMAGE_STORAGE_BUCKET_NAME,
          id: uuid(),
          order,
        });

        this.questionAttemptResponseImageService.addNewLocalQuestionAttemptResponseImages([
          localAttemptImageStoreModel,
        ]);
      }),
      switchMap(({ image, fileName }) => this.solutionImageService.uploadImage(image, fileName)),
      switchMap((uploadedImagePath) => {
        const createModel: QuestionAttemptResponseImageCreateModel = {
          order,
          bucket_name: SOLUTION_IMAGE_STORAGE_BUCKET_NAME,
          image_name: uploadedImagePath,
          question_attempt_id: attemptId,
        };

        return this.questionAttemptResponseImageService.createQuestionAttemptResponseImage(createModel).pipe(
          tap((httpModel) => {
            this.questionAttemptResponseImageService.replaceLocalQuestionAttemptResponseImage(httpModel);
          }),
        );
      }),
    );
  }

  async fileToPhoto(file: File): Promise<Photo> {
    const base64 = await this.convertFileToBase64(file);
    return {
      dataUrl: base64,
      format: file.type.split('/')[1],
      saved: false,
    };
  }

  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string); // Base64 string
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
}
