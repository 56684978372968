<div class="flex flex-col p-4">
  <div class="header flex flex-col justify-between gap-2">
    <div class="flex items-center justify-between">
      <div class="text-md font-medium">
        <dojo-icon class="mr-2" [icon]="'image'" />
        Question image description
      </div>

      <div class="align-center flex items-center gap-2">
        <dojo-button (click)="save()" [disabled]="form.pristine" [pending]="state.isSaving">
          <dojo-icon class="mr-1" [icon]="'save'" />
          Save
        </dojo-button>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <y42-textarea-input
        [autoResize]="true"
        [formCtrl]="form.controls.description"
        [errorMessages]="{ required: 'Question image description is required' }"
      />

      <dojo-markdown-viewer [content]="form.controls.description.getRawValue()" />
    </div>
  </div>

  <div class="flex flex-col gap-2">
    <h3 class="text-typography-primary text-md mb-2 mt-2">Question image upload</h3>
    @if (state.questionImageUrl) {
      <div class="group">
        <span class="relative inline-block">
          <img [src]="state.questionImageUrl" alt="Question image" class="h-[300px]" />
          <y42-icon-button
            class="absolute right-1 top-1 !hidden bg-gray-100 group-hover:!block"
            [icon]="'times'"
            [size]="'large'"
            [matTooltip]="'Delete image'"
            (click)="deleteQuestionImage()"
          />
        </span>
      </div>
    } @else {
      <dojo-image-dropzone (imageSelected)="selectedQuestionImage.set($event)" />
    }
  </div>
</div>
