import { ChangeDetectionStrategy, Component, DestroyRef, input, output } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormControlsOf, markAllAs } from '@examdojo/angular/forms';
import { connectState } from '@examdojo/angular/util';
import { DateTimeModule } from '@examdojo/core/date-time';
import { IconComponent } from '@examdojo/core/icon';
import { TextareaInputComponent } from '@examdojo/core/input';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { MarkschemeViewerComponent } from '@examdojo/markscheme';
import { Markscheme } from '@examdojo/models/markscheme';
import { StemLlmFeedback, StemStoreModel } from '@examdojo/models/question';
import { shareOneReplay } from '@examdojo/rxjs';
import { ButtonComponent } from '@examdojo/ui/button';
import { MarkdownViewerComponent } from '@examdojo/ui/markdown-viewer';
import { catchError, EMPTY, filter, map, of, startWith, switchMap, tap } from 'rxjs';
import { CmsQuestionQuery } from '../../cms-question.query';
import { CmsQuestionService } from '../../cms-question.service';
import { provideStemMarkschemeImage } from './stem-markscheme-image/provide-stem-markscheme-image';
import { StemMarkschemeImageService } from './stem-markscheme-image/stem-markscheme-image.service';

type StemEditorForm = FormControlsOf<{
  stem: StemStoreModel['stem'][0]['en'];
  markscheme: string | null;
}>;

@Component({
  selector: 'dojo-stem-editor',
  templateUrl: './stem-editor.component.html',
  styleUrls: ['./stem-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DateTimeModule,
    ReactiveFormsModule,
    MatTooltipModule,
    TextareaInputComponent,
    MarkschemeViewerComponent,
    MarkdownViewerComponent,
    IconComponent,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [provideStemMarkschemeImage()],
})
export class StemEditorComponent {
  constructor(
    private readonly questionService: CmsQuestionService,
    private readonly destroyRef: DestroyRef,
    private readonly cmsQuestionQuery: CmsQuestionQuery,
    private readonly stemMarkschemeImageService: StemMarkschemeImageService,
  ) {
    this.stem$
      .pipe(filter(isNotNullish))
      .pipe(
        filter(isNotNullish),
        tap((stem) => {
          if (this.form.pristine) {
            this.form.patchValue({
              // TODO: support multiple stems(?)
              stem: stem.stem[0].en ?? '',
            });

            this.stemMarkschemeImageService.setMarkscheme(stem.markscheme);
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly id = input.required<StemStoreModel['id']>();
  // [BUG] TODO: We need to create at least store-like solution
  // As we need to keep track of local updates to stems and stimuli.
  // Otherwise the next update may revert some of the changes made previously :(
  readonly stem$ = toObservable(this.id).pipe(
    switchMap((id) => this.questionService.fetchStem(id)),
    shareOneReplay(),
  );

  readonly llmFeedback = input<StemLlmFeedback>();
  readonly showMarkscheme = input(true);
  readonly showStem = input(true);
  readonly imageEditOnly = input(false);

  readonly finishedSaving = output<void>();

  readonly form = new FormGroup<StemEditorForm>({
    stem: new FormControl('', { nonNullable: true }),
    markscheme: this.stemMarkschemeImageService.markschemeFormControl,
  });

  readonly state = connectState({
    isSaving: toObservable(this.id).pipe(
      switchMap((id) => (id ? this.cmsQuestionQuery.selectIsSaving('savingStemMap', id) : of(false))),
    ),
    markscheme: this.form.controls.markscheme.valueChanges.pipe(
      startWith(this.form.controls.markscheme.value),
      map((stemMarkscheme) => {
        try {
          const stemArray = stemMarkscheme ? JSON.parse(stemMarkscheme) : [];

          return [
            {
              stem_letter: '',
              sub_stems: stemArray,
            },
          ] as Markscheme;
        } catch {
          return [];
        }
      }),
    ),
  });

  save() {
    const formValue = this.form.value;

    if (this.form.pristine) {
      return;
    }

    markAllAs('touched', this.form);

    if (!this.form.valid && !this.form.controls.markscheme.errors?.['markschemeWarning']) {
      return;
    }

    this.questionService
      .updateStem(this.id(), {
        stem: [{ en: formValue.stem }],
        ...(formValue.markscheme ? { markscheme: JSON.parse(formValue.markscheme) } : {}),
      })
      .pipe(
        tap(() => {
          this.form.markAsPristine();
          this.finishedSaving.emit();
        }),
        catchError(() => EMPTY),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
