import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';
import { from, Observable } from 'rxjs';
import { QuestionAttemptHttpModel } from '../question-attempt.model';
import { QuestionAttemptResponseImageHttpModel } from './question-attempt-response-image.model';
import { QuestionGradingResultHttpModel } from './question-grading-result.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionAttemptResponseImageHttpService extends SupabaseBaseHttpService<
  'question_attempt_response_images',
  'learning'
> {
  readonly tableName = 'question_attempt_response_images';
  readonly schema = 'learning';

  fetchQuestionAttemptResponseImages(
    questionAttemptId: QuestionAttemptHttpModel['id'],
    questionAttemptGradingId: QuestionGradingResultHttpModel['id'] | null = null,
  ): Observable<QuestionAttemptResponseImageHttpModel[]> {
    let query = this.supabase.client
      .schema('learning')
      .from('question_attempt_response_images')
      .select()
      .eq('question_attempt_id', questionAttemptId)
      .order('created_at')
      .order('order');

    if (questionAttemptGradingId) {
      query = query.eq('question_attempt_grading_id', questionAttemptGradingId);
    } else {
      query = query.is('question_attempt_grading_id', null);
    }

    return from(query.throwOnError().then((d) => d.data!));
  }

  deleteQuestionAttemptResponseImageFile(bucketName: string, path: string) {
    return from(this.supabase.removeFiles(bucketName, path));
  }

  fetchSignedUrls(imageNames: string[], bucketName: string) {
    return this.supabase.getSignedUrlsForFiles(bucketName, imageNames);
  }
}
