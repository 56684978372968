import { Injectable } from '@angular/core';
import { EntityStore } from '@examdojo/state';
import { PracticeActivityState, PracticeActivityStoreModel } from './practice-activity.model';

@Injectable({ providedIn: 'root' })
export class PracticeActivityStore extends EntityStore<PracticeActivityState, PracticeActivityStoreModel, 'id'> {
  constructor() {
    super({
      name: 'practice-activities',
      idKey: 'id',
    });
  }
}
