import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { finalize, tap } from 'rxjs';
import { SolutionCaptureQueryParam } from '../solution-capture-query-param.model';

@Injectable()
export class SolutionLinkService {
  constructor(private readonly http: HttpClient) {}

  private readonly generateSolutionLinkUrl = `${environment.examdojo.studentsApiUrl}/generate-magic-link`;

  readonly isLoading = signal<boolean>(false);

  readonly solutionLink = signal<string | null>(null);

  fetchSolutionLink() {
    this.isLoading.set(true);
    return this.http
      .get<{ result: { link: string; status: string } }>(this.generateSolutionLinkUrl, {
        params: {
          redirect_to: this.getRedirectToWithCaptureQueryParam(),
        },
      })
      .pipe(
        tap((solutionLinkResponse) => {
          this.solutionLink.set(solutionLinkResponse?.result?.link ?? null);
        }),
        finalize(() => {
          this.isLoading.set(false);
        }),
      );
  }

  private getRedirectToWithCaptureQueryParam() {
    const url = new URL(window.location.href);
    url.searchParams.set(SolutionCaptureQueryParam, 'true'); // QuestionQueryParams.capture
    return url.href;
  }
}
