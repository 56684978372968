import { MarkGroup, MarkGroupAlternative, MarkPoint, Method, Stem, SubStem } from '@examdojo/models/markscheme';
import { TableRowModel } from '@examdojo/supabase';

export interface GradedMarkPoint extends Omit<MarkPoint, 'implied'> {
  awarded_amount: number;
}

export interface GradedMark {
  points: GradedMarkPoint[];
  mark_level_feedback: string;
  totalMaxPoints?: number;
  totalAwardedPoints?: number;
}

export interface GradedMarkGroupAlternative extends Omit<MarkGroupAlternative, 'marks' | 'note'> {
  marks: GradedMark[];
  awarded?: true;
}

export interface GradedMarkGroup extends Omit<MarkGroup, 'note' | 'mark_group_alternatives'> {
  mark_group_alternatives: GradedMarkGroupAlternative[];
}

export interface GradedMethod extends Omit<Method, 'note' | 'mark_groups'> {
  mark_groups: GradedMarkGroup[];
  awarded?: true;
}

export interface GradedSubStem extends Omit<SubStem, 'note' | 'methods'> {
  methods: GradedMethod[];
  sub_stem_level_feedback: string;
  awardedMethodIndex?: number;
}

export interface GradedStem extends Omit<Stem, 'sub_stems'> {
  sub_stems: GradedSubStem[];
  stem_level_feedback: string;
}

export interface GradedStemStoreModel extends GradedStem {
  awardedMarks: number;
}

export type QuestionGradingResultHttpModel = Pick<
  TableRowModel<'question_attempt_gradings', 'learning'>,
  'grading_result' | 'id' | 'created_at'
>;

export type QuestionGradingResultStoreModel = Omit<QuestionGradingResultHttpModel, 'grading_result'> & {
  grading_result: GradedStemStoreModel[];
};

export function mapGradingResultHttpModelToStoreModel(
  questionGradingResultHttpModel: QuestionGradingResultHttpModel['grading_result'],
): QuestionGradingResultStoreModel['grading_result'] {
  return (questionGradingResultHttpModel as unknown as GradedStem[]).map((stem) => ({
    ...stem,
    awardedMarks: getStemAwardedPoints(stem),
  }));
}

function getMarkPoints(mark: GradedMark): { totalAwardedPoints: number; totalMaxPoints: number } {
  return mark.points.reduce(
    (acc, point: GradedMarkPoint) => {
      acc.totalAwardedPoints += point.awarded_amount;
      acc.totalMaxPoints += point.amount;
      return acc;
    },
    { totalAwardedPoints: 0, totalMaxPoints: 0 },
  );
}

function getMarkGroupAlternativeAwarded(alt: GradedMarkGroupAlternative): number {
  return alt.marks.reduce((sum: number, mark: GradedMark) => {
    const { totalAwardedPoints, totalMaxPoints } = getMarkPoints(mark);

    mark.totalAwardedPoints = totalAwardedPoints;
    mark.totalMaxPoints = totalMaxPoints;

    return sum + totalAwardedPoints;
  }, 0);
}

function getMarkGroupAwarded(markGroup: GradedMarkGroup): number {
  let bestAlternativeAwarded = 0;

  for (const alt of markGroup.mark_group_alternatives) {
    const altAwarded = getMarkGroupAlternativeAwarded(alt);
    if (altAwarded > bestAlternativeAwarded) {
      bestAlternativeAwarded = altAwarded;
      alt.awarded = true;
    }
  }

  return bestAlternativeAwarded;
}

function getMethodAwarded(method: GradedMethod): number {
  return method.mark_groups.reduce((methodSum: number, markGroup: GradedMarkGroup) => {
    return methodSum + getMarkGroupAwarded(markGroup);
  }, 0);
}

function getSubStemAwarded(subStem: GradedSubStem): number {
  let bestMethodAwarded = 0;
  let awardedMethodIndex = 0;

  subStem.methods.forEach((method, index) => {
    const methodAwarded = getMethodAwarded(method);
    if (methodAwarded > bestMethodAwarded) {
      bestMethodAwarded = methodAwarded;
      awardedMethodIndex = index;
      method.awarded = true;
    }
  });

  subStem.awardedMethodIndex = awardedMethodIndex;

  return bestMethodAwarded;
}

function getStemAwardedPoints(stem: GradedStem): number {
  return stem.sub_stems.reduce((stemSum: number, subStem: GradedSubStem) => {
    return stemSum + getSubStemAwarded(subStem);
  }, 0);
}

// function calculateTotalAwardedPoints(markscheme: GradedStem[]): number {
//   return markscheme.reduce((total: number, stem: GradedStem) => {
//     return total + getStemAwardedPoints(stem);
//   }, 0);
// }
