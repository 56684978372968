import { ChangeDetectionStrategy, Component, computed, Input, input } from '@angular/core';
import { Icon, IconComponent } from '@examdojo/core/icon';

export type Severity = 'error' | 'warn' | 'success' | 'info';

@Component({
  selector: 'y42-info-box-severity-icon',
  template: '<dojo-icon class="icon" [icon]="icon()" [size]="size" />',
  styleUrls: ['./info-box-severity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class]': 'computedClass()' },
  imports: [IconComponent],
})
export class InfoBoxSeverityIconComponent {
  private readonly DEFAULT_ICON_SIZE = 14;

  readonly severity = input.required<Severity>();

  readonly icon = computed(() => {
    const severityToIcon: Record<Severity, Icon> = {
      warn: 'triangle-exclamation',
      error: 'circle-exclamation',
      success: 'check',
      info: 'circle-info',
    };

    return severityToIcon[this.severity()];
  });

  protected computedClass = computed(() => `flex severity-${this.severity()}`);

  @Input() size = this.DEFAULT_ICON_SIZE;
}
