import { Injectable } from '@angular/core';
import { SupabaseBaseHttpService } from '@examdojo/supabase';

@Injectable({ providedIn: 'root' })
export class PracticeActivityStatusHttpService extends SupabaseBaseHttpService<
  'practice_activity_statuses',
  'learning'
> {
  readonly schema = 'learning';
  readonly tableName = 'practice_activity_statuses';
}
