import { Provider, Type } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import { MarkschemeImage } from '@examdojo/models/markscheme';
import { MarkschemeNodePath } from './markscheme-node-path.model';

export abstract class MarkschemeImageService {
  abstract openImageDialog(imageDescription: LocalizedString, path: MarkschemeNodePath): void;
  abstract deleteImage(image: MarkschemeImage, path: MarkschemeNodePath): Promise<void>;
  abstract fetchSignedUrlForImage(imageId: string): Promise<string>;
}

export function provideMarkschemeImageService(service: Type<MarkschemeImageService>): Provider[] {
  return [service, { provide: MarkschemeImageService, useExisting: service }];
}
