import { Constants, Enum } from '@examdojo/supabase';

export type StemSubjectType = Enum<'subject_type'>;

export const STEM_SUBJECT_TYPES = Constants.public.Enums.subject_type;

// TODO: map to translation keys instead
export const STEM_SUBJECT_TYPE_TO_LABEL: Record<StemSubjectType, string> = {
  MATH: 'Math',
};
